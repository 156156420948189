import React, { Component } from 'react';
import {
    Row, Form, Button, Progress, FormGroup, Label, UncontrolledTooltip
} from 'reactstrap';
import styles from './UploadDoc.module.css';
import classNames from 'classnames';
import SaveModal from '../../../../components/modals/SaveModal';
import Dropzone from './DragandDropFileUpload';
import ReactTooltip from 'react-tooltip';
import DocumentTitle from 'react-document-title';
import { ReactComponent as Info } from '../../../../assets/images/info-icon-blk.svg';
import { updateClaimConfiguration } from '../../../../helpers'
import moment from 'moment';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent';
import InternationalPhoneStatic from '../../../shared/PhoneNumberUtility/InternationalPhoneStatic'
import IntakeLossButtons from '../IntakeLoss/IntakeLossButtons';
import { ToSpeakWithARepresentative } from '../IntakeLoss/ConfirmationLT';
import { ContractContext } from '../../../contexts/ContractContext';
import { ReactComponent as PaperIcon } from '../../../../assets/images/paper.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/images/download-down-arrow.svg';
import { ReactComponent as CloseButton } from '../../../../assets/images/close.svg';
import { StandardContent } from '../IntakeLoss/ConfirmationLT';
import { DropDownInput } from '../../../inputs/DropDownInput';
import SimpleReactValidator from 'simple-react-validator';
import ProofOfLossApple from '../../../../assets/Documents/Proof-of-loss-Apple.pdf'

let dSmFlex = "d-sm-flex";
let btmLine = styles.btmLine;


export class UploadDocs extends Component {

    constructor(props) {

        super(props);
        this.state = {
            statusType: this.props?.statusType,
            contractNumber: "",  // case number
            client: typeof this.props?.history?.location?.state === "undefined" ? "" : this.props?.history?.location?.state?.clientId,
            countryCode: this.props?.history?.location?.state?.entitlementDetails?.countryCode,
            claimId: this.props?.claimId,
            claimNumber: this.props?.claimNumber,
            claimEmail: this.props?.claimEmail || "",
            clientId: this.props?.clientId,
            isUpdatebtnDisable: true,
            selectedDoc: "",
            files: [],
            fileName: "",
            fileUploadedPercentage: 0,
            pendingDocs: false,
            isFNOL: this.props?.isFNOL || false,
            isGovtDoc: true,
            isFraud: typeof this.props?.history?.location.state === "undefined" || typeof this.props?.history?.location?.state?.isFraud === "undefined" ? true : this.props?.history?.location?.state?.isFraud,
            additionalReview: typeof this.props?.history?.location.state === "undefined" || typeof this.props?.history?.location?.state?.additionalReview === "undefined" ? false : this.props?.history?.location?.state?.additionalReview,
            isClaimOverview: this.props?.isClaimOverview || false,
            isMultiplePendingRequest: this.props?.isMultiplePendingRequest || false,
            pendingInfoDocuments: this.props?.pendingInfoDocuments || [],
            ccNumber: "",
            ccType: "",
            model: {
                DocumentId: 0,
                DocumentTypeId: 1,
                DocumentFileTypeId: 1,
                DocumentName: "test",
                DocumentData: [],
                CreatedBy: "CustomerPortal",
                CreatedDate: moment().format(),
                ModifiedBy: "",
                ModifiedDate: moment().format()

            },
            invalidDoc: false,
            caseNumber: this.props?.caseNumber,
            uploading: false,
            maxUploads: false,
            pageTitle: 'Upload Documents | AIG AppleCare+ with Theft and Loss Claims',
            validationFired: false
        }

        this.uploadDocument = this.uploadDocument.bind(this);
        this.uploadPendingDocuments = this.uploadPendingDocuments.bind(this);
        this.uploadedRequiredInfo = this.uploadedRequiredInfo.bind(this);
        this.viewConfirmation = this.viewConfirmation.bind(this);
        this.saveForLater = this.saveForLater.bind(this);
        this.saveForLaterAction = this.saveForLaterAction.bind(this);
        this.onSaveForLaterAction = this.onSaveForLaterAction.bind(this);
        this.validator = new SimpleReactValidator({
            messages: {
                required: ':attribute is required.'
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        });
    }


    onSaveForLaterAction = e => {
        e.preventDefault();
        this.setState({ showConfirmation: true });
    }

    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        this.setState({ isSaveForLater: true }, () => { this.saveForLaterAction(this.props.history.location.state.claimId) });
    }

    saveForLaterAction = (claimId) => {
        if (!claimId) { return; }
        this.setState({ uploading: true });
        const entitlementDetails = this.props.entitlementDetails;
        const partyModel = entitlementDetails?.party || null
        const claim = { claimId: claimId, createdBy: "SaveClaim", claimNumber: this.props.history.location.state.claimNumber }
        const model = { claimModel: claim, partyModel: partyModel }
        fetch(`api/claim/SaveForLater/${claimId}/4/0/apple`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        }).then(data => {
            if (!data.ok) {
                throw new Error(`HTTP error! Status: ${data.status}`);
            }
            if (data.status === 204) {
            }
            if (data.status === 200) {
                this.props.history.push(
                    '/vendor/apple/confirmation',
                    {
                        statusType: "savedClaim",
                        caseNumber: this.props.history.location.state.caseNumber,
                        claimEmail: this.state.claimEmail
                    }
                );
            }
            this.setState({ uploading: false, isSaveForLater: false });
        }).catch(e => {
            this.setState({ uploading: false, isSaveForLater: false });
        })
    }

    componentDidMount() {
        this.setState(() => ({
            contractNumber: this.props?.contractNumber == undefined ? "230404953" : this.props?.contractNumber,
            client: this.props?.client == undefined ? "apple" : this.props.client,
            claimNumber: typeof this.props?.history?.location?.state === "undefined" ? "" : this.props?.history?.location?.state?.claimNumber,
            claimEmail: this.props?.history?.location?.state?.claimEmail || this.props?.claimEmail || "",
            clientId: this.props?.clientId == undefined ? "1" : this.props?.clientId,
            claimId: this.props?.history?.location?.state?.claimId == undefined ? false : this.props?.history?.location?.state?.claimId,
            caseNumber: this.props?.history?.location?.state?.caseNumber == undefined ? false : this.props?.history?.location?.state?.caseNumber,
            pendingDocs: this.props?.pendingDocs == undefined ? false : this.props.pendingDocs,
            isFraud: typeof this.props?.history?.location?.state === "undefined" || typeof this.props?.history?.location?.state?.isFraud === "undefined" ? true : this.props?.history?.location?.state?.isFraud,
            ccNumber: typeof this.props?.history?.location?.state === "undefined" ? false : this.props?.history?.location?.state?.ccNumber,
            ccType: typeof this.props?.history?.location?.state === "undefined" ? false : this.props?.history?.location?.state?.ccType,
            additionalReview: typeof this.props?.history?.location?.state === "undefined" || typeof this.props?.history?.location?.state?.additionalReview === "undefined" ? false : this.props?.history?.location?.state?.additionalReview,
        }));
    }

    uploadDocument = e => {
        e.preventDefault();

        this.setState({ uploading: true });
        let statusCode = '';
        fetch('api/document/'.concat(this.state.client), {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                DocumentId: 0,
                DocumentTypeId: 1,
                DocumentFileTypeId: 1,
                DocumentName: this.state.fileName,
                DocumentData: this.state.selectedDoc,
                CreatedBy: "CustomerPortal",
                CreatedDate: moment().format(),
                ModifiedBy: "",
                ModifiedDate: moment().format(),
                ObjectDocumentMappingModel: [{
                    WarrantyObjectValue: parseInt(this.state.claimId),
                    WarrantyObjectTypeId: 2,
                    DocumentId: 0


                }]

            })
        })
            .then(function (res) {
                statusCode = res.status;
                return res.json();
            })
            .then(data => {
                if (statusCode === 204) {
                }
                if (statusCode === 200) {
                    updateClaimConfiguration(parseInt(this.state.claimId), 17, "false", this.state.client).then(res => {
                        if (res.ok && res.status === 200) {
                            if (this.state.isFNOL) {
                                this.props.history.push('/vendor/apple/confirmation', { 
                                        statusType: "docsUploaded", 
                                        claimId: this.state.claimId, 
                                        caseNumber: this.state.caseNumber, 
                                        ccNumber: this.props.history.location.state.ccNumber, 
                                        ccType: this.props.history.location.state.ccType,
                                        claimEmail: this.props.history.location.state.claimEmail || this.state.claimEmail,
                                    });
                            }
                            else {
                                this.props.onDocumentUploadSuccess(true);
                            }
                        }
                        this.setState({ uploading: false });
                    });
                }
            });
    }

    uploadPendingDocuments = (e) => {
        e.preventDefault();

        this.setState({
            uploading: true,
            validationFired: true
        })
        if (this.validator.allValid()) {
            fetch('api/document/upload/documents/apple', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WarrantyObjectValue: parseInt(this.state.claimId),
                    WarrantyObjectTypeId: 2,
                    Documents: this.state.files,
                })
            }).then(res => {
                if (res.ok) {
                    if (res.status === 200) {
                        res.json().then(data => {
                            if (this.uploadedRequiredInfo() && !this.state.isMultiplePendingRequest) {
                                this.props?.updateClaimOverviewStatus('docsInReview', true)
                            }
                            else if (this.uploadedRequiredInfo() && this.state.isMultiplePendingRequest) {
                                this.props?.updateClaimOverviewStatus('docsInReview', false)
                            }
                            else {
                                this.props?.updateClaimOverviewStatus('pendingDocs', false)
                            }
                        })
                    }
                }
                else {
                    this.setState({ uploading: false })
                    return;
                }
            })
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ uploading: false })
        }
    }

    uploadedRequiredInfo = () => {
        const submittedPendingInfo = this.state.pendingInfoDocuments?.filter(pendingInfo => {
            return !this.state.files?.some(file => file?.documentTypeId === pendingInfo.data);
        })

        if (submittedPendingInfo.length) {
            return false;
        }
        else {
            return true;
        }
    }

    viewConfirmation = e => {
        e.preventDefault();
        this.props.history.push('/vendor/apple/confirmation', {
            isFraud: true,
            statusType: "docsPending",
            claimId: typeof this.props.history.location.state === "undefined" ? false : this.props.history.location.state.claimId,
            caseNumber: this.props.history.location.state.caseNumber,
            ccNumber: this.props.history.location.state.ccNumber,
            ccType: this.props.history.location.state.ccType,
            claimNumber: this.props.history.location.state.claimNumber,
            entitlementDetails: this.props.history.location.state.entitlementDetails,
            claimEmail: this.props.history.location.state.claimEmail || this.state.claimEmail
        });

    }


    callBackFuncFileUpload = (fileData) => {

        if (fileData && fileData.length > 0) {
            const currentFile = fileData[0];
            this.setState(state => ({ fileName: currentFile.name }));

            const reader = new FileReader();

            reader.addEventListener("load", () => {

                this.setState(state => ({ selectedDoc: reader.result }));
                this.setState({ isUpdatebtnDisable: false });

            }, false)
            reader.addEventListener("abort", (ev) => {
            }, false)
            reader.addEventListener("error", (ev) => {
            }, false)

            reader.addEventListener("progress", (event) => {
                this.setState({
                    fileUploadedPercentage: Math.round((event.total / event.loaded) * 100)
                });


            })

            reader.readAsDataURL(currentFile);
            this.setState({ invalidDoc: false });
        }
        else {
            //alert("The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)");
            this.setState({ invalidDoc: true });
        }


    }

    onPendingFileUpload = (file) => {
        this.setState({ maxUploads: false });

        if (this.state.files.length < 7) {
            if (file && file.length > 0) {
                const currentFile = file[0];
                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    this.setState(prevState => ({
                        files: [
                            ...prevState.files,
                            {
                                documentId: 0,
                                documentTypeId: null,
                                documentFileTypeId: 1,
                                documentName: currentFile?.name,
                                documentData: reader.result,
                                createdBy: 'CustomerPortal',
                                createdDate: moment().format()
                            }
                        ],
                        invalidDoc: false
                    }))
                }, false)
                reader.readAsDataURL(currentFile);

            }
            else {
                this.setState({ invalidDoc: true });
            }
        }
        else {
            this.setState({
                maxUploads: true
            })
        }
    }

    onPendingRemoveFile = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        let filesCopy = this.state.files;
        filesCopy = filesCopy.filter((file, i) => i !== index)
        this.setState({
            files: filesCopy,
            maxUploads: false
        })
    }

    getTranslatedPendingInfoDocuments = (translations) => {
        let copyOfPendingInfoDocuments = this.state.pendingInfoDocuments;

        copyOfPendingInfoDocuments?.map((pendingInfo) => {
            if (pendingInfo.data === 4) {
                pendingInfo.display = translations?.pendingDocs?.governmentID  || 'Government-Issued ID'
            }
            if (pendingInfo.data === 5) {
                pendingInfo.display = translations?.pendingDocs?.policeReport || 'Police Report'
            }
            if (pendingInfo.data === 13) {
                pendingInfo.display = translations?.pendingDocs?.proofOfLoss || 'Proof of Loss Form'
            }
            if (pendingInfo.data === 17) {
                pendingInfo.display = translations?.pendingDocs?.proofOfPurchase || 'Proof of Purchase'
            }
        })
        return copyOfPendingInfoDocuments 
    }

    handleDocTypeChange = (data, index) => {
        let filesCopy = this.state.files;
        filesCopy?.map((file, i) => {
            if (i === index) {
                return (
                    file.documentTypeId = data
                )
            }
        })

        this.setState({
            files: filesCopy
        })
    }

    onRemoveFile = event => {

        this.setState(state => ({ selectedDoc: '' }));
        this.setState({ isUpdatebtnDisable: true });
        this.setState({
            fileUploadedPercentage: 0
        });

    }

    render() {
        const { pageTitle } = this.state;
        const { data: { isChileApple } } = this.context;
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/UploadDocs/UploadDocs'}>
                {international => {
                    const {
                        translations,
                        countryVars: {
                            currency
                        },
                        englishVars: {
                            proofOfIdTitle,
                            proofOfIdDescription
                        }
                    } = international;
                    return (
                        <DocumentTitle title={pageTitle}>
                            <>
                                <section id="uploadDocs">
                                    {!this.state.isClaimOverview && (
                                        <>
                                            <h1 className={classNames(dSmFlex, !this.state.isFNOL ? btmLine : "")}>{translations.pageTitle || 'Claim in Review'} <span className={styles.badgeAlert}>{translations.actionRequired || 'Action Required'}</span></h1>
                                            <p className="mb-0">{translations.coverage || 'To process your claim, we need you to upload one of the the following document(s):'}</p>
                                            <ul className={styles.docsReq}>
                                                {(this.state.isFraud || this.state.additionalReview) &&
                                                    <li className="proofPurchase" aria-label={translations.proofOfPurchaseAria || "Documentation that confirms you’ve paid for the device you’re requesting to replace"}>{translations.proofOfPurchase || 'Proof of Purchase'}
                                                        <Button
                                                            className={styles.tooltipButton}
                                                            onClick={() => { ReactTooltip.show(this.tooltip1Ref) }}
                                                            onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip1Ref)}
                                                            role="button"
                                                        ><Info ref={ref => this.tooltip1Ref = ref} data-tip data-for='tooltip1' aria-label="Help text" className={styles.infoIcon} /></Button>
                                                        <ReactTooltip
                                                            id='tooltip1'
                                                            place="top" aria-haspopup='true'
                                                            role="tooltip"
                                                            aria-label={translations.proofOfPurchaseAria || "Documentation that confirms you’ve paid for the device you’re requesting to replace."}
                                                            className={styles.tooltiptext}
                                                            effect="solid"
                                                            type="light"
                                                            clickable={true}
                                                            border={true}>
                                                            <b>{translations.proofOfPurchase || 'Proof of Purchase'}</b> - {translations.proofOfPurchaseAria || "Documentation that confirms you’ve paid for the device you’re requesting to replace"}
                                                        </ReactTooltip>
                                                    </li>
                                                }
                                                {(!this.state.isFraud) &&
                                                    <li className="proofAddress" aria-label="Proof of Address - Documentation that confirms where you live (must have your full name and address listed)">Proof of Address
                                                        <Button
                                                            role="button"
                                                            className={styles.tooltipButton}
                                                            onClick={() => { ReactTooltip.show(this.tooltip2Ref) }}
                                                            onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip2Ref)}
                                                        ><Info ref={ref => this.tooltip2Ref = ref} data-tip data-for='tooltip2' aria-label="Help text" className={styles.infoIcon} /></Button>
                                                        <ReactTooltip
                                                            id='tooltip2'
                                                            aria-haspopup='true'
                                                            place="top"
                                                            role="tooltip"
                                                            aria-label="Documentation that confirms where you live (must have your full name and address listed)."
                                                            className={styles.tooltiptext}
                                                            effect="solid"
                                                            type="light"
                                                            border={true}>
                                                            <b>{translations.proofOfAddress || 'Proof of Address'}</b> - Utility Bill or Bank Statement
                                                        </ReactTooltip>
                                                    </li>
                                                }
                                                {(this.state.isFraud && !this.state.additionalReview) &&
                                                    <li className="govID" aria-label={`${translations.proofOfId || 'Proof of ID'} - ${translations.proofOfIdDescription || proofOfIdDescription}`}>{translations.proofOfId || 'Proof of ID'}
                                                        <Button
                                                            role="button"
                                                            className={styles.tooltipButton}
                                                            onClick={() => { ReactTooltip.show(this.tooltip3Ref) }}
                                                            onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip3Ref)}
                                                        ><Info data-tip data-for='tooltip3' aria-label="Help text" className={styles.infoIcon} />
                                                        </Button>
                                                        <ReactTooltip
                                                            id='tooltip3'
                                                            className={styles.tooltiptext}
                                                            effect="solid"
                                                            type="light"
                                                            place="top"
                                                            border={true}
                                                        >
                                                            <b>{translations.proofOfId || 'Proof of ID'}</b> - {translations.proofOfIdDescription || proofOfIdDescription}
                                                        </ReactTooltip>
                                                    </li>
                                                }
                                                {(this.state.additionalReview) &&
                                                    <li className="wirelessBill" aria-label="Wireless Bill - Monthly statement of services and charges from your wireless carrier for the device you're requesting to replace">Wireless Bill
                                                        <Button
                                                            role="button"
                                                            className={styles.tooltipButton}
                                                            onClick={() => { ReactTooltip.show(this.tooltip4Ref) }}
                                                            onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip4Ref)}
                                                        ><Info data-tip data-for='tooltip4' aria-label="Help text" className={styles.infoIcon} /></Button>
                                                        <ReactTooltip
                                                            id='tooltip4'
                                                            aria-haspopup='true'
                                                            role="tooltip"
                                                            className={styles.tooltiptext}
                                                            effect="solid"
                                                            type="light"
                                                            place="top"
                                                            border={true}>
                                                            <b>Wireless Bill</b> - Monthly statement of services and charges from your wireless carrier for the device you're requesting to replace
                                                        </ReactTooltip>
                                                    </li>
                                                }
                                                {(!this.state.isFraud) &&
                                                    <li className="utilityBill" aria-label="Utility Bill - Monthly statement of services and charges from the utility company supplying your service at the address you provided (must have your full name and address listed)">Utility Bill
                                                        <Button
                                                            role="button"
                                                            className={styles.tooltipButton}
                                                            onClick={() => { ReactTooltip.show(this.tooltip4Ref) }}
                                                            onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip4Ref)}
                                                        ><Info data-tip data-for='tooltip5' aria-label="Help text" className={styles.infoIcon} />
                                                        </Button>
                                                        <ReactTooltip
                                                            id='tooltip5'
                                                            aria-haspopup='true'
                                                            role="tooltip"
                                                            className={styles.tooltiptext}
                                                            effect="solid"
                                                            type="light"
                                                            place="top"
                                                            border={true}>
                                                            <b>Utility Bill</b> - Monthly statement of services and charges from the utility company supplying your service at the address you provided (must have your full name and address listed)
                                                        </ReactTooltip>
                                                    </li>
                                                }
                                                {(!this.state.isFraud) &&
                                                    <li className="cardStatement" aria-label="Card Statement - Main page of your monthly statement of services and charges from the credit card company of the card you provided to pay any required claim fees (must have your full name, the last four digits of the card number, and the credit card billing address)">Card Statement
                                                        <Button
                                                            role="button"
                                                            className={styles.tooltipButton}
                                                            onClick={() => { ReactTooltip.show(this.tooltip4Ref) }}
                                                            onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip4Ref)}
                                                        ><Info data-tip data-for='tooltip6' aria-label="Help text" className={styles.infoIcon} />
                                                        </Button>
                                                        <ReactTooltip
                                                            id='tooltip6'
                                                            aria-haspopup='true'
                                                            role="tooltip"
                                                            className={styles.tooltiptext}
                                                            effect="solid"
                                                            type="light"
                                                            place="top"
                                                            border={true}>
                                                            <b>Card Statement</b> - Main page of your monthly statement of services and charges from the credit card company of the card you provided to pay any required claim fees (must have your full name, the last four digits of the card number, and the credit card billing address)
                                                        </ReactTooltip>
                                                    </li>
                                                }
                                            </ul>
                                            <p>
                                                {isChileApple ? (
                                                    translations.cardNotChargedUntil || 'Your card will not be charged until your claim has been approved.'
                                                ) : (
                                                    <b>{translations.cardNotChargedUntil || 'Your card will not be charged until your claim has been approved.'}</b>
                                                )}
                                            </p>
                                        </>
                                    )}
                                    {this.state.isClaimOverview && (
                                        <>
                                            {this.state.isMultiplePendingRequest ?
                                                <h1 className={classNames('h3', dSmFlex, btmLine)}>
                                                    {translations?.pendingDocs?.multipleReqTitle || 'Documentation Needed'}
                                                </h1>
                                                :
                                                <h1 className={classNames(dSmFlex, btmLine)}>
                                                    {translations?.pendingDocs?.pageTitle || 'Claim in Review'}
                                                    <span className={styles.badgeAlert}>
                                                        {translations.actionRequired || 'Action Required'}
                                                    </span>
                                                </h1>
                                            }
                                            <p className="mb-0">{translations.pendingDocs?.p1 || 'Please upload the following document(s):'}</p>
                                            <ul className={styles.docsReq}>
                                                {this.state.pendingInfoDocuments?.map(doc => {
                                                    //docType 4 is Government-Issued ID
                                                    if (doc?.data === 4) {
                                                        return (
                                                            <li>
                                                                {translations?.pendingDocs?.governmentID || 'Government-Issued ID'}
                                                                <Button
                                                                    className={styles.tooltipButton}
                                                                    onClick={() => { ReactTooltip.show(this.tooltip1Ref) }}
                                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip1Ref)}
                                                                    role="button"
                                                                >
                                                                    <Info
                                                                        ref={ref => this.tooltip1Ref = ref}
                                                                        data-tip
                                                                        data-for='tooltip1'
                                                                        aria-label="Help text"
                                                                        className={styles.infoIcon}
                                                                    />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id='tooltip1'
                                                                    place="top"
                                                                    aria-haspopup='true'
                                                                    role="tooltip"
                                                                    aria-label={translations?.pendingDocs?.governmentIdToolTip || 'Please provide a valid government-issued ID or passport'}
                                                                    className={styles.tooltiptext}
                                                                    effect="solid"
                                                                    type="light"
                                                                    clickable={true}
                                                                    border={true}
                                                                >
                                                                    <b>{translations?.pendingDocs?.governmentID || 'Government-Issued ID'}</b> - {translations?.pendingDocs?.governmentIdToolTip || 'Please provide a valid government-issued ID or passport'}
                                                                </ReactTooltip>
                                                            </li>
                                                        )
                                                    }
                                                    //docType 5 is Police Report
                                                    if (doc?.data === 5) {
                                                        return (
                                                            <li>
                                                                {translations?.pendingDocs?.policeReport || 'Police Report'}
                                                                <Button
                                                                    className={styles.tooltipButton}
                                                                    onClick={() => { ReactTooltip.show(this.tooltip2Ref) }}
                                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip2Ref)}
                                                                    role="button"
                                                                >
                                                                    <Info
                                                                        ref={ref => this.tooltip2Ref = ref}
                                                                        data-tip
                                                                        data-for='tooltip2'
                                                                        aria-label="Help text"
                                                                        className={styles.infoIcon}
                                                                    />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id='tooltip2'
                                                                    place="top"
                                                                    aria-haspopup='true'
                                                                    role="tooltip"
                                                                    aria-label={translations?.pendingDocs?.policeReportToolTip || "Please provide a detailed police report with serial number."}
                                                                    className={styles.tooltiptext}
                                                                    effect="solid"
                                                                    type="light"
                                                                    clickable={true}
                                                                    border={true}
                                                                >
                                                                    <b>{translations?.pendingDocs?.policeReport || 'Police Report'}</b> - {translations?.pendingDocs?.policeReportToolTip || "Please provide a detailed police report with serial number."}
                                                                </ReactTooltip>
                                                            </li>
                                                        )
                                                    }
                                                    //docType 13 is Proof of Loss Form
                                                    if (doc?.data === 13) {
                                                        return (
                                                            <li>
                                                                {translations?.pendingDocs?.proofOfLoss || 'Proof of Loss Form'}
                                                                <span className='ml-2'>
                                                                    <a className={styles.browseLink} onClick={() => window.open(ProofOfLossApple)} target="_blank">
                                                                        {translations?.download || 'Download'}
                                                                        <DownloadIcon className={styles.downloadIcon} />
                                                                    </a>
                                                                </span>
                                                            </li>
                                                        )
                                                    }
                                                    //docType 17 is Proof of Purchase
                                                    if (doc?.data === 17) {
                                                        return (
                                                            <li>
                                                                {translations?.pendingDocs?.proofOfPurchase || 'Proof of Purchase'}
                                                                <Button
                                                                    className={styles.tooltipButton}
                                                                    onClick={() => { ReactTooltip.show(this.tooltip3Ref) }}
                                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip3Ref)}
                                                                    role="button"
                                                                >
                                                                    <Info
                                                                        ref={ref => this.tooltip3Ref = ref}
                                                                        data-tip
                                                                        data-for='tooltip3'
                                                                        aria-label="Help text"
                                                                        className={styles.infoIcon}
                                                                    />
                                                                </Button>
                                                                <ReactTooltip
                                                                    id='tooltip3'
                                                                    place="top"
                                                                    aria-haspopup='true'
                                                                    role="tooltip"
                                                                    aria-label={translations?.pendingDocs?.proofOfPurchaseToolTip || "Please provided proof of purchase of the lost/stolen device"}
                                                                    className={styles.tooltiptext}
                                                                    effect="solid"
                                                                    type="light"
                                                                    clickable={true}
                                                                    border={true}
                                                                >
                                                                    <b>{translations?.pendingDocs?.proofOfPurchase || 'Proof of Purchase'}</b> - {translations?.pendingDocs?.proofOfPurchaseToolTip || "Please provided proof of purchase of the lost/stolen device"}
                                                                </ReactTooltip>
                                                            </li>
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        </>
                                    )}
                                    <Form>
                                        {!this.state.isClaimOverview &&
                                            <Dropzone onParentFileUpload={this.callBackFuncFileUpload}></Dropzone>
                                        }

                                        {(!this.state.isClaimOverview && !this.state.isUpdatebtnDisable) &&
                                            <div className={styles.uploadProgress}>
                                                <PaperIcon className={styles.file} />
                                                <div className={styles.progressWrapper}>
                                                    <div className={styles.fileName}>{this.state.fileName}</div>
                                                    <div className='w-100'>
                                                        <div class={styles.uploadProgressWrapper}>
                                                            <Progress
                                                                className={styles.progress}
                                                                value={this.state.fileUploadedPercentage}
                                                                barClassName={styles.progressBar}
                                                            />
                                                            <CloseButton
                                                                onClick={this.onRemoveFile}
                                                                aria-label={translations.cancelUploadAria || "Cancel Uploaded File"}
                                                                className={styles.closeButton}
                                                            />
                                                        </div>
                                                        <div className={styles.uploadPercentage}>
                                                            {translations.progress1 || "Upload"}
                                                            <span> {this.state.fileUploadedPercentage}% {translations.progress2 || "completed"}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                        {this.state.isClaimOverview &&
                                            <Dropzone onParentFileUpload={this.onPendingFileUpload} isClaimOverview={this.state.isClaimOverview} />
                                        }
                                        {this.state.maxUploads &&
                                            <p className="validationSummary">
                                                {translations?.maxNumberDocs || 'The maximum number of documents (7) has been reached for this session. Please upload the current documents or remove one.'}
                                            </p>
                                        }
                                        {(this.state.isClaimOverview && this.state.files.length > 0) &&
                                            this.state.files?.map((file, index) => {
                                                return (
                                                    <div className={styles.uploadedPendingDocContainer}>
                                                        <div key={index} className={styles.pendingUploadProgress}>
                                                            <PaperIcon className={styles.file} />
                                                            <p id={`tooltip-${index}`} className={styles.pendingFileName}>
                                                                {file?.documentName}
                                                            </p>
                                                            <UncontrolledTooltip
                                                                className={styles.toolTipStyle}
                                                                placement='top'
                                                                target={`tooltip-${index}`}
                                                            >
                                                                {file?.documentName}
                                                            </UncontrolledTooltip>
                                                            <div class={styles.pendingUploadProgressWrapper}>
                                                                <Progress
                                                                    className={styles.progress}
                                                                    value={100}
                                                                    barClassName={styles.progressBar}
                                                                />
                                                                <div className={styles.uploadPercentage}>
                                                                    {translations.progress1 || "Uploading"}
                                                                    <span> {100}% {translations.progress2 || "completed"}</span>
                                                                </div>
                                                            </div>
                                                            <CloseButton
                                                                onClick={(e) => this.onPendingRemoveFile(e, index)}
                                                                aria-label={translations.cancelUploadAria || "Cancel Uploaded File"}
                                                                className={styles.closeButton}
                                                            />
                                                        </div>
                                                        <FormGroup className={classNames(styles.docTypeDropdown, `ml-3 ${(!file?.documentTypeId && this.state.validationFired) && "form-error"}`)}>
                                                            <Label for="pendingInfoDropdown" className="mb-0 form-label">
                                                                {translations?.pendingDocs?.documentType || 'Document Type'}
                                                            </Label>
                                                            <span aria-hidden="true" className="required-indicator">*</span>
                                                            <div className={styles.docTypeWidth}>
                                                                <DropDownInput
                                                                    id={index}
                                                                    options={this.getTranslatedPendingInfoDocuments(translations)}
                                                                    placeholder={translations?.pendingDocs?.pleaseSelect || 'Please Select...'}
                                                                    onChangedValue={this.handleDocTypeChange}
                                                                />
                                                            </div>
                                                            {this.validator.message(
                                                                "documentType",
                                                                file?.documentTypeId,
                                                                "required",
                                                                {
                                                                    messages: {
                                                                        required: translations?.pendingDocs?.docTypeRequired || "Document type is required."
                                                                    }
                                                                }
                                                            )}
                                                        </FormGroup>
                                                    </div>
                                                )
                                            })
                                        }
                                        {this.state.invalidDoc &&
                                            <p className="validationSummary">The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)</p>
                                        }

                                        {!this.state.isClaimOverview &&
                                            <ToSpeakWithARepresentative caseNumber={this.state.caseNumber} countryCode={this.state.countryCode} />
                                        }

                                        {(this.state.isClaimOverview && !this.state.isMultiplePendingRequest) &&
                                            <>
                                                <StandardContent
                                                    claimEmail={this.state.claimEmail}
                                                    standardContentOption='communicationUpdates'
                                                />
                                                <p className='mb-0 font-weight-bold'>
                                                    {translations?.pendingDocs?.pleaseNote || 'Please note the following:'}
                                                </p>
                                                <div className={styles.standardContentWrap}>
                                                    <StandardContent
                                                        standardContentOption='pendingAuth'
                                                        customClass='font-weight-bold'
                                                    />
                                                    <StandardContent
                                                        standardContentOption='failureToProvide'
                                                        customClass='font-weight-bold'
                                                    />
                                                </div>
                                                <StandardContent
                                                    caseNumber={this.state.caseNumber}
                                                    standardContentOption='contactCustomerService'
                                                />
                                            </>
                                        }
                                        <Row className="formBlock">
                                            {!this.state.isClaimOverview &&
                                                <IntakeLossButtons
                                                    // PRIMARY BUTTON PROPS
                                                    buttonText={translations.continue || "Continue"}
                                                    onButtonClick={(e) => this.uploadDocument(e)}
                                                    buttonDisabled={this.state.isUpdatebtnDisable}
                                                    buttonSpinning={this.state.uploading}
                                                    buttonAriaLabel={translations.continueBtnAria || "Click to submit documents for review"}
                                                    // FIRST LINK UNDER BUTTON
                                                    showLink1={!this.state.pendingDocs}
                                                    onLink1Click={this.viewConfirmation}
                                                    link1AriaLabel={translations.uploadLaterAria || "Saves claim so documents can be uploaded later"}
                                                    link1Text={translations.uploadLater || "Upload Later"}
                                                    // CANCEL LINK PROPS CONTROL CANCEL MODAL
                                                    saveClaimClickActionFromWithinCancelModal={this.onSaveForLaterAction}
                                                    caseNumberToCancel={this.state.caseNumber}
                                                    claimNumberToCancel={this.state.claimNumber}
                                                    hideCancelClaimLink={this.state.isFNOL}
                                                    isSubmittedClaim={this.props.isSubmittedClaim}
                                                    hideCancelClaimButton={this.props.hideCancelClaimButton}
                                                    isUploadDocs={true}
                                                />
                                            }
                                            {this.state.isClaimOverview &&
                                                <IntakeLossButtons
                                                    // PRIMARY BUTTON PROPS
                                                    buttonText={translations?.upload || "Upload"}
                                                    onButtonClick={(e) => this.uploadPendingDocuments(e)}
                                                    buttonDisabled={this.state.uploading || this.state.files.length === 0}
                                                    buttonSpinning={this.state.uploading}
                                                    // CANCEL LINK PROPS CONTROL CANCEL MODAL
                                                    hideCancelClaimButton={this.state.isMultiplePendingRequest}
                                                    caseNumberToCancel={this.state.caseNumber}
                                                    claimNumberToCancel={this.state.claimNumber}
                                                    isUploadDocs={true}
                                                    //FOR CLAIMOVERVIEW HANDLING
                                                    isClaimOverview={true}
                                                />
                                            }
                                            {this.state.showConfirmation &&
                                                <SaveModal
                                                    isOpen={this.state.showConfirmation}
                                                    showConfirmation={this.state.showConfirmation}
                                                    history={this.props.history}
                                                    continuing={this.state.isSaveForLater}
                                                    onCancel={this.onSaveCancel}
                                                    onSaveForLaterAction={this.saveForLater}
                                                    client={"apple"}
                                                />
                                            }
                                        </Row>
                                    </Form>
                                </section>
                            </>
                        </DocumentTitle>
                    );
                }
                }
            </UseTranslationInClassComponent>
        );
    }
}
UploadDocs.contextType = ContractContext