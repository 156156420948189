import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Container } from 'reactstrap';
import { ContractContext } from '../contexts/ContractContext';
import YourInfo from './YourInfo';
import PlanInfo from './PlanInfo';
import FileClaim from './FileClaim';
import styles from './PlanOverview.module.css'
import { MyClaimsTable } from './MyClaimsTable';
import { MyPlans } from './MyPlansView/MyPlans';
import { useLocation, useHistory } from 'react-router';
import { parse } from 'query-string';
import { getContractTerms } from '../Global/helpers'


const OverviewTabber = ({ ...props}) => {
    // const [activeTab, setActiveTab] = useState(props?.defaultTab)
    const context = useContext(ContractContext);
    const location = useLocation()
    const history = useHistory()
    const activeTab = location?.state?.activeOverviewTab || 'fileClaim'
    const activePlanView = location?.state?.view || 'kanban'
    const [token, setToken] = useState(parse(location.search)?.id || parse(location.search)?.token)
    const contract = context.data?.contract
    const contracts = context.data?.contracts
    const clientId = contract?.clientId
    const party = contract?.party

    const [contractTerms, setContractTerms] = useState([]);
    const [currentTab, setCurrentTab] = useState(activeTab);
    const [isDataSet, setIsDataSet] = useState(false);

    //const {
    //    data: {
    //        contract,
    //        contract: {
    //            clientId
    //        },
    //        party
    //    }
    //} = context.data

    const [data, setData] = useState({ ...props, contract });
    const handleSwitchTabs = (tabId) => {
        setTabData(tabId);

        history.push({
            pathname: '/planoverview',
            search: typeof token == 'undefined' ? null : `?token=${token}`,
            state: { 
                activeOverviewTab: tabId
            }
        });
    }

    useEffect(() => {
        getTerms();
    }, []);

    const getTerms = () => {
        getContractTerms(false, clientId)
            .then(data => {
                setContractTerms(data);
                if (location?.state?.activeOverviewTab === "myPlans") {
                    setTabData("myPlans", data)
                }
            });
    }

    const setTabData = (tabId, data) => {
        const updatedTerms = data || contractTerms;
        let tabData = { ...props, contract }

        switch (tabId) {
            case 'myPlans':
                tabData = { ...props, contract, contractTerms: updatedTerms };
                break;
        }

        setData(tabData);
        setIsDataSet(true);
    }

    return (
        <Container className={clientId === 'cutler' ? styles.containerPadding : ''}>
            <div>
                <ul className={styles.tabNav} role="tablist">
                    <li
                        className={`${styles.tab} ${activeTab === 'fileClaim' && styles.activeTab}`}
                        onClick={() => handleSwitchTabs('fileClaim')}
                        role="tab"
                    >
                        File A Claim
                    </li>

                    {/* commenting this for now as discussed */}
                    {/* {clientId !== 'hwa' && 
                        <li 
                            className={`${styles.tab} ${activeTab==='planDetails' && styles.activeTab}`} 
                            onClick={()=>handleSwitchTabs('planDetails')} 
                            role="tab"
                        >
                            Plan Details
                        </li>
                    } */}

                    <li
                        className={`${styles.tab} ${activeTab === 'myPlans' && styles.activeTab}`}
                        onClick={() => handleSwitchTabs('myPlans')}
                        role="tab"
                    >
                        My Plans
                    </li>

                    <li
                        className={`${styles.tab} ${activeTab === 'myClaims' && styles.activeTab}`}
                        onClick={() => handleSwitchTabs('myClaims')}
                        role="tab"
                    >
                        My Claims
                    </li>
                </ul>
            </div>


            {activeTab === 'fileClaim' &&
                <Row>
                    <Col md={12}>
                    <FileClaim contract={contract} contracts={contracts} party={ party} />
                    </Col>
                </Row>
            }


            {activeTab === 'planDetails' && clientId !== 'hwa' &&
                <Row>
                    <Card className={styles.planDetailsCard}>
                        <YourInfo party={party} />
                        <PlanInfo party={party} contract={contract} />
                    </Card>
                </Row>
            }


            {activeTab === 'myClaims' &&
                <MyClaimsTable contract={contract} props={props} />
            }

            {activeTab === 'myPlans' && isDataSet &&
                <MyPlans {...data}  planView={activePlanView}/>
            }
        </Container>
    )
}

export default OverviewTabber;