import React, { useEffect, useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FooterButtonsRow from '../components/shared/FooterButtonsRow/FooterButtonsRow.jsx';
import { useIdleTimerContext } from 'react-idle-timer';
import { useHistory, useLocation } from 'react-router-dom';
import { useLogout } from '../../src/components/shared/logout';
import { ContractContext } from '../components/contexts/ContractContext';

const HandleTimeoutModal = ({
    showModal,
    onLogout,
    onContinue,
}) => {

    const history = useHistory();
    const location = useLocation();
    const idleTimer = useIdleTimerContext();
    const context = useContext(ContractContext);
    const [idleTimeLimit, setIdleTimeLimit] = useState(120);
    const { logout } = useLogout();
    const clientId = context.data?.clientId?.toLowerCase() || context.data?.contract?.clientId?.toLowerCase() || 'aig';
    const claimStatusId = history.location?.state?.claim?.claimStatusId;
    const currentPathname = location.pathname || '';

    const handleLogoutAction = (abandonClaim) => {
        saveClaim(abandonClaim);
        logout(true); //for timeout modal passing true so that handleLogout handled by component
        onLogout();
    }

    const onContinueSession = () => {
        idleTimer.pause();
        idleTimer.reset();
        onContinue();
    }

    useEffect(() => {
        if (showModal) {

            const interval = setInterval(() => {
                setIdleTimeLimit(remainingTime => remainingTime > -1 ? remainingTime - 1 : remainingTime);
            }, 1000)

            return () => {
                clearInterval(interval)
            }
        }

    }, [showModal])

    const formattedTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;

        const strPadLeft = (string, pad, length) => {
            return (new Array(length + 1).join(pad) + string).slice(-length);
        }

        const finalTime = strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2);
        return finalTime;
    }

    const saveClaim = (claimAbandoned) => {

        const claim = history.location?.state?.claim;
        const party = history.location?.state?.entitlementDetails?.party;
        const model = { claimModel: claim, partyModel: party }

        let step = 0;

        if (claimStatusId && (claimStatusId === 3 || claimStatusId === 6 || claimStatusId === 8)) {

            if (currentPathname === '/vendor/apple/entitlement') {
                step = 1;
            }
            else if (currentPathname === '/vendor/apple/verifyinfo') {
                step = 2;
            }
            else if (currentPathname === '/vendor/apple/payment') {
                step = 3;
            }
            else if (currentPathname?.toLowerCase().includes('/fileclaim/entitlement')) {
                step = 1;
            }
        }

        if (!claim || step === 0) { return; }

        fetch(`api/claim/SaveForLater/${claim?.claimId}/${step}/${claimAbandoned}/${clientId}`, {

            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        })
    }

    return (
        <Modal
            isOpen={showModal}
            className='md'
            role='dialog'
            aria-modal='true'
        >
            <ModalHeader className='noLine'></ModalHeader>
            <ModalBody className='pb-4'>
                {idleTimeLimit === -1 ? handleLogoutAction(true) : null}
                <h5 className='modal-title mb-1' id='expire'>Your session is about to expire.</h5>
                <p className='mb-3'>Your session will automatically expire in {formattedTime(idleTimeLimit)} minutes.</p>
            </ModalBody>
            <ModalFooter>
                <FooterButtonsRow
                    isModal
                    buttons={{
                        link: {
                            text: 'End Session',
                            onClick: () => handleLogoutAction(false),
                            ariaLabel: 'Click end session to close modal and return to landing page'
                        },
                        primary: {
                            text: 'Continue Session',
                            type: 'primary',
                            onClick: onContinueSession,
                            ariaLabel: 'Click continue session'
                        }
                    }}
                />
            </ModalFooter>
        </Modal>
    )
}
export default HandleTimeoutModal;