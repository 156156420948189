import React, { Component, useContext, useState } from 'react';
import {
    Container
} from 'reactstrap';
import NeedHelp from '../../../components/Vendor/Apple/IntakeLoss/NeedHelp';
import { LanguageContext } from '../../../components/contexts/LanguageTranslation/LanguageContext';
import useTranslation from '../../contexts/LanguageTranslation/useTranslation';
import AppleCookiePolicyModal from '../../modals/AppleCookiePolicyModal';
import styles from './Footer.module.css';


const urls = {
    "US": {
        privacy: "https://www.aig.com/privacy-policy",
        terms: "https://www.aig.com/terms-of-use"
    },
    "UK": {
        privacy: "https://www.aig.co.uk/privacy-policy",
        terms: "https://www.aig.com/terms-of-use",
        cookiePolicy: "https://www.aig.co.uk/cookie-policy"
    },
    "DE": {
        privacy: 'https://www.aig.de/privacy-policy',
        terms: 'https://www.aig.de/terms-of-use',
        cookiePolicy: "https://www.aig.de/cookie-richtlinie"
    },
    "AUS": {
        privacy: "https://www.aig.com.au/about-us/governance/privacy",
        terms: "https://www.aig.com.au/terms-of-use",
        cookiePolicy: "https://www.aig.com.au/cookie-policy"
    }, 
    "FR": {
        privacy: "https://www.aigassurance.fr/protection-des-donnees-personnelles",
        terms: "https://www.aigassurance.fr/about-aig/informations-legales",
        cookiePolicy: "https://www.aigassurance.fr/cookie-policy"
    },
    "IT": {
        privacy: "https://www.aig.co.it/informativa-privacy",
        terms: "https://www.aig.co.it/informazioni-legali",
        cookiePolicy: "https://www.aig.co.it/cookie-policy"
    }, 
    "ES": {
        privacy: "https://www.aig.com.es/politica-de-privacidad",
        terms: "https://www.aig.com.es/condiciones-de-uso",
        cookiePolicy: "https://www.aig.com.es/politica-de-cookies"
    },
    "VN": {
        privacy: "https://www.aig.com.vn/chinh-sach-bao-mat",
        terms: "https://www.aig.com.vn/qui-dinh-su-dung",
        cookiePolicy: "https://www.aig.com.vn/cookie-policy"
    },
    "AT": {
        privacy: "https://www.aig.co.at/datenschutzrichtlinie",
        terms: "https://www.aig.co.at/terms-of-use1",
        cookiePolicy: "https://www.aig.co.at/cookie-richtlinie"
    },
    "NZ": {
        privacy: "https://www.aig.co.nz/privacy-policy",
        terms:  "https://www.aig.co.nz/terms-of-use",
        cookiePolicy: "https://www.aig.co.nz/cookie-policy"
    },
    "DK": {
        privacy: "https://www.aig.dk/privacy-policy",
        terms: "https://www.aig.dk/hjem/brugerbetingelser",
        cookiePolicy: "https://www.aig.dk/cookie-policy"
    },
    "FI": {
        privacy: "https://www.aig.fi/henkilotietojen-suojaperiaatteet",
        terms: "https://www.aig.fi/kayttoehdot",
        cookiePolicy: "https://www.aig.fi/cookie-policy"
    },
    "IE": {
        privacy: "https://www.aig.ie/privacy-policy",
        terms: "https://www.aig.ie/terms-of-use",
        cookiePolicy: "https://www.aig.ie/cookie-policy"
    },
    "NL": {
        privacy: "https://www.aiginsurance.nl/privacybeleid",
        terms: "https://www.aiginsurance.nl/wettelijke-informatie-en-gebruiksvoorwaarden",
        cookiePolicy: "https://www.aiginsurance.nl/cookie-policy"
    },
    "NO": {
        privacy: "https://www.aig.no/sikkerhet-og-personvern",
        terms: "https://www.aig.no/business/juridisk-informasjon",
        cookiePolicy: "https://www.aig.no/cookie-policy"
    },
    "SE": {
        privacy: "https://www.aig.se/integritetspolicy",
        terms: "https://www.aig.se/juridisk-information",
        cookiePolicy: "https://www.aig.se/policy-for-cookies"
    },
    "CH": {
        privacy: "https://www.aig.ch/privacy-policy",
        terms: "https://www.aig.ch/terms-of-use",
        cookiePolicyEN: "https://www.aig.ch/cookie-policy",
        cookiePolicyDE: "https://www.aig.ch/cookie-policy-de",
        cookiePolicyFR: "https://www.aig.ch/cookie-policy-fr",
        cookiePolicyIT: "https://www.aig.ch/cookie-policy-it"
    },
    "CL": {
        privacy: "https://www.southbridgeseguros.cl/terminos-y-condiciones/",
        terms: "https://www.southbridgeseguros.cl/terminos-y-condiciones/"
    }
}

const Footer = () => {
    const international = useTranslation('components/Vendor/Apple/Footer')
    const { country, translations, language } = international;
    const footerUrls = urls[country] ?? urls['US'];
    const [showCookiePolicyModal, setShowCookiePolicyModal] = useState(false);

    const handleCookiePolicyModal = () => setShowCookiePolicyModal(!showCookiePolicyModal);
    const getMultiLanguageCookiePolicy = () => {
        switch (country) {
            case 'CH':
                if (language?.toLowerCase() === 'en'){
                    return footerUrls?.cookiePolicyEN;
                }
                if (language?.toLowerCase() === 'fr'){
                    return footerUrls?.cookiePolicyFR;
                }
                if (language?.toLowerCase() === 'it'){
                    return footerUrls?.cookiePolicyIT;
                }
                if (language?.toLowerCase() === 'sg'){
                    return footerUrls?.cookiePolicyDE;
                }
            default:
                return;
        }
    }
    const getCookieConsentPolicy = () => {
        if (country.toLowerCase() !== 'us' && country.toLowerCase() !== 'cl' && country.toLowerCase() !== 'ch') {
            return (
                <li>
                    <a href={footerUrls?.cookiePolicy} target="_blank" className={styles.modalLink}>
                        {translations?.cookiePolicy || 'Cookie Policy'}
                    </a>
                </li>
            )
        }
        else if (country.toLowerCase() === 'ch') {
            //Multiple Language per country should use function getMultiLanguageCP and this block..
            return (
                <li>
                    <a href={getMultiLanguageCookiePolicy()} target="_blank" className={styles.modalLink}>
                        {translations?.cookiePolicy || 'Cookie Policy'}
                    </a>
                </li>
            )
        }
        else if (country.toLowerCase() === 'us') {
            return (
                <li>
                    <a onClick={handleCookiePolicyModal} className={styles.modalLink}>
                        Cookie Policy
                    </a>
                </li>
            )
        }

        return;
    }

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <>
            {country.toLowerCase() === "cl" ?
                <Container className='px-3'>
                    <p className="preFooterText">
                        {translations?.preFooter || 'Theft and Loss coverage has been agreed by Apple with Southbridge Insurance Company S.A., which is registered at the Financial Markets Commission under the following registration code POL202X00XXX. The coverage is included without any costs as part of the Applecare+ program and its effective period is concurrent with such program.'}
                    </p>
                </Container>
                :
                ""
            }   
            <footer role="contentinfo" aria-label="footer">
                <Container>
                    <ul className="horzList">
                        <li>
                            <a href={footerUrls?.privacy} target="_blank" aria-label={translations.privacyAria || "Privacy Policy opens a new details window"}>
                                {translations.privacy || 'Privacy Policy'}
                            </a>
                        </li>
                        <li>
                            <a href={footerUrls?.terms} target="_blank" aria-label={translations.termsOfUseAria || "Site Terms of Use opens a new details window" }>
                                {translations.termsOfUse || 'Terms Of Use'}
                            </a>
                        </li>
                        {country.toLowerCase() === "de" && (
                            <li>
                                <a href='https://www.aig.de/impressum' target="_blank" aria-label="Necessary legal site on German (EU?) websites">
                                    {translations.impressum || 'Impressum'}
                                </a>
                            </li>
                        )}
                        {
                            getCookieConsentPolicy()
                        }
                    </ul>
                    {country.toLowerCase() === "cl" ?
                        <p>
                            {translations.copyright || "Copyright"} &copy;{(new Date().getFullYear())}, {translations?.southBridge || 'Southbridge Insurance Company. '} {translations.allRightsReserved || "All rights reserved"}.
                        </p>
                        :
                        <p>
                            {translations.copyright || "Copyright"} &copy;{(new Date().getFullYear())}, American International Group, Inc. {translations.allRightsReserved || "All rights reserved"}.
                        </p>
                    }
                    {country.toLowerCase() === "us" && showCookiePolicyModal &&
                        <AppleCookiePolicyModal
                            showCookiePolicyModal={showCookiePolicyModal}
                            onClose={handleCookiePolicyModal}
                        />
                    }           
                </Container>
                <NeedHelp />
            </footer>
        </>
    )
};

export default Footer; 