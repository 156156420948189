import React, { useState } from 'react';
import { Col } from 'reactstrap';
import styles from './PlanOverview.module.css';
import { CustomerInfoModal } from '../../components/modals/CustomerInfoModal';
import { phoneFormat } from '../shared/util'

const YourInfo = ({ party }) => {
    const [partyState, setParty] = useState(party);
    const collectParty = (collectedParty) => {
        setParty(collectedParty);
    }

    // concatenate first and last, make lowerCase to allow CSS to transform capitalization
    const clientName = partyState.firstName.concat(' ', partyState.lastName).toLowerCase()

    const addresses = partyState.partyAddressModel;
    const billingAddress = addresses.filter(a => a.addressTypeId === 1)[0];
    const { address1, address2, city, zip } = billingAddress || {}
    const billingState = typeof billingAddress !== "undefined" ? billingAddress.state : "";      // using this to prevemt confusion between react "state" and billing state.

    const email = partyState.partyEmailModel[0];

    const phones = partyState.partyPhoneModel;
    const mobilePhone = phones.filter(p => p.phoneTypeId === 2)[0];
    const homePhone = phones.filter(p => p.phoneTypeId === 1)[0];

    return (
        <Col className={styles.planDetailsCol} style={{ minWidth: '100' }}>
            <h2 className='h4'>Your Information</h2>

            {/* PERSONAL DETAILS */}
            <ul className={styles.detailsList}>
                <li className={styles.listHeader}>{clientName}</li>
                {!!mobilePhone && <li>Mobile Phone: {phoneFormat(mobilePhone.number)}</li>}
                {!!homePhone && <li>Alternate Phone: {phoneFormat(homePhone.number)}</li>}
                {!!email && <li>{email.address}</li>}
            </ul>

            {/* CLIENT ADDRESS */}
            <ul className={styles.detailsList}>
                <li className={styles.listHeader}>Mailing Address</li>
                {!!address1 && <li>{address1 || "<no street address>"}</li>}
                {!!address2 && <li>{address2}</li>}
                {(!!city || !!billingState || !!zip) &&
                    <li>
                        {`${!!city && (city + ',')} ${!!billingState && billingState} ${!!zip && zip}`}
                    </li>
                }
            </ul>
            <CustomerInfoModal party={party} collectUpdate={collectParty} />
        </Col>
    );
}

export default YourInfo;