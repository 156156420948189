import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import styles from "./ForgotPassword.module.css";
import StandardInput from "../shared/StandardInput/StandardInput";
import { sendVerificationToken } from "../../helpers";
import { ContractContext } from "../../components/contexts/ContractContext";
import { LanguageContext } from "../../components/contexts/LanguageTranslation/LanguageContext";
import SimpleReactValidator from "simple-react-validator";
import SpinnerButton from "../../components/shared/SpinnerButton/SpinnerButton";


const ForgotPassword = () => {
    const history = useHistory();
    const context = useContext(ContractContext);
    const languageContext = useContext(LanguageContext);
    const [email, setEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [validationFired, setValidationFired] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [, forceUpdate] = useState();

    const validator = useRef(
        new SimpleReactValidator({
            messages: {
                email: "Please enter a valid email.",
                required: ":attribute is required."
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        })
    );

    const handleInputChange = (e) => {
        e.preventDefault();
        let { value } = e.target;
        setEmail(value);
        setErrorMsg("");
    };

    const handleContinue = async (e) => {
        e.preventDefault();
        setValidationFired(true);

        if (validator.current.allValid()) {
            setIsSearching(true);
            setValidationFired(false);

            if (email && email.length > 0) {

                const clientId = context?.data?.clientId || context?.data?.contract?.clientId || "aig";
                const countryCode = languageContext?.country ?? "us";
                const languageCode = languageContext?.language ?? "en";

                const response = await fetch(`api/contract/FindContractsByEmailAndClient`, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        clientId: clientId,
                        emailAddress: email,
                        countryCode: countryCode,
                        langCode: languageCode,
                        sendEmail: false
                    })
                });

                const result = await response?.json();

                if (response.status === 200 && result?.length > 0) {

                    const warrantyTypeId = 9;
                    const partyId = result[0]?.party?.partyId;
                    const tokenTypeId = 6;
                    const hasUser = true;
                    const forgotPassword = true;
                    const partyEmail = result[0]?.party?.partyEmailModel[0]?.address;

                    const sentToken = await sendVerificationToken(warrantyTypeId, partyId, tokenTypeId, countryCode, languageCode, "VerifyItsYou", hasUser, partyEmail, clientId);

                    if (sentToken) {
                        history.push("/accountverification", {
                            email: partyEmail,
                            partyId: partyId,
                            countryCode: countryCode,
                            languageCode: languageCode,
                            hasUser: hasUser,
                            forgotPassword: forgotPassword,
                            tokenTypeId: tokenTypeId,
                            clientId: clientId
                        });
                    }
                    else {
                        setIsSearching(false);
                        setErrorMsg("An unexpected error happened, please try again later.");
                    }
                }
                else {
                    setIsSearching(false);
                    setErrorMsg("The email entered does not match our records.");
                }
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <Container className={styles.mainContainer}>
            <div className={styles.forgotPasswordBackground}>
                <Row>
                    <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 1 }}>
                        <Card className={styles.forgotPasswordCard}>
                            <h3 className="text-center mb-2">Forgot Password</h3>
                            <p className="text-center mb-2">Please enter the email address associated with your account. We will send password reset instructions to this address.</p>
                            {errorMsg && (
                                <span className="validation-summary mt-1 text-center">{errorMsg}</span>
                            )}
                            <StandardInput
                                label="Email"
                                hasError={validationFired && validator.current.message("email", email, "required|email") && "form-error"}
                                fieldName="email"
                                ariaLabelAndFieldId="email"
                                maxLength={320}
                                value={email}
                                onChange={handleInputChange}
                                validator={validator.current.message("email", email, "email|required")}
                            />
                            <SpinnerButton
                                type="primary"
                                onClick={handleContinue}
                                text="Send Email"
                                spinning={isSearching}
                                disabled={isSearching}
                                ariaLabel="Click to send email address"
                            />
                            <a href="/plansearch" className="btnLink mt-4 text-center">
                                Back to Login
                            </a>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};

export default ForgotPassword;