import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styles from './ClaimOverview.module.css';
import SimpleReactValidator from 'simple-react-validator';
import Progress from '../../../components/shared/StatusProgress/Progress';
import classNames from 'classnames';
import { UploadDocs } from '../../../components/Vendor/Apple/UploadDocs/UploadDocs';
import moment from 'moment';
import DocumentTitle from 'react-document-title';
import ClaimDetails from '../../ProgressClaimDetails/ClaimDetails';
import { updateRepair, updatePartnerCase, cancelClaim, callAdobe } from '../../../helpers';
import { phoneFormat } from '../../../components/shared/util';
import { UseTranslationInClassComponent } from '../../../components/contexts/LanguageTranslation/UseTranslationInClassComponent'
import { ToSpeakWithARepresentative } from '../../../components/Vendor/Apple/IntakeLoss/ConfirmationLT';
import CancelModal from '../../../components/modals/CancelModal';
import { CombinedLangContractContext } from '../../../components/contexts/ProviderLangContractContext';
import { ContractContext } from '../../../components/contexts/ContractContext';
import VisaIcon from '../../../assets/images/cc-visa.svg';
import MasterCardIcon from '../../../assets/images/cc-mastercard.svg';
import AmexIcon from '../../../assets/images/cc-amex.svg';
import DiscoverIcon from '../../../assets/images/cc-discover.svg';
import { appleServiceNumbers } from '../../../components/_BrandingProvider/brandContent';
import CallLink from '../../../components/shared/PhoneNumberUtility/CallLink';
import { StandardContent } from '../../../components/Vendor/Apple/IntakeLoss/ConfirmationLT';
import PageLoading from '../../../components/shared/PageLoading/PageLoading';
import { AddressForm } from '../../../components/Vendor/Apple/IntakeLoss/CountryAddressForms';
import { Entitlement } from '../../../components/Vendor/Apple/IntakeLoss/Entitlement';
import { verifyAddress, VerificationResponseCodes } from '../../../components/api/addressverification';
import SuggestedAddress from '../../../components/modals/SuggestedAddress';
import InvalidAddress from '../../../components/modals/InvalidAddress';
import IntakeLossButtons from '../../../components/Vendor/Apple/IntakeLoss/IntakeLossButtons';
import { createRepair } from '../../../helpers';

let rtCol = styles.rtCol;
let btmLine = styles.btmLine;
let h2 = "h2";
let caseTitle = styles.caseTitle;
let colLg4 = "col-lg-4";
let colLg5 = "col-lg-5";
let noGutters = "no-gutters"
let progressItem = styles.progressItem;
let progressItemCompleted = styles.progressItemCompleted;
let progressItemActive = styles.progressItemActive;
let orderFirst = "order-first";
let orderLGLast = "order-lg-last";
let orderLast = "order-last";
let orderLGFirst = "order-lg-first";
let overviewBody = styles.overviewBody;
let hoverHand = styles.hoverHand;
let allUpdate = styles.allUpdate;
let contentWrapper = styles.contentWrapper;
let mrAuto = "mr-auto";
let h6 = "h6"

export class ClaimOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contract: this.props?.history?.location?.state?.entitlementDetails?.contract || this.context?.contractContext?.data?.contract,
            claim: this.props.history.location.state?.claim || true,
            claimId: this.props.history.location.state?.claimId || true,
            claimNumber: this.props.history.location.state?.claimNumber || true,
            clientId: this.props?.history?.location?.state?.clientId || 'apple',
            additionalReview: this.props.history.location?.state?.additionalReview,
            isDocUploaded: (typeof this.props.history.location.state === "undefined") ? true : this.props.history.location.state.isDocUploaded,
            isUnderReview: this.props.history.location?.state?.isUnderReview,
            isValidClaim: this.props.history.location?.state?.isValidClaim,
            isApproved: false,
            claimOverview: null,
            claimEventStatus: null,
            claimOverviewStatus: null,
            claimOverviewConfirmation: false,
            pendingDocs: [],
            pendingInfoRequestData: [],
            multiplePendingRequest: false,
            pendingInfoRequestId: null,
            requestClarificationId: null,
            isPendingInfo: false,
            claimEmail: '',
            claimClosureDate: null,
            caseNumber: this.props.history.location?.state?.caseNumber,
            isDeductibleUnsuccessful: this.props.history.location?.state?.isDeductibleUnsuccessful,
            isPendingReview: this.props.history.location?.state?.isPendingReview,
            isClaimDenied: this.props.history.location?.state?.isClaimDenied,
            errorMessage: '',
            ccNumber: this.props.history.location.state?.ccNumber || true,
            ccType: this.props.history.location.state?.ccType || true,
            pageTitle: 'Claim Overview | AIG AppleCare+ with Theft and Loss Claims',
            showCancelModal: false,
            address1: '',
            address2: '',
            city: '',
            state: '',
            stateId: null,
            zip: '',
            states: [],
            activeCountries: null,
            countryCode: this.props?.history?.location?.state?.countryCode || this.props.entitlementDetails?.countryCode || this.props?.location?.state?.entitlementDetails?.countryCode,
            shipToCountryCode: this.props?.history?.location?.state?.entitlementDetails?.shipToCountryCode || this.props?.entitlementDetails?.shipToCountryCode,
            addressToVerify: null,
            verifiedAddress: null,
            showSuggestedAddressModal: false,
            showInvalidAddressModal: false,
            validationFired: false,
            continuing: false,
            fetchingClaimOverview: false
        }

        this.fetchClaimOverview = this.fetchClaimOverview.bind(this);
        this.fetchStates = this.fetchStates.bind(this);
        this.fetchActiveCountries = this.fetchActiveCountries.bind(this);
        this.getCCType = this.getCCType.bind(this);
        this.getClaimReviewStatus = this.getClaimReviewStatus.bind(this);
        this.getDeviceShippedStatus = this.getDeviceShippedStatus.bind(this);
        this.getClaimReviewStyle = this.getClaimReviewStyle.bind(this);
        this.getDeviceShippedStyle = this.getDeviceShippedStyle.bind(this);
        this.allowPendingStatuses = this.allowPendingStatuses.bind(this);
        this.validator = new SimpleReactValidator({
            messages: {
                phone: "Please enter a valid phone number.",
                email: "Please enter a valid email.",
                required: ':attribute is required.'
            },
            validators: {
                valid_phone: {
                    message: 'Please enter a valid phone number.',
                    rule: (val, params, validator) => {
                        return !new RegExp("^([0-9])\\1+$").test(val.match(/\d+/g).join(''))
                    }
                },
                pobox_check: {
                    message: "Sorry, we are unable to ship to PO Boxes. Please update your shipping address.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?![\d#]\ \w+)?^((?!^ *((^(?![\d#]).*p[ \.-/]*\ ?(o|0)[-. \/\\]*[\ \.\-]+[-]?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? +((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box)|(\bPOB\b)).)*$/igm) && params.indexOf(val) === -1
                    }
                }
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        });
    }


    componentDidMount() {
        this.fetchClaimOverview();
        this.fetchStates();
        this.fetchActiveCountries();
    }

    getCCType = () => {
        switch (this.state.ccType) {
            case "visa":
                return (<img className={styles.ccImage} alt="Visa" src={VisaIcon} />)
            case "mastercard":
                return (<img className={styles.ccImage} alt="mastercard" src={MasterCardIcon} />)
            case "amex":
                return (<img className={styles.ccImage} alt="Amex" src={AmexIcon} />)
            case "discover":
                return (<img className={styles.ccImage} alt="Discover" src={DiscoverIcon} />)
            default:
                return (<img className={styles.ccImage} alt="Unidentified" />)
        }
    }

    fetchClaimOverview = async () => {
        this.setState({ fetchingClaimOverview: true })
        const claimId = this.state.claimId;
        const caseId = this.state.caseNumber;

        fetch(`/api/claim/GetClaimOverview/${claimId}/${caseId}/apple`,
            {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.ok) {
                    if (res.status === 200) {
                        res.json().then(claims => {
                            this.setState({
                                claimOverview: claims,
                                claimEmail: claims?.replacementShiptoEmail,
                                claimEventStatus: claims?.eventStatusId,
                                claimOverviewStatus: this.getClaimOverviewStatus(claims, this.state.claimOverviewStatus),
                                claimClosureDate: claims?.claimDecisionCompletedDate,
                                fetchingClaimOverview: false
                            })
                        });
                    }
                    else {
                        this.setState({
                            fetchingClaimOverview: false,
                            errorMessage: 'Oops, there was an issue loading your claim details. Please refresh your browser or try again later.'
                        })
                    }
                }
                else {
                    this.setState({
                        fetchingClaimOverview: false,
                        errorMessage: 'Oops, there was an issue loading your claim details. Please refresh your browser or try again later.'
                    })
                }
            })

        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: 'vendor/apple/claimoverview',
            pageName: 'Claim Overview',
            country: this.context.languageContext?.country,
            language: this.context.languageContext?.language,
            currency: this.context.languageContext?.countryVars.currency.abbreviation,
            caseId: this.props.history.location?.state?.caseNumber,
            claimType: this.state.claimOverview?.coverageName,
            claimStatus: (this.state.isDocUploaded && this.state.isUnderReview)
                ? 'claimInReview'
                : (this.state.isDocUploaded && !this.state.isUnderReview)
                    ? 'docsUploaded'
                    : 'docsPending'
        })
    }

    fetchStates = async () => {
        fetch('api/party/states/' + this.state.countryCode + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                const stateArray = data.map(s => {
                    return {
                        data: s.stateId,
                        display: s.name,
                        abbreviation: s.abbreviation
                    }
                })
                this.handleChangeShipToCountry(stateArray, this.state.countryCode);
            });
    }

    fetchActiveCountries = async () => {
        fetch('api/party/activecountries/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    activeCountries: data.map(country => {
                        return {
                            countryId: country.countryId,
                            countryCode: country.iso2 === 'AU' ? 'AUS' : country.iso2 === 'GB' ? 'UK' : country.iso2,
                            countryName: country.countryName
                        }
                    })
                });
            });
    }

    callBackUploadDocSuccess = (isDocUploaded) => {
        this.setState({
            isDocUploaded: isDocUploaded,
            isUnderReview: isDocUploaded
        })
        this.fetchClaimOverview();
    }

    displayClaimedDevice = (claim) => {
        if (claim == undefined || claim == null)
            return false;

        let validModel = claim.modelNumber !== undefined && claim.modelNumber != null && claim.modelNumber.length > 0;
        let validSerial = claim.serialNumber !== undefined && claim.serialNumber != null && claim.serialNumber.length > 0;
        return validModel && validSerial;
    }

    getClaimOverviewStatus = (claimData, currentClaimOverviewStatus) => {
        let claimOverviewStatus = '';
        //If docs are still pending from FNOL show uploadDocs
        if (claimData?.isDocumentUploadNeeded &&
            claimData?.pendingInfoRequestData === null &&
            claimData?.eventStatusId !== 5 && // Closed or cancelled status should end the need for the uploadDocs screen.
            claimData?.eventStatusId !== 2 
        ) {
            return 'uploadDocsFNOL'
        }
        //Pending Docs and Request should be reset on every claimOverview API call to gather latest 
        this.setState({
            isPendingInfo: false,
            pendingInfoRequestData: [],
            multiplePendingRequest: false,
            pendingDocs: []
        })

        if (claimData?.pendingInfoRequestData !== null &&
            claimData?.eventStatusId !== 5 && // These statuses should end the need for all pendingInfo: Canceled, Denied, Closed 
            claimData?.eventStatusId !== 2 &&
            claimData?.eventStatusId !== 7
        ) {
            claimData?.pendingInfoRequestData?.map((pendingInfo) => {
                if (pendingInfo?.clarificationTypeId === 1 && pendingInfo?.requestStatusId === 1) {
                    claimData?.pendingInfoRequestDocuments?.map(docs => {
                        if (docs?.requestStatusId === 1 && 
                            !this.state.pendingDocs?.some((document) => { return docs?.documentTypeId === document?.data})
                        ) {
                            this.setState(prevState => ({
                                pendingDocs: [...prevState.pendingDocs, { display: docs?.documentTypeName, data: docs?.documentTypeId }]
                            }))
                        }
                    })
                    if (this.state.pendingDocs.length > 0 &&
                        !this.state.pendingInfoRequestData?.some((request) => 
                            { return pendingInfo?.clarificationTypeId === request?.clarificationTypeId }
                        )
                    ) {
                        this.setState(prevState => ({
                            pendingInfoRequestData: [...prevState.pendingInfoRequestData, pendingInfo],
                            isPendingInfo: true
                        }))
                        claimOverviewStatus = 'pendingDocs'
                    }
                }
                else if (pendingInfo?.clarificationTypeId === 2 && 
                    pendingInfo?.requestStatusId === 1 &&
                    !this.state.pendingInfoRequestData?.some((request) => 
                        { return pendingInfo?.clarificationTypeId === request?.clarificationTypeId }
                    )
                ) {
                    this.setState(prevState => ({
                        isPendingInfo: true,
                        pendingInfoRequestData: [...prevState.pendingInfoRequestData, pendingInfo],
                        pendingInfoRequestId: pendingInfo?.pendingInformationRequestId,
                        requestClarificationId: pendingInfo?.requestClarificationId
                    }))
                    claimOverviewStatus = 'pendingIncidentDescription'
                }
                else if (pendingInfo?.clarificationTypeId === 3 && 
                    pendingInfo?.requestStatusId === 1 &&
                    !this.state.pendingInfoRequestData?.some((request) => 
                        { return pendingInfo?.clarificationTypeId === request?.clarificationTypeId }
                    )
                ) {
                    this.setState(prevState => ({
                        isPendingInfo: true,
                        pendingInfoRequestData: [...prevState.pendingInfoRequestData, pendingInfo],
                    }))
                    claimOverviewStatus = 'pendingShipping'
                }
            })
            if (this.state.pendingInfoRequestData.length > 1) {
                claimOverviewStatus = 'multiplePendingRequest'
                this.setState({
                    multiplePendingRequest: true
                })
            }
            //If we are trying to progress to a confirmation then we need to assign the claimOverview status maintained
            if (this.state.claimOverviewConfirmation) {
                claimOverviewStatus = currentClaimOverviewStatus
            }
        }
        //Handler for non pendingInfo Statuses
        if (claimOverviewStatus === '') {
            switch (claimData?.eventStatusId) {
                case 3:
                case 15:
                case 16:
                case 17:
                    return 'inReview'
                case 5:
                    return 'canceledClaim'
                case 2:
                    return 'closedClaim'
                case 18:
                    return 'resubmitPayment'
                case 7:
                    return 'deniedReview'
            }
        }

        return claimOverviewStatus
    }

    updateClaimOverviewStatus = (status, progressToConfirmation) => {
        if (progressToConfirmation) {
            this.setState({
                claimOverviewStatus: status,
                claimOverviewConfirmation: true
            })
        }
        else {
            this.setState({
                claimOverviewStatus: null,
                claimOverviewConfirmation: false
            })
        }
        this.fetchClaimOverview()
    }

    saveForLater = (e) => {
        e.preventDefault();
        e.preventDefault();

        this.setState({ uploading: true });
        let statusCode = '';
        const entitlementDetails = this.props.entitlementDetails;
        const partyModel = entitlementDetails?.party || null
        const model = { claimModel: this.state.claim, partyModel: partyModel }
        fetch(`api/claim/SaveForLater/${this.state.claimId}/5/0/apple`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        })
            //.then(function (res) {
            //    // this.setState({ isUpdatebtnDisable: true }); //disabling upload button aftrer file upload button click
            //    statusCode = res.status;
            //    return res.json();
            //})
            .then(data => {
                if (data.status === 204) {
                }
                if (data.status === 200) {
                    this.props.history.push('/vendor/apple/confirmation', { statusType: "savedClaim", caseNumber: this.props.location.state.claim.caseNumber });
                }
                this.setState({ uploading: false });
            });
    }

    handleCancelClaim = () => {
        const { claimNumber, caseNumber } = this.state
        updateRepair("apple", claimNumber, "CCCR")
            .then(res => {
                cancelClaim("apple", claimNumber, false);
                updatePartnerCase(claimNumber, "CCCR", "apple");
                this.props.history.push('/vendor/apple/confirmation', { statusType: "cancelledClaim", caseNumber });
            });
    }

    handleInputChange = e => {
        let { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleStateDropDown = e => {
        const state = this.state.states?.find(({ data }) => data === e)?.display;
        this.setState({ stateId: e, state: state });
    }

    handleVerifyAddress = () => {
        this.setState({ validationFired: true });

        if (this.validator.allValid()) {
            const shipState = this.state.stateId;
            const shipToCountry = this.state.shipToCountryCode ?? this.props.entitlementDetails?.countryCode;
            const stateInfo = this.state.states?.filter(function (state) {
                return state.data === shipState;
            });

            let addressToVerify = {
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: stateInfo[0]?.display,
                zip: this.state.zip,
                country: shipToCountry?.toLowerCase() ?? "us",
            }
            if (this.state.countryCode?.toLowerCase() === 'cl') {
                addressToVerify.state = this.state.state;
            }

            this.setState({
                addressToVerify: addressToVerify
            });

            verifyAddress(addressToVerify, this.state.clientId, this.onAddressVerified);
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onAddressVerified = (result) => {

        if (result.code === VerificationResponseCodes.addressVerified) {
            this.setState({
                showSuggestedAddressModal: false
            }, () => this.addressSubmit());
        } else if (result.code === VerificationResponseCodes.addressChanged) {
            this.setState({
                showSuggestedAddressModal: true,
                verifiedAddress: result.returnedAddress
            });
        } else {
            this.setState({
                showInvalidAddressModal: true
            });
        }
    }

    addressSubmit = () => {
        this.setState({
            searching: true
        })

        this.getApprovalStatus();
        const countryId = this.state.activeCountries?.find(({ countryCode }) => countryCode?.toLowerCase() === this.state.shipToCountryCode?.toLowerCase())?.countryId;
        let shippingAddress = {
            partyId: this.state.claimOverview?.replacementParty?.partyId,
            address1: this.state.address1,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            countryId: countryId,
        }

        fetch(`api/claim/PendingInformation/Resubmit/ShippingAddress/${this.state.clientId}`,
            {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        claimId: this.state.claimId,
                        shippingAddress: shippingAddress,
                    }
                )
            }).then(res => {
                if (res.ok) {
                    if (res.status === "204") {
                        res.json().then(data => {
                            this.setState((prev) => {
                                return {
                                    party: data,
                                    editing: !prev.editing,
                                    saving: !prev.saving
                                }
                            });
                        });
                    }
                    if (!this.state.multiplePendingRequest) {
                        this.updateClaimOverviewStatus('resubmitShipping', true);
                    }
                    else if (this.state.multiplePendingRequest) {
                        this.updateClaimOverviewStatus('resubmitShipping', false);
                    }
                    this.setState({ searching: false });
                }
            }).then(() => {
                fetch(`api/Contract/SaveContractConfiguration/${this.state.clientId}`,
                    {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(
                            {
                                contractId: this.state.contract?.contractId,
                                contractAttributeId: 32, // ship-to Country code
                                value: this.state.shipToCountryCode?.toLowerCase()
                            }
                        )
                    }).then(res => {
                        if (!res.ok) {
                            console.error(res);
                        }
                    })
            }).then(() => {
                if (this.state.isApproved) {
                    createRepair(this.state.clientId, this.state.claimNumber)
                        .then(res => {
                            if (res.ok) {
                                fetch('api/communication/'.concat(this.state.clientId), {
                                    method: 'post',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({

                                        emailModel: {
                                            Recipients: [this.state.claimOverview?.replacementShiptoEmail],
                                            Cc: [],
                                            Bcc: [],
                                            IsBodyHtml: true,
                                            emailType: 215
                                        },
                                        serviceModel: {
                                            SerialNumber: this.state.claimOverview?.serialNumber,
                                            CaseNumber: this.state.claimOverview?.caseNumber,
                                            ClaimId: parseInt(this.state.claimId),
                                            WarrantyObjectTypeId: 2
                                        }
                                    })
                                })

                                this.removeQueueItem()
                            }
                        })
                }
            });
    }

    onContinueSaveAction = (selectedAddress) => {
        if (selectedAddress === "suggestedAddress") {
            let newAddress = this.state.verifiedAddress;
            let suggestedState;
            let stateId = null;
            let state = null;
            if (this.state.states.length) {
                suggestedState = this.state.states.find(st => st.abbreviation === newAddress.stateAbbreviation || st.display === newAddress.stateAbbreviation);
                stateId = suggestedState ? suggestedState.data : null;
                state = suggestedState ? newAddress.stateAbbreviation : null;
            }
            if (this.state.countryCode?.toLowerCase() === 'cl') {
                state = newAddress.stateAbbreviation;
                stateId = null;
            }
            this.setState({
                showSuggestedAddressModal: false,
                address1: (`${newAddress.address1} ${newAddress.suite}`).trim(),
                address2: newAddress.address2,
                stateId: stateId,
                state: state,
                city: newAddress.city,
                zip: newAddress.zip,
            }, () => this.addressSubmit());
        }
        else {
            this.setState({
                showSuggestedAddressModal: false,
                showInvalidAddressModal: false
            }, () => this.addressSubmit());
        }
    }

    handleChangeShipToCountry = (states, shipToCountryCode) => {
        if (shipToCountryCode?.toLowerCase() === this.state.claimOverview?.replacementParty?.shippingAddress?.countryCode?.toLowerCase()) {
            this.setState({
                states: states,
                shipToCountryCode: shipToCountryCode?.toLowerCase(),
                state: this.state.claimOverview?.replacementParty?.shippingAddress?.state || '',
                stateId: this.state.claimOverview?.replacementParty?.shippingAddress?.stateId || '',
            })
        } else {
            this.setState({
                states: states,
                shipToCountryCode: shipToCountryCode?.toLowerCase(),
                stateId: null,
                state: ''
            })
        }
    }

    onCloseAddressModal = () => {
        this.setState({
            showSuggestedAddressModal: false,
            showInvalidAddressModal: false
        });
    }

    stopPageProgression = () => {
        this.setState({ continuing: false })
    }

    handleToggleCancelClaimModal = (action) => {
        switch(action) {
            case "OPEN":
                this.setState({showCancelModal: true})
                return
            case "CLOSE":
                this.setState({showCancelModal: false})
                return
            default: return
        }
    }

    removeQueueItem = e => {
        const claimId = this.state.claimId ?? this.state.claimOverview?.claimId ?? 0;
        fetch('api/queue/RemoveItemFromQueue/' + claimId + '/' + this.state.clientId, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (res) {
            console.log(res);
        })
    }

    getApprovalStatus = async () => {
        try {
            const res = await fetch('api/history/GetApprovedStatus/' + this.state.claimId + '/' + this.state.clientId);
            const data = await res.json();
            this.setState({
                isApproved: data.objectHistoryId !== 0 && data.objectHistoryId !== undefined ? true : false
            });
        }
        catch (error) {
            console.error(error)
        }
    }

    allowPendingStatuses = () => {
        //Do not return pending status if status in Denied, Canceled, or Closed
        if (this.state.claimOverview.pendingInfoRequestData !== null &&
            this.state.isPendingInfo &&
            this.state.claimOverview?.eventStatusId !== 2 &&
            this.state.claimOverview?.eventStatusId !== 5 &&
            this.state.claimOverview?.eventStatusId !== 7 &&
            !this.state.claimOverviewConfirmation
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    getClaimReviewStatus = () => {
        if (this.state.claimOverview !== null) {
            if (this.state.claimOverview.isClaimApproved) {
                return 'Approved';
            }
            else if (this.allowPendingStatuses()) {
                return 'Pending Info';
            }
            else {
                return this.state.claimOverview.eventStatusId;
            }
        }
        else {
            return null;
        }
    }

    getDeviceShippedStatus = () => {
        if (this.state.claimOverview !== null && this.state.claimOverview?.isClaimApproved) {
            if (this.state.claimOverview.trackingNumber !== null) {
                return 'Shipped';
            }
            else {
                return this.state.isPendingInfo ? 'Pending Info' : 'Processing'
            }
        }
        else {
            return null;
        }
    }

    getErrorMessage = (translations) => {
        return this.state.errorMessage ? (translations?.errorMessage || this.state.errorMessage) : ''
    }

    getClaimReviewStyle = () => {
        if (this.state.claimOverview !== null) {
            if (!this.state.claimOverview?.isClaimApproved) {
                if (this.allowPendingStatuses()) {
                    return 'pending';
                }

                switch (this.state.claimOverview?.eventStatusId) {
                    case 3:
                    case 15:
                    case 16:
                    case 17:
                        return 'active';

                    case 7:
                    case 12:
                        return 'pending'

                    case 2:
                    case 5:
                    case 18:
                        return 'alert';

                    default:
                        return 'active';
                }
            }
            else {
                return 'completed'
            }
        }
        else {
            return 'active';
        }
    }

    getDeviceShippedStyle = () => {
        const deviceShippedStatus = this.getDeviceShippedStatus();

        if (deviceShippedStatus === 'Shipped') {
            return 'completed';
        }
        else if (deviceShippedStatus === 'Pending Info') {
            return 'pending';
        }
        else {
            return 'active';
        }
    }

    render() {
        const { pageTitle, claimOverview, showCancelModal } = this.state;
        const countryCode = this.props?.history?.location?.state?.entitlementDetails?.countryCode
        let shipToAddress = claimOverview?.replacementParty?.shippingAddress || claimOverview?.replacementParty?.serviceAddress;
        let replacementShipToAddress;
        const { contractContext: { data: { isChileApple } } } = this?.context;
        if (claimOverview) {
            replacementShipToAddress = {
                firstName: claimOverview?.replacementParty?.firstName,
                lastName: claimOverview?.replacementParty?.lastName,
                address1: shipToAddress.address1,
                address2: shipToAddress.address2,
                zip: shipToAddress.zip,
                city: shipToAddress.city,
                state: shipToAddress.state || '',
                stateId: shipToAddress.stateId,
                phone: phoneFormat(claimOverview?.replacementShiptoPhone),
                email: claimOverview.replacementShiptoEmail,
                countryCode: shipToAddress.countryCode
            }
        }

        return (
            <DocumentTitle title={pageTitle} >
                <UseTranslationInClassComponent componentPathFromSrcFolder={'screens/Vendor/Apple/ClaimOverview'}>
                    {international => {
                        const {
                            englishVars,
                            country,
                            countryVars: {
                                date: {
                                    momentJsFormat,
                                    momentJsLongFormat
                                }
                            },
                            translations,
                        } = international;
                        const steps = (dateFormat) => [{
                            name: translations.claimReceived || 'Claim Received',
                            style: 'completed',
                            date: moment(claimOverview != null ? claimOverview.claimSetupDate != null ? claimOverview.claimSetupDate : "" : "").format(dateFormat),
                            id: 'claimReceived'
                        }, {
                            name: translations.claimReview || 'Claim Review',
                            style: this.getClaimReviewStyle(),
                            date: null,
                            status: this.getClaimReviewStatus(),
                            id: 'claimReview'

                        }, {
                            name: translations.deviceShipment || 'Device Shipment',
                            style: this.getDeviceShippedStyle(),
                            date: null,
                            status: this.getDeviceShippedStatus(),
                            tracking: claimOverview != null ? claimOverview.trackingNumber != null ? claimOverview.trackingNumber : null : null,
                            hideStep: this.getClaimReviewStatus() === 2 || this.getClaimReviewStatus() === 5,
                            id: 'deliveryId'
                        }]

                        const localAppleCustomerServicePhone = appleServiceNumbers.customerService[country]
                        const localApplePhone = appleServiceNumbers.incorrectDevice[country]

                        return (
                            <Container className="contentWrapper pl-md-0">
                                {!this.state.fetchingClaimOverview &&
                                    <Row>
                                        <Col lg="7" className={classNames(overviewBody, mrAuto, orderLast, orderLGFirst)}>
                                            {this.state.claimOverviewStatus === 'uploadDocsFNOL' &&
                                                <section id="uploadDocsWrapper">
                                                    <UploadDocs
                                                        history={this.props.history}
                                                        pendingDocs="true"
                                                        isClaimOverview={false}
                                                        onDocumentUploadSuccess={this.callBackUploadDocSuccess}
                                                        onSaveForLaterAction={this.saveForLater}
                                                        claimNumber={this.props.claimNumber}
                                                        entitlementDetails={this.props.entitlementDetails}
                                                        isSubmittedClaim={this.state.isDeductibleUnsuccessful && (this.state.isPendingReview || this.state.isClaimDenied)}
                                                        hideCancelClaimButton={!this.state.isDeductibleUnsuccessful}
                                                    />
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'multiplePendingRequest' &&
                                                <section id="multiplePendingRequest" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>
                                                        {translations?.multiplePendingRequest?.title || "Claim in Review"}
                                                        <span className={styles.actionRequiredBadge}>
                                                            {translations?.actionRequiredBadge || 'Action Required'}
                                                        </span>
                                                    </h1>
                                                    <p className='mb-0'>
                                                        {translations?.multiplePendingRequest?.p1 || 'To process your claim, we need you to provide the following information:'}
                                                    </p>
                                                    <ul className={styles.pendingInfoRequired}>
                                                        {this.state.pendingInfoRequestData?.map(pendingInfo => {
                                                            if (pendingInfo?.clarificationTypeId === 1) {
                                                                //Type Id 1 means Pending - Documentation
                                                                return (
                                                                    <li>
                                                                        {translations?.multiplePendingRequest?.pendingDoc || 'Additional documentation for review.'}
                                                                    </li>
                                                                )
                                                            }
                                                            if (pendingInfo?.clarificationTypeId === 2) {
                                                                //Type Id 2 means Pending - Incident Description
                                                                return (
                                                                    <li>
                                                                        {translations?.multiplePendingRequest?.pendingIncident || 'A detailed description of your incident.'}
                                                                    </li>
                                                                )
                                                            }
                                                            if (pendingInfo?.clarificationTypeId === 3) {
                                                                //Type Id 3 means Pending - Shipping Address
                                                                return (
                                                                    <li>
                                                                        {translations?.multiplePendingRequest?.pendingShipping || 'Confirmation of where you would like the device shipped'}
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </ul>

                                                    <StandardContent
                                                        claimEmail={this.state.claimEmail}
                                                        standardContentOption='communicationUpdates'
                                                    />
                                                    <p className='mb-0 font-weight-bold'>
                                                        {translations?.pendingIncidentDescription?.p5 || 'Please note the following:'}
                                                    </p>
                                                    <div className={styles.standardContentWrap}>
                                                        <StandardContent
                                                            standardContentOption='pendingAuth'
                                                            customClass='font-weight-bold'
                                                        />
                                                        <StandardContent
                                                            standardContentOption='failureToProvide'
                                                            customClass='font-weight-bold'
                                                        />
                                                    </div>
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                    <Row>
                                                        <IntakeLossButtons
                                                            hideButtonForSubmit={true}
                                                            caseNumberToCancel={this.state.caseNumber}
                                                            claimNumberToCancel={this.state.claimNumber}
                                                            //FOR CLAIMOVERVIEW HANDLING
                                                            isClaimOverview={true}
                                                        />
                                                    </Row>
                                                </section>
                                            }
                                            {(this.state.claimOverviewStatus === 'pendingDocs' || this.state.pendingInfoRequestData?.find(({ clarificationTypeId }) => clarificationTypeId === 1)) &&
                                                <section id="pendingDocs" className={styles.overviewSection}>
                                                    <UploadDocs
                                                        history={this.props.history}
                                                        pendingDocs="true"
                                                        isClaimOverview={true}
                                                        isMultiplePendingRequest={this.state.multiplePendingRequest}
                                                        onDocumentUploadSuccess={this.callBackUploadDocSuccess}
                                                        caseNumber={this.state.caseNumber}
                                                        claimNumber={this.state.claimNumber}
                                                        claim={this.state.claimOverview}
                                                        claimEmail={this.state.claimEmail}
                                                        pendingInfoDocuments={this.state.pendingDocs}
                                                        isSubmittedClaim={this.state.isDeductibleUnsuccessful && (this.state.isPendingReview || this.state.isClaimDenied)}
                                                        updateClaimOverviewStatus={this.updateClaimOverviewStatus}
                                                    />
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'canceledClaim' &&
                                                <section id="canceledClaim" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.canceled?.title || "Your Claim has been Canceled"}</h1>
                                                    <div className={styles.standardContentWrap}>
                                                        {translations?.canceled?.p1Prefix || 'Case ID '}
                                                        {this.state.caseNumber}
                                                        {translations?.canceled?.p1Suffix || ' has been canceled. '}
                                                        <StandardContent
                                                            caseNumber={this.state.caseNumber}
                                                            standardContentOption='unlockDevice'
                                                        />
                                                    </div>
                                                    <p>
                                                        {translations?.canceled?.p2Prefix || 'If your claim has been canceled in error and you wish to initiate a claim, please visit '}
                                                        <a className='btnLink' href="https://support.apple.com/iphone/theft-loss-claims " target="_blank">
                                                            {translations?.canceled?.p2Link || 'Iphone Theft and Loss Claims'}
                                                        </a>
                                                        {translations?.canceled?.p2Suffix || ' or call Apple directly at '}
                                                        <CallLink phone={localApplePhone} />
                                                        {translations?.canceled?.p2SuffixEnding || "."}
                                                    </p>
                                                    <StandardContent
                                                        claimEmail={this.state.claimEmail}
                                                        standardContentOption='communicationUpdates'
                                                    />
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'closedClaim' &&
                                                <section id="closedClaim" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.closed?.title || "Your Claim has been Closed"}</h1>
                                                    <p>
                                                        {translations?.closed?.p1Prefix || 'Unfortunately, your Theft and Loss claim was closed on '}
                                                        {moment(this.state.claimClosureDate)?.format(momentJsFormat)}
                                                        {translations?.closed?.p1Suffix || ' due to claim inactivity or failure to provide the required details in a timely manner.'}
                                                    </p>
                                                    <p>
                                                        {translations?.closed?.p2Prefix || 'If your claim has been closed in error and you wish to initiate a claim, please visit '}
                                                        <a className='btnLink' href="https://support.apple.com/iphone/theft-loss-claims " target="_blank">
                                                            {translations?.closed?.p2Link || 'Iphone Theft and Loss Claims'}
                                                        </a>
                                                        {translations?.closed?.p2Suffix || ' or call Apple directly at '}
                                                        <CallLink phone={localApplePhone} />
                                                        {translations?.closed?.p2SuffixEnding || "."}
                                                    </p>
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'resubmitPayment' &&
                                                <section id="resubmitPayment" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>
                                                        {translations?.resubmitPayment?.title || "Resubmit Payment"}
                                                        <span className={styles.actionRequiredBadge}>
                                                            {translations?.resubmitPayment?.badge || 'Action Required'}
                                                        </span>
                                                    </h1>
                                                    <p>
                                                        {translations?.resubmitPayment?.p1 || `To complete your claim and receive a replacement device, please update your payment method. Your claim has been approved, however, the attempt to collect the ${englishVars.deductibleName?.toLowerCase()} on the payment method previously provided failed.`}
                                                    </p>
                                                    <StandardContent standardContentOption='failureToProvide' />
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'inReview' &&
                                                <section id="claimInReview" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.inReview?.title || "Claim in Review"} </h1>
                                                    <StandardContent standardContentOption='contactTwoDays' />
                                                    <StandardContent standardContentOption='pendingAuth' />
                                                    <StandardContent
                                                        claimEmail={this.state.claimEmail}
                                                        standardContentOption='communicationUpdates'
                                                    />
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'docsInReview' &&
                                                <section id="docsInReview" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.docsInReview?.title || "Claim in Review"} </h1>
                                                    <div className={styles.standardContentWrap}>
                                                        {translations?.docsInReview?.p1 || 'Thank you for uploading your document(s). '}
                                                        <StandardContent standardContentOption='agentReview' />
                                                    </div>
                                                    <StandardContent
                                                        claimEmail={this.state.claimEmail}
                                                        standardContentOption='communicationUpdates'
                                                    />
                                                    <div className={styles.standardContentWrap}>
                                                        <StandardContent standardContentOption='pendingAuth' />
                                                        <StandardContent standardContentOption='failureToProvide' />
                                                    </div>
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'incidentDescription' &&
                                                <section id="incidentDescription" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.incidentDescription?.title || "Claim in Review"} </h1>
                                                    <div className={styles.standardContentWrap}>
                                                        <p>
                                                            {translations?.incidentDescription?.p1 || 'Thank you for providing additional information regarding your claim. '}
                                                        </p>
                                                        <StandardContent standardContentOption='agentReview' />
                                                    </div>
                                                    <StandardContent
                                                        claimEmail={this.state.claimEmail}
                                                        standardContentOption='communicationUpdates'
                                                    />
                                                    <p className='mb-0'>
                                                        {translations?.incidentDescription?.p3 || 'Please note the following:'}
                                                    </p>
                                                    <div className={styles.standardContentWrap}>
                                                        <StandardContent standardContentOption='pendingAuth' />
                                                        <StandardContent standardContentOption='failureToProvide' />
                                                    </div>
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                </section>
                                            }
                                            {(this.state.claimOverviewStatus === 'pendingIncidentDescription' || this.state.pendingInfoRequestData?.find(({ clarificationTypeId }) => clarificationTypeId === 2)) &&
                                                <section id="pendingIncidentDescription" className={styles.overviewSection}>
                                                    {this.state.multiplePendingRequest ?
                                                        <h1 className={classNames('h3', btmLine)}>
                                                            {translations?.multiplePendingRequest?.detailedIncident || "Detailed Incident Description"}
                                                        </h1>
                                                        :
                                                        <h1 className={classNames(h2, btmLine)}>
                                                            {translations?.pendingIncidentDescription?.title || "Claim in Review"}
                                                            <span className={styles.actionRequiredBadge}>
                                                                {translations?.actionRequiredBadge || 'Action Required'}
                                                            </span>
                                                        </h1>
                                                    }
                                                    <p>
                                                        {translations?.pendingIncidentDescription?.p1 || 'To process your claim we need additional information regarding your incident.'}
                                                    </p>
                                                    <Entitlement
                                                        claim={this.state.claimOverview}
                                                        claimOverviewVersion={true}
                                                        isMultiplePendingRequest={this.state.multiplePendingRequest}
                                                        submitting={this.state.continuing}
                                                        stopPageProgression={this.stopPageProgression}
                                                        pendingInfoRequestId={this.state.pendingInfoRequestId}
                                                        requestClarificationId={this.state.requestClarificationId}
                                                        updateClaimOverviewStatus={this.updateClaimOverviewStatus}
                                                    />
                                                    {!this.state.multiplePendingRequest &&
                                                        <>
                                                            <StandardContent
                                                                claimEmail={this.state.claimEmail}
                                                                standardContentOption='communicationUpdates'
                                                            />
                                                            <p className='mb-0 font-weight-bold'>
                                                                {translations?.pendingIncidentDescription?.p5 || 'Please note the following:'}
                                                            </p>
                                                            <div className={styles.standardContentWrap}>
                                                                <StandardContent
                                                                    standardContentOption='pendingAuth'
                                                                    customClass='font-weight-bold'
                                                                />
                                                                <StandardContent
                                                                    standardContentOption='failureToProvide'
                                                                    customClass='font-weight-bold'
                                                                />
                                                            </div>
                                                            <StandardContent
                                                                caseNumber={this.state.caseNumber}
                                                                standardContentOption='contactCustomerService'
                                                            />
                                                        </>
                                                    }
                                                    <Row>
                                                        <IntakeLossButtons
                                                            buttonText={translations.submit || "Submit"}
                                                            onButtonClick={() => this.setState({
                                                                continuing: true
                                                            })}
                                                            buttonDisabled={this.state.continuing}
                                                            buttonSpinning={this.state.continuing}
                                                            //CANCEL LINK PROPS
                                                            hideCancelClaimButton={this.state.multiplePendingRequest}
                                                            caseNumberToCancel={this.state.caseNumber}
                                                            claimNumberToCancel={this.state.claimNumber}
                                                            //FOR CLAIMOVERVIEW HANDLING
                                                            isClaimOverview={true}
                                                        />
                                                    </Row>
                                                </section>
                                            }
                                            {this.state.claimOverviewStatus === 'resubmitShipping' &&
                                                <section id="resubmitShipping" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.resubmitShipping?.title || "Resubmit Shipping Address"} </h1>
                                                    <div className={styles.standardContentWrap}>
                                                        <p className='font-weight-bold'>{translations?.resubmitShipping?.p1 || 'Thank you for updating your shipping address. '}</p>
                                                        <StandardContent standardContentOption='replacement' />
                                                    </div>
                                                    <StandardContent
                                                        claimEmail={this.state.claimEmail}
                                                        standardContentOption='shippingCommunicationUpdate'
                                                    />
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                </section>
                                            }
                                            {(this.state.claimOverviewStatus === 'pendingShipping' || this.state.pendingInfoRequestData?.find(({ clarificationTypeId }) => clarificationTypeId === 3)) &&
                                                <section id="pendingShipping" className={styles.overviewSection}>
                                                    <h1 className={classNames(`${this.state.multiplePendingRequest ? 'h3' : 'h2'}`, btmLine)}>
                                                        {translations?.pendingShipping?.title || "Resubmit Shipping Address"}
                                                        {!this.state.multiplePendingRequest &&
                                                            <span className={styles.actionRequiredBadge}>
                                                                {translations?.actionRequiredBadge || 'Action Required'}
                                                            </span>
                                                        }
                                                    </h1>
                                                    <p>
                                                        {translations?.pendingShipping?.p1 || 'To complete your claim and receive a replacement device, please confirm the address where you would like your replacement device shipped.'}
                                                    </p>
                                                    <Col className="p-0" md="8">
                                                        <AddressForm
                                                            allowVariableCountry={true}
                                                            includeNameFields={false}
                                                            clientId={this.state.clientId}
                                                            isChileApple={isChileApple}
                                                            showCountryChangeModal={true}
                                                            onUpdateStates={this.handleChangeShipToCountry}
                                                            handleInputChange={this.handleInputChange}
                                                            handleStateDropDown={this.handleStateDropDown}
                                                            address={{
                                                                address1: this.state.address1,
                                                                address2: this.state.address2,
                                                                city: this.state.city,
                                                                state: this.state.state,
                                                                stateId: this.state.stateId,
                                                                zip: this.state.zip,
                                                            }}
                                                            validator={this.validator}
                                                            validationFired={this.state.validationFired}
                                                            states={this.state.states}
                                                        />
                                                    </Col>
                                                    {!this.state.multiplePendingRequest &&
                                                        <>
                                                            <StandardContent
                                                                claimEmail={this.state.claimEmail}
                                                                standardContentOption='communicationUpdates'
                                                            />
                                                            <p className='mb-0 font-weight-bold'>
                                                                {translations?.pendingShipping?.p2 || 'Please note the following:'}
                                                            </p>
                                                            <StandardContent
                                                                customClass='font-weight-bold'
                                                                standardContentOption='failureToProvide'
                                                            />
                                                            <StandardContent
                                                                caseNumber={this.state.caseNumber}
                                                                standardContentOption='contactCustomerService'
                                                            />
                                                        </>
                                                    }
                                                    <button
                                                        className="btn btn-primary mt-4"
                                                        tabIndex="0"
                                                        color="secondary"
                                                        onClick={this.handleVerifyAddress}
                                                    >
                                                        {translations.submitButton || "Submit"}
                                                    </button>
                                                </section>
                                            }
                                            {/* ====== FUTURE STORY -  Not accessible currently ====== */}
                                            {/* {this.state.claimOverviewStatus === 'denied' &&
                                                <section id="denied" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.denied?.title || "Your Claim has been Denied"} </h1>
                                                    <p>
                                                        {translations?.denied?.p1Prefix || 'Unfortunately, after reviewing the details of your claim, we are unable to proceed with a replacement at this time. Details regarding your claim status have been emailed to '}
                                                        {this.state.claimEmail}
                                                        {translations?.denied?.p1Suffix || ', however, the information for your review is provided below:'}
                                                    </p>
                                                    TODO: INFO Needed for link
                                                    <p>
                                                        <a className='btnLink'>
                                                            {translations?.denied?.p2Link || 'Download Claim Review Details'}
                                                            <DownloadIcon className={styles.downloadIcon}/>
                                                        </a>
                                                    </p>
                                                    <p>
                                                        {translations?.denied?.p3Prefix || 'To review the terms and conditions of your AppleCare+ with Theft and Loss Insurance Plan, please view '}
                                                        <a className='btnLink' href="/vendor/apple/coveragedocs" target="_blank">
                                                            {translations?.denied?.coverageDocsLink || 'Coverage Documents'}
                                                        </a>
                                                        {translations?.denied?.p3Suffix || '.'}
                                                    </p>
                                                    <StandardContent 
                                                        caseNumber={this.state.caseNumber} 
                                                        standardContentOption='contactCustomerService'
                                                    /> 
                                                </section>
                                            } */}
                                            {this.state.claimOverviewStatus === 'deniedReview' &&
                                                <section id="deniedReview" className={styles.overviewSection}>
                                                    <h1 className={classNames(h2, btmLine)}>{translations?.deniedReview?.title || "Your Claim has been Reviewed"} </h1>
                                                    <p>
                                                        {translations?.deniedReview?.p1Prefix || 'We have reviewed your claim and will be sending an email to '}
                                                        {this.state.claimEmail}
                                                        {translations?.deniedReview?.p1Suffix || ' with details regarding your claim status within 1 business day. Please check your email regularly and look for an email from aigtheftandloss@aig.com.'}
                                                    </p>
                                                    <StandardContent
                                                        caseNumber={this.state.caseNumber}
                                                        standardContentOption='contactCustomerService'
                                                    />
                                                </section>
                                            }
                                            {(this.state.claimOverviewStatus === 'inReview' || this.state.claimOverviewStatus === 'incidentDescription' || this.state.claimOverviewStatus === 'docsInReview') &&
                                                <>
                                                    <button
                                                        className="btnLink"
                                                        tabIndex="0"
                                                        aria-label={translations.cancelClaimAria || "Opens a modal to cancel your claim"}
                                                        color="secondary"
                                                        onClick={() => this.handleToggleCancelClaimModal("OPEN")}
                                                    >
                                                        {translations.cancelClaim || "Cancel Claim"}
                                                    </button>
                                                    <CancelModal
                                                        claimNumber={this.state.claimNumber}
                                                        caseNumber={this.state.caseNumber}
                                                        isOpen={showCancelModal}
                                                        handleCancel={this.handleCancelClaim}
                                                        onCloseModal={() => this.handleToggleCancelClaimModal("CLOSE")}
                                                        clientId={this.props?.history?.location?.state?.entitlementDetails?.clientId || 'apple'}
                                                        isSubmittedClaim
                                                    />
                                                </>
                                            }
                                            {this.state.claimOverviewStatus === 'resubmitPayment' &&
                                                <>
                                                    <button
                                                        className="btn btn-primary"
                                                        tabIndex="0"
                                                        color="secondary"
                                                    // TODO PAYMENT SCREEN STORY STILL TBD
                                                    // onClick={() => this.props.history.push(
                                                    //     '/vendor/apple/payment',
                                                    //     {
                                                    //         entitlementDetails: this.props?.history?.location?.state?.entitlementDetails,
                                                    //         claim: this.props?.history?.location?.state?.claim,
                                                    //         claimOverviewPaymentVersion: true
                                                    //     }
                                                    // )}
                                                    >
                                                        {translations.updatePaymentButton || "Update Payment"}
                                                    </button>
                                                </>
                                            }
                                            {this.state.showSuggestedAddressModal &&
                                                <SuggestedAddress
                                                    showSuggestedAddressValidationModal={this.state.showSuggestedAddressModal}
                                                    enteredAddress={this.state.addressToVerify}
                                                    suggestedAddress={this.state.verifiedAddress}
                                                    onContinueAction={this.onContinueSaveAction}
                                                    onEditAddress={this.onCloseAddressModal}
                                                    clientId={this.state.clientId}

                                                />
                                            }
                                            {this.state.showInvalidAddressModal &&
                                                <InvalidAddress
                                                    showInvalidAddressModal={this.state.showInvalidAddressModal}
                                                    onEditAddress={this.onCloseAddressModal}
                                                    onContinueAction={this.onContinueSaveAction}
                                                />
                                            }
                                            <ClaimDetails
                                                ccType={this.state.ccType}
                                                ccNumber={this.state.ccNumber}
                                                claimOverview={this.state.claimOverview}
                                                pendingIncidentStatus={this.state.pendingInfoRequestData?.find(({ clarificationTypeId }) => clarificationTypeId === 2)}
                                                errorMessage={this.getErrorMessage(translations)}
                                                caseNumber={this.state.caseNumber}
                                                address={replacementShipToAddress}
                                                clientId={this.props?.history?.location?.state?.entitlementDetails?.clientId || 'apple'}
                                                countryCode={this.props?.history?.location?.state?.entitlementDetails?.shipToCountryCode}
                                            />
                                        </Col>
                                        <Progress  {...this.state} steps={steps(momentJsLongFormat)} />
                                    </Row>
                                }
                                {this.state.fetchingClaimOverview && (
                                    <PageLoading text={''} height={500} />
                                )}
                            </Container>
                        )
                    }}
                </UseTranslationInClassComponent>
            </DocumentTitle>
        );
    }
}

ClaimOverview.contextType = CombinedLangContractContext;