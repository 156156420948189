import React, { useState, useRef, useContext, useEffect } from "react";
import { Container, Col, FormGroup, Label, Card, CardBody, CardFooter,Row, CardHeader } from "reactstrap";
import styles from "./CoverageDocuments.module.css";
import useLoadAtTop from "../../components/_ReactHooks/useLoadAtTop";
import { DropDownInput } from "../../components/inputs/DropDownInput";
import DocumentTitle from 'react-document-title';
import SimpleReactValidator from "simple-react-validator";
import { ContractContext  } from '../contexts/ContractContext';
import CallLink from '../shared/PhoneNumberUtility/CallLink';
import getClientContent from '../_BrandingProvider/brandContent';
import moment from 'moment';
import { openDocument } from '../Global/DocumentHelper';
import classNames from 'classnames';
import { useHistory } from 'react-router';

const CoverageDocuments = (props) => {
    useLoadAtTop()
    const context = useContext(ContractContext);
    const history = useHistory()
    const [selectedPlanType, setSelectedPlanType] = useState("");
    const [selectedPurchasePlan, setSelectedPurchasePlan] = useState("");
    const [validationFired, setValidationFired] = useState(false);
    const [, forceUpdate] = useState();
    const [selectCoveredItem, setSelectedCoveredItem] = useState('');
    const validator = useRef(
        new SimpleReactValidator({
            element: (message) => (
                <div className="errorMsg" aria-live="polite">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
        })
    );

    const { data: { clientId } } = useContext(ContractContext);
    let { phone } = getClientContent(clientId)?.variableText?.customerService;
    const clientTextValues = getClientContent(clientId);
    const title = clientTextValues.variableText.pageTitle;

    const pageTitle = title ? `Coverage Documents | ${title}` : 'Coverage Documents';
    const [state, setState] = useState({
        coverageDocs: [],
        planTypeOptions: [],
        coveredItemOptions: [],
        purchasePlanOptions: []
    })
    const coveragePlanType = context.data?.coveragePlanType ?? 'HomeWarranty';

    useEffect(() => {
        getCoverageDocs();
    }, [clientId])

    const getCoverageDocs = async () => {
        fetch(`api/document/GetCoverageDocuments/${clientId}`)
            .then(res => res.json())
            .then(data => {
                if (Array.isArray(data)) {
                    let planTypeOptions = [];
                    let coveredItemOptions = [];

                    if (coveragePlanType.toLowerCase() === "account"){
                        data.map(asset => {
                            if (!planTypeOptions.find(existingAsset => existingAsset.data == asset.warrantyObjectValue)) {
                                planTypeOptions.push({
                                    data: asset.warrantyObjectValue,
                                    display: asset.mappedDocumentAssetName
                                })
                            }
                        })
                    } else {
                        data[0]?.assetCoverageModels?.map(asset => {
                            if (!coveredItemOptions.find(existingAsset => existingAsset.data == asset.assetId)) {
                                coveredItemOptions.push({
                                    data: asset.assetId,
                                    display: asset.assetName
                                })
                            }
                        })
                    }  

                    setState(prev => ({
                        ...prev,
                        coverageDocs: data,
                        planTypeOptions,
                        coveredItemOptions
                    }));
                }
            });
    }

    const getDocVersions = (warrObjectValue) => {
        const { coverageDocs } = state;
        const filteredDocs = coverageDocs.filter(x => x.warrantyObjectValue == warrObjectValue);
        const purchasePlanOptions = filteredDocs.map(x => {
            return {
                data: x.documentId,
                display: `Purchased on or after ${moment(x.effectiveFrom).format("MMMM DD, YYYY")}`
            }
        })

        setState(prev => ({
            ...prev,
            purchasePlanOptions
        }));

        if (Array.isArray(purchasePlanOptions)) {
            setSelectedPurchasePlan(purchasePlanOptions[0].data);
        }
    }

    const handlePlanTypeDropdown = (e) => {
        getDocVersions(e)
        setSelectedPlanType(e);
    }

    const handlePurchaseDropDown = (e) => {
        setSelectedPurchasePlan(e);
    }

    const handleSubmit = () => {
        setValidationFired(true);
        if (validator.current.allValid()) {
            openDocument(12, selectedPlanType, selectedPurchasePlan, clientId);
        } else {            
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleViewTermsCondtions = () => {
        openDocument(12, selectedPlanType, selectedPurchasePlan, clientId);
    }
    
    const handleAssetCoverageOverview = () => {
        setValidationFired(true);
        if (validator.current.allValid()) {
            const pathName = '/assetcoverageoverview';
            
            history.push({
                pathname: pathName,
                state: {
                    assetName: getAssetName(selectCoveredItem)
                }
            });

        } else {            
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleCoveredItemDropdown = (e) => {
        setSelectedCoveredItem(e);
    }

    const getAssetName = (assetId) => {
        const filteredAssets = state.coveredItemOptions.filter(asset => asset.data === assetId);
        return filteredAssets[0]?.display;
    }
    
    return (
        <DocumentTitle title={pageTitle}>
            <>
                <div className={styles.pageHeader}>
                    {coveragePlanType?.toLowerCase() === 'account' &&
                        <Container className={styles.pageHeaderContent}>
                            <h1>Terms and Conditions</h1>
                            <p className="mb-0 text-center">
                                Terms and Condition vary depending on the date of purchase and the type of plan. 
                                Choose from the selections below to view and print the appropriate version.
                            </p>
                        </Container>
                    }
                    {coveragePlanType?.toLowerCase() === 'homewarranty' &&
                        <Container className={styles.pageHeaderContent}>
                            <h1 class="mb-0">Want to View Your Coverage Details?</h1>
                            <p className="mb-0 text-center">
                                View the complete terms and conditions of your current plan or review 
                                coverage details on individual covered products and home systems.
                            </p>
                        </Container> 
                    }
                </div>
                {coveragePlanType?.toLowerCase() === 'account' &&
                    <Container>
                        <div className={styles.termsForm}>
                            <Col className="mb-2 p-0">
                                <FormGroup className={(validationFired && !selectedPlanType) ? "form-error" : ""}>
                                    <Label htmlFor="planType" className="form-label font-weight-bold">Type of Plan</Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownInput
                                        name="planType"
                                        options={state.planTypeOptions}
                                        className="form-control"
                                        onChangedValue={handlePlanTypeDropdown}
                                        placeholder={"Please select..."}
                                        id="planType"
                                        defaultValue={selectedPlanType}
                                    />
                                    {validator.current.message(
                                        "planType",
                                        selectedPlanType,
                                        "required",
                                        { messages: {required: "Type of Plan is required."} }
                                    )}
                                </FormGroup>
                                <FormGroup className={(validationFired && !selectedPurchasePlan) ? "form-error" : ""}>
                                    <Label htmlFor="purchaseDate" className="form-label font-weight-bold">
                                        When Did You Purchase the Plan?
                                    </Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownInput
                                        name="purchasePlanDate"
                                        options={state.purchasePlanOptions}
                                        className="form-control"
                                        onChangedValue={handlePurchaseDropDown}
                                        placeholder={"Please select..."}
                                        id="purchasePlanDate"
                                        defaultValue={selectedPurchasePlan}
                                    />
                                    {validator.current.message(
                                        "purchasePlanDate",
                                        selectedPurchasePlan,
                                        "required",
                                        { messages: {required: "Please select a date range."} }
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="12" md="12" className="d-flex flex-center mb-2">
                                <button
                                    className="btn btn-primary mb-2 mt-2"
                                    onClick={handleSubmit}
                                >
                                    View Terms and Conditions
                                </button>
                            </Col>
                            <Col className="text-center">
                                <p className="mb-0">Need Help?</p>
                                <p><CallLink phone={phone} /></p>
                            </Col>
                        </div>
                    </Container>
                }
                {coveragePlanType?.toLowerCase() === 'homewarranty' &&
                    <Container className={classNames(styles.containerPadding, "card")}>
                        <Row className="twoColWrapper">
                        <Col>
                            <Card className={styles.coverageCard}>
                                <CardBody className={styles.pageHeaderContent}>
                                <h4 className={styles.coverageHeader}>Service Plan Terms and Conditions</h4>
                                <Row>
                                    <FormGroup>
                                        <button className="btn btn-primary" onClick={handleViewTermsCondtions}>
                                            Terms and Conditions
                                        </button>
                                    </FormGroup>
                                </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className={styles.coverageCard}>
                                <CardBody className={styles.pageHeaderContent}>
                                    <h4 className={styles.coverageHeader}>Coverage Details by Covered Item</h4>
                                    <p className="mb-3 text-center">
                                        Select a covered product or home system to view coverage details and exclusions.
                                    </p>
                                    <Row>
                                        <FormGroup className={classNames(styles.coverageItemDropdown, (validationFired && !selectCoveredItem) ? "form-error" : "")}>
                                            <Label htmlFor="coverageItem" className={classNames("form-label", styles.coveredItemLabel)}>Covered Item</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <DropDownInput
                                                name="coverageItem"
                                                options={state.coveredItemOptions}
                                                className="form-control"
                                                onChangedValue={handleCoveredItemDropdown}
                                                placeholder={"Please select..."}
                                                id="covItemType"
                                                defaultValue={selectCoveredItem}
                                            />
                                            {validator.current.message(
                                                "covItemType",
                                                selectCoveredItem,
                                                "required",
                                                { messages: {required: "Covered Item is required."} }
                                            )}
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <button
                                            className="btn btn-primary mb-2 mt-2"
                                            onClick={handleAssetCoverageOverview}
                                            >
                                                View Details
                                            </button>
                                        </FormGroup>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                } 
            </>
        </DocumentTitle>
    )
}

export default CoverageDocuments