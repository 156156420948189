import { ContractContext } from '../contexts/ContractContext';
import React, { useContext } from 'react';
import { clearSession } from '../../helpers.jsx';
import { useHistory } from 'react-router-dom';

export const useLogout = () => {    
    const contractContext = useContext(ContractContext);
    const history = useHistory();

    const handleLogout = () => {
        const appSession = sessionStorage.getItem('appState');
        const sessionClientId = JSON.parse(appSession)?.clientId || 'aig';

        if (sessionClientId.toLowerCase() === 'et') {
            window.location.href = 'https://www.energytexas.com/myaccount';
        }
        else if (sessionClientId.toLowerCase() === 'apple' || sessionClientId.toLowerCase() === 'aig') {
            history.push('/vendor/apple');
        }
        else {
            history.push('/plansearch');
        }
    }
    
    const logout = (isTimeoutModalActive = false) => {
        contractContext.setLoggedIn(false);
        contractContext.updateContract({})
        contractContext.updateContracts([]);
        contractContext.setCoverageDocs(null);
        contractContext.updatePlanLookup({});
        contractContext.setHandshakeSuccessful(null);
        contractContext.updateHwaToken("");
        contractContext.updateParty({});
        contractContext.updateViaClientHandshake(false);
        contractContext.setLogin(false);
        clearSession();
        !isTimeoutModalActive && handleLogout(); //for timeout modal handle logout from component itself
    }
    
    return { logout, handleLogout }
}