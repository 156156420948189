import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import styles from './AccountVerification.module.css';
import OtpInput from 'react-otp-input';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendVerificationToken, verifyCode, getContractsByPartyId } from "../../helpers";
import { ContractContext } from "../../components/contexts/ContractContext";


const AccountVerification = (props) => {
    const context = useContext(ContractContext);
    const [verificationCode, setVerificationCode] = useState(JSON.parse(sessionStorage.getItem("verificationError"))?.code || '');
    const [validationError, setValidationError] = useState(JSON.parse(sessionStorage.getItem("verificationError"))?.message || '');
    const history = useHistory();
    const [emailAddress, setEmailAddress] = useState(history?.location?.state?.email || '');
    const [partyId, setPartyId] = useState(history?.location?.state?.partyId || 0);
    const [countryCode, setCountryCode] = useState(history?.location?.state?.countryCode || 'us');
    const [languageCode, setLanguageCode] = useState(history?.location?.state?.languageCode || 'en');
    const [clientId, setClientId] = useState(history?.location?.state?.clientId || 'aig');
    const [hasUser, setHasUser] = useState(history?.location?.state?.hasUser || false);
    const [forgotPassword, setForgotPassword] = useState(history?.location?.state?.forgotPassword || false);
    const [tokenTypeId, setTokenTypeId] = useState(history?.location?.state?.tokenTypeId || 0);

    useEffect(() => {
        return () => {
            sessionStorage.removeItem("verificationError")
        };
    }, []);

    const handleCodeVerification = async (passCode) => {
        setVerificationCode(passCode);
        if (passCode.length === 6) {
            var verified = await verifyCode(passCode, emailAddress, partyId, hasUser, clientId);

            if (verified) {
                setValidationError('');
                sessionStorage.setItem("verificationError", JSON.stringify({ message: '', code: '' }))
                if (hasUser && !forgotPassword) {
                    var contracts = await getContractsByPartyId(partyId, clientId);
                    let value = context;
                    value.updateContract(contracts[0]);
                    value.setLoggedIn(true);
                    value.updateParty(contracts[0].party);
                    value.updateContracts(contracts);
                    /* Group contracts by CCN to see which screen to show next */
                    const differentContracts = Object.groupBy(contracts, ({ clientContractNumber }) => clientContractNumber);
                    const numberOfContracts = Object.keys(differentContracts)?.length ?? 0;

                    if (numberOfContracts > 1) {
                        history.push('/accounts')
                    }
                    else {
                        history.push('/planoverview');
                    }
                }
                else {
                    if (forgotPassword) {
                        var contracts = await getContractsByPartyId(partyId, clientId);
                        let value = context;
                        value.updateContract(contracts[0]);
                        value.updateParty(contracts[0].party);
                        value.updateContracts(contracts);
                    }
                    history.push('/createnewpassword', { email: emailAddress, partyId: partyId, countryCode: countryCode, languageCode: languageCode, hasUser: hasUser, tokenTypeId: tokenTypeId, passCode: passCode, clientId: clientId });
                }
            }
            else {
                const verificationErrorObject = {
                    message: 'The verification code entered is invalid or has expired.',
                    code: passCode
                }
                sessionStorage.setItem("verificationError", JSON.stringify(verificationErrorObject))
                history.go(0)
            }
        }
    }

    useEffect(() => {
        if (emailAddress === '') {
            history.push('/plansearch');
        }
    }, [])

    const handleResendCode = async () => {
        setValidationError('');
        sessionStorage.setItem("verificationError", JSON.stringify({ message: '', code: '' }))
        setVerificationCode('');
        var sent = await sendVerificationToken(9, partyId, 6, countryCode, languageCode ?? "en", "VerifyItsYou", hasUser, emailAddress, clientId);
        if (!sent) {
            const verificationErrorObject = {
                message: 'An unexpected error happened, please try again later.',
                code: verificationCode
            }
            sessionStorage.setItem("verificationError", JSON.stringify(verificationErrorObject))
            history.go(0)
        }
    }

    return (
        <Container className={styles.mainContainer}>
            <div className={styles.verifyCodeBackground}>
                <Row>
                    <Col sm='12' md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }}>
                        <Card className={styles.verifyCodeCard}>
                            <h3 className='text-center mb-2'>
                                Please Check Your Inbox...
                            </h3>
                            <p className='text-center mb-1'>
                                We just sent your one-time passcode to {emailAddress}
                            </p>
                            {validationError && (
                                <p className={`errorMsg text-center mb-0`}>
                                    {validationError}
                                </p>
                            )}
                            <label htmlFor='enterCode' className={`form-label mt-4 mb-1 ${validationError && 'errorMsg'}`}>
                                Enter Code<span aria-hidden='true' className='required-indicator'>*</span>
                            </label>
                            <OtpInput
                                value={verificationCode}
                                onChange={handleCodeVerification}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={validationError && { color: '#ec1944', border: '1px solid #ec1944' }}
                            />
                            <a onClick={handleResendCode} className={`btnLink text-center ${styles.btnLink}`}>
                                Resend Code
                            </a>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default AccountVerification;