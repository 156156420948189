import React from 'react';
import { ReactComponent as ElectricalSystem } from '../../assets/images/Vendor/Constellation/electrical-system.svg';
import { ReactComponent as VehicleCharger } from '../../assets/images/Vendor/Constellation/vehicle-charger.svg';

export const assetInformation = {
    'constellation': {
        'Interior Electric Line': {
            icon: <ElectricalSystem />,
            description: `This plan helps cover repair costs on your home's interior electrical system components including breaker panels, fuse boxes and wiring up to a maximum annual limit of $2,000 for covered repairs due to defects in materials and workmanship or normal wear and tear.`,
            coveredInfo: [
                `Certain mechanical parts and components affecting the operations of the interior electrical system located within the perimeter of the home's outer load-bearing walls, up to the maximum annual limit of $2,000 minus any prior claims paid`,
                'Standard interior copper wiring',
                'Breaker panels/load centers',
                'Fuse boxes',
                'Fuses',
                'Circuit breakers',
                'Load management devices',
                'Connectors',
                'Boxes',
                'Standard wall outlets/receptacles (including ground fault circuit interrupters)',
                'Standard wall switches and dimmers'
            ],
            notCoveredInfo: [
                'Electric services that are rated more than 400 amps',
                'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
                'Losses that are subject to a manufacturer’s recall',
                'Losses caused by Your failure to follow or adhere to proper operation, care, or maintenance instructions',
                'Any pre-existing conditions or damages that occurred prior to the coverage start date',
                'Unauthorized modifications, alterations, or enhancements',
                'Non-copper wiring',
                'Any low-voltage system or systems dedicated to the transmission of data or signal through wiring, including wiring for speakers, garage doors, low-voltage lighting or fans, whole-home vacuums, cable television, phones, computers, and security systems',
                'Fixtures or appliances (with or without a plug) that are external to the Interior Electric, including external lighting not affixed to the side of the residence, animal fencing systems, ice melting systems, external security systems, and any external connections from your service address to a pool, HVAC unit, or other external appliance of fixture',
                'Software, remote, wireless, or internet enabled functions',
                'Extension cords, plugs, and fuses within plugs',
                'Utility meters and surge protectors', 
                'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents, and chips',
                'Cleaning, periodic checkups, and preventive maintenance',
                'Periphery or incidental costs arising from or relating to any needed installation, set-up, removal, or disposal services, including the costs of opening, removing, or restoring walls or floors, countertops, cabinets, or similar fixtures in or around Your Covered Product and the disposal of hazardous or toxic material',
                'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful, or reckless misconduct',
                'Incidental, consequential, or secondary damages, including loss of use, lost profits, and any damages arising from delays in requesting or rendering service, repairs, or reimbursement',
                'Damage caused by packing, unpacking, assembly, installation, or removal',
                'Damage caused by external factors  such as rust, corrosion, warping, bending, animals, animal inhabitation, or insect infestation',
                'Damage caused by improper removal or installation of replaceable components, modules, parts or peripheral devices, or installation of incorrect parts',
                'Any cost associated with making space suitable for installation including modifications to flooring, walls, or cabinetry',
                'Loss or damage caused by external causes of any kind, including war, invasion, rebellion, riot, strike, labor disturbance, lockout, civil commotion, fire, theft, vandalism, animals, exposure to weather, windstorm, sand, dirt, hail, earthquake, flood water, or acts of God',
                'Costs arising from or relating to the upgrade or modification of parts, components, or equipment due to incompatibility with existing systems, service, or utility lines at the service address or to meet changes in federal, state, or local codes or regulations',
                'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any computer system, electronic hardware, or software, or components thereof, that are used to store, process, access, transmit, or receive information within the product as result of any cause or loss other than covered losses that are expressly stated in this Plan, including any unauthorized access or unauthorized use of such system, a denial of service attack, or receipt or transmission of malicious code',
                'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any electronic data, including any such loss caused by unauthorized access or unauthorized use of such data, a denial of service attack, or receipt or transmission of malicious code',
            ]
        },

        'Electric Vehicle Charger': {
            icon: <VehicleCharger />,
            description: `This plan helps cover repair costs on your home's level 2 electric vehicle charger for covered repairs due to mechanical failure, a power surge or accidental damage from handling (ADH) up to a maximum annual limit of $2,000.`,
            coveredInfo: [
            'Certain mechanical parts and components affecting the operations of the level 2 electric vehicle charger due to mechanical failure, a power surge or accidental damage from handling (ADH) up to the maximum annual limit of $2,000 minus any prior claims paid',
            'Power relay',
            'Contactors',
            'Wire-to-board connectors',
            'Wire-to-wire connectors',
            'Passive components (power resistors)',
            'Electronic filters',
            'Switches',
            'Electrical internal wiring components (terminal blocks, distribution blocks, terminals and splices)',
            'Power surge',
            'Accidental damage from handling during normal usage, such as accidental drops or liquid spills or submersions',
            'Control boards',
            'Load control',
            'RFID cards',
            'Touchscreen user interfaces',
            
            ],
            notCoveredInfo: [
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Losses that are subject to a manufacturer’s recall',
            'Losses caused by Your failure to follow or adhere to proper operation, care, or maintenance instructions',
            'Any pre-existing conditions or damages that occurred prior to the coverage start date',
            'Unauthorized modifications, alterations, or enhancements',
            'Any low-voltage system or systems dedicated to the transmission of data or signal through wiring, including wiring for speakers, garage doors, low-voltage lighting or fans, whole-home vacuums, cable television, phones, computers, and security systems',
            'Software, remote, wireless, or internet enabled functions',
            'Extension cords, plugs, and fuses within plugs',
            'Utility meters and surge protectors',
            'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents, and chips',
            'Cleaning, periodic checkups, and preventive maintenance',
            'Periphery or incidental costs arising from or relating to any needed installation, set-up, removal, or disposal services, including the costs of opening, removing, or restoring walls or floors, countertops, cabinets, or similar fixtures in or around Your Covered Product and the disposal of hazardous or toxic material',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful, or reckless misconduct',
            'Incidental, consequential, or secondary damages, including loss of use, lost profits, and any damages arising from delays in requesting or rendering service, repairs, or reimbursement',
            'Damage caused by packing, unpacking, assembly, installation, or removal',
            'Damage caused by external factors other than covered ADH for electric vehicle chargers, such as rust, corrosion, warping, bending, animals, animal inhabitation, or insect infestation',
            'Damage caused by improper removal or installation of replaceable components, modules, parts or peripheral devices, or installation of incorrect parts',
            'Any cost associated with making space suitable for installation including modifications to flooring, walls, or cabinetry',
            'Loss or damage caused by external causes of any kind, other than covered ADH for electric vehicle chargers, including war, invasion, rebellion, riot, strike, labor disturbance, lockout, civil commotion, fire, theft, vandalism, animals, exposure to weather, windstorm, sand, dirt, hail, earthquake, flood water, or acts of God',
            'Costs arising from or relating to the upgrade or modification of parts, components, or equipment due to incompatibility with existing systems, service, or utility lines at the service address or to meet changes in federal, state, or local codes or regulations',
            'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any computer system, electronic hardware, or software, or components thereof, that are used to store, process, access, transmit, or receive information within the product as result of any cause or loss other than covered losses that are expressly stated in this Plan, including any unauthorized access or unauthorized use of such system, a denial of service attack, or receipt or transmission of malicious code',
            'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any electronic data, including any such loss caused by unauthorized access or unauthorized use of such data, a denial of service attack, or receipt or transmission of malicious code'          
            ]
        }
    }
}