import React, { useState, useRef } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";
import StandardInput from "../shared/StandardInput/StandardInput";
import styles from "./ForgotPlanNumber.module.css";
import { useHistory } from 'react-router-dom';


const ForgotPlanNumber = () => {
    const [email, setEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [validationFired, setValidationFired] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [, forceUpdate] = useState();
    const history = useHistory();
    const [countryCode, setCountryCode] = useState(history?.location?.state?.countryCode || 'us');
    const [languageCode, setLanguageCode] = useState(history?.location?.state?.languageCode || 'en');

    const validator = useRef(
        new SimpleReactValidator({
            messages: {
                email: "Please enter a valid email.",
                required: ":attribute is required."
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        })
    );

    const handleInputChange = (e) => {
        e.preventDefault();
        let { value } = e.target;
        setEmail(value);
        setErrorMsg("");
    };

    const handleContinue = async (e) => {
        e.preventDefault();
        setValidationFired(true);

        if (validator.current.allValid()) {
            setIsSearching(true);
            setValidationFired(false);

            if (email == null) {
                setErrorMsg("The email entered does not match our records.");
            } 
            else {
                var appSession = sessionStorage?.getItem('appState');
                var clientId = JSON.parse(appSession)?.clientId;

                const response = await fetch(`api/contract/FindContractsByEmailAndClient`, {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        clientId: clientId,
                        emailAddress: email,
                        countryCode: countryCode,
                        langCode: languageCode,
                        sendEmail: true
                    })
                });

                var result = await response.json();
                
                if (result.length > 0 && response.status === 200) {
                    history.push('/plansearch');
                    // Commenting out based on LeAnna's comments on Teams
                    // history.push('/accountverification', { email: email, partyId: result });
                    setIsSearching(false);
                }
                else  {
                    setErrorMsg("The email entered does not match our records. ");
                    setIsSearching(false);
                }
            }
            

        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <Container className={styles.mainContainer}>
            <div className={styles.forgotPlanBackground}>
                <Row>
                    <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }}>
                        <Card className={styles.forgotPlanCard}>
                            <h3 className="text-center mb-2 font-weight-bold">Forgot Plan Number</h3>
                            <p className="text-center mb-4">Please enter the email address associated with  your plan. We will send the plan number to this email.</p>
                            {errorMsg && (
                                <span className="validation-summary text-center">{errorMsg}</span>
                            )}
                            <StandardInput
                                label="Email"
                                hasError={validationFired && validator.current.message("email", email, "required|email") && "form-error"}
                                fieldName="email"
                                ariaLabelAndFieldId="email"
                                maxLength={320}
                                value={email}
                                onChange={handleInputChange}
                                validator={validator.current.message("email", email, "email|required")}
                            />
                            <SpinnerButton
                                type="primary"
                                onClick={handleContinue}
                                text="Send Email"
                                spinning={isSearching}
                                ariaLabel="Click to send email address"
                            />
                            <a href="/plansearch" className="btnLink mt-4 text-center">
                                Back to Plan Lookup
                            </a>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default ForgotPlanNumber;