import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Card, FormGroup, Label } from "reactstrap";
import StandardInput from "../shared/StandardInput/StandardInput";
import CheckboxInput from "../shared/CheckboxInput/CheckboxInput";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";
import styles from "./PlanLookup.module.css";
import SimpleReactValidator from "simple-react-validator";
import { useHistory, Link } from "react-router-dom";
import Cookies from "js-cookie";
import getClientContent from '../_BrandingProvider/brandContent';
import CallLink from "../shared/PhoneNumberUtility/CallLink";
import classnames from "classnames";

const NOT_FOUND = "not found";
const ERROR_204 = "204 error";
const ERROR_404 = "404 error";
const ERROR_500 = "500 error";
const SERVICE_ERROR = "error";

const LoginPage = ({ loginFormHandler, context, languageContext, clientId }) => {
    const initialEmailValue = Cookies.get("email");
    const initialRememberMeValue = Cookies.get("rememberMe");
    const customerServicePhone = getClientContent(clientId)?.variableText?.customerService?.phone;
    const planName = getClientContent(clientId)?.variableText?.planName;
    const [planDetails, setPlanDetails] = useState({
        email: initialEmailValue || "",
        password: "",
        rememberMe: initialRememberMeValue || false,
    });
    const [validationFired, setValidationFired] = useState(false);
    const [statusMsg, setStatusMsg] = useState("");
    const [attemptCounter, setAttemptCounter] = useState(0);
    const [maxFailedLoginAttempts, setMaxFailedLoginAttempts] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [, forceUpdate] = useState();
    const history = useHistory();

    useEffect(() => {
      if (attemptCounter > 3) {
        setMaxFailedLoginAttempts(true);
      }
    }, [attemptCounter]);

    const validator = useRef(
        new SimpleReactValidator({
            messages: {
                email: "Please enter a valid email address.",
                required: ":attribute is required.",
            },
            element: (message) => (
                <div className="errorMsg" role="alert">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
        })
    );

    const updateRememberMeCookies = () => {
        if (planDetails.rememberMe) {
            Cookies.set("email", planDetails.email, { expires: 365 });
            Cookies.set("rememberMe", true, { expires: 365 });
        } else {
            Cookies.remove("email");
            Cookies.remove("rememberMe");
        }
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "rememberMe") {
            setPlanDetails(
                {
                    ...planDetails,
                    rememberMe: !planDetails.rememberMe,
                },
                updateRememberMeCookies()
            );
            const { password, ...restData } = planDetails;
            context.setPlanLogin({
                ...restData
            });
        } else {
            setPlanDetails({
                ...planDetails,
                [name]: value,
            });
            const { password, ...restData } = planDetails;
            context.setPlanLogin({
                ...restData
            });
        }
        setStatusMsg("");
    };

    const handleLookUpPage = () => {
        loginFormHandler(false);
    };

    const handleContinue = (e) => {
        e.preventDefault();
        setIsSearching(true);
        setValidationFired(true);
        if (validator.current.allValid()) {
            setValidationFired(false);

            var clientId = context.data.clientId

            const reqObj = {
                UserName: planDetails.email,
                Password: planDetails.password,
                RememberMe: planDetails.rememberMe,
                Client: clientId,
            };
            fetch(`api/identity/login`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(reqObj),
            })
                .then((res) => {
                    if (res.ok) {
                        if (res.status === 204) {
                            return setStatusMsg(ERROR_204);
                        }
                        if (res.status === 404) {
                            setAttemptCounter((prevState) => prevState + 1);
                            return setStatusMsg(ERROR_404);
                        }
                        res.json().then((data) => {
                            if (!data.succeeded) {
                                setAttemptCounter((prevState) => prevState + 1);
                                setIsSearching(false);
                                return setStatusMsg(NOT_FOUND);
                            } else {
                                const languageCode = languageContext?.language ?? "en";
                                const countryCode = languageContext?.country ?? "us";
                                const tokenTypeId = 6;
                                history.push({
                                    pathname: "/accountverification",
                                    state: {
                                        email: planDetails.email,
                                        partyId: data?.partyId,
                                        countryCode: countryCode,
                                        languageCode: languageCode,
                                        hasUser: true,
                                        tokenTypeId: tokenTypeId,
                                        clientId: clientId,
                                        forgotPassword: false
                                    }
                                });
                            }
                        });
                    } else {
                        if (res.status === 204) {
                            setIsSearching(false);
                            return setStatusMsg(ERROR_204);
                        }
                        else if (res.status === 404) {
                            setAttemptCounter((prevState) => prevState + 1);
                            setIsSearching(false);
                            return setStatusMsg(ERROR_404);
                        } 
                        else {
                            setIsSearching(false);
                            return setStatusMsg(SERVICE_ERROR);
                        }
                    }
                })
                .catch((err) => {
                    setIsSearching(false);
                    return setStatusMsg(ERROR_500);
                });
        } else {
            validator.current.showMessages();
            forceUpdate(1);
            setIsSearching(false);
        }
    };

    const ValidationMessages = () => {
        if (maxFailedLoginAttempts) {
            return (
                <span className="validation-summary text-center">
                    Trouble logging in? <a className="link" href="/forgotpassword">Reset Your Password</a>
                </span>
            )
        }
        
        if (statusMsg === NOT_FOUND) {
            return (
                <span className="validation-summary text-center">
                    An error occurred. Please enter the correct information.
                </span>
            );
        }
        
        if (
            statusMsg === ERROR_204 ||
            statusMsg === SERVICE_ERROR ||
            statusMsg === ERROR_500
        ) {
            return (
                <span className="validation-summary text-center">
                    Something went wrong, please try again later.
                </span>
            );
        }
        
        if (statusMsg === ERROR_404) {
            return (
                <span className="validation-summary text-center">
                    The login you have entered does not match our records.
                </span>
            );
        }


        return null;
    };

    const clientUpper = clientId?.toUpperCase();

    return (
        <Card className={classnames(styles.fileClaimCard, styles[clientUpper])}>
            <h3 className="text-center mb-2">Login to My Account</h3>
            <p className="text-center mb-4">
                {`Manage your ${planName?.toLowerCase() + 's'}, file or track a claim.`}
            </p>
            <ValidationMessages />
            <StandardInput
                label="Email"
                hasError={
                    validationFired &&
                    validator.current.message(
                        "email",
                        planDetails.email,
                        "required|email"
                    ) &&
                    "form-error"
                }
                fieldName="email"
                ariaLabelAndFieldId="email"
                maxLength={320}
                value={planDetails.email}
                onChange={handleInputChange}
                validator={validator.current.message(
                    "email",
                    planDetails.email,
                    "email|required"
                )}
            />
            <StandardInput
                label="Password"
                htmlInputType="password"
                hasError={
                    validationFired &&
                    validator.current.message(
                        "password",
                        planDetails.password,
                        "required"
                    ) &&
                    "form-error"
                }
                fieldName="password"
                ariaLabelAndFieldId="password"
                value={planDetails.password}
                onChange={handleInputChange}
                validator={validator.current.message(
                    "password",
                    planDetails.password,
                    "required"
                )}
            />
            <div className="checkbox d-flex flex align-items-center justify-content-between">
                <CheckboxInput
                    id="rememberMe"
                    name="rememberMe"
                    customClass={styles.checkboxInput}
                    checked={planDetails?.rememberMe}
                    handleCheck={handleInputChange}
                >
                    Remember Me
                </CheckboxInput>
                <div>
                    <Link to="/forgotpassword" className="btnLink text-center">
                        Forgot Password?
                    </Link>
                </div>
            </div>

            <SpinnerButton
                type="primary"
                onClick={handleContinue}
                text="Login"
                spinning={isSearching}
                disabled={isSearching}
            />
            <div className="d-flex flex mt-4 align-items-center justify-content-center">
                <span className="text-center mr-2 mb-5">Don't have an account?</span>
                <button className="btnLink m-0 mb-5 text-center" onClick={handleLookUpPage}>
                    Enroll Now
                </button>
            </div>
            <div className={styles.divider}></div>

            <SpinnerButton
                type="secondary"
                onClick={handleLookUpPage}
                text="Request Service as Guest"
            />
        </Card>
    );
};

export default LoginPage;