import React, { useEffect } from 'react';
import { Container, Card, CardBody, CardHeader, Row } from 'reactstrap';
import { ApptContextProvider } from './AppointmentContext';
import { DisplayController } from '.';
import StepsHeader from '../StepsHeader';


/**
 * This component simply instantiates the ContextProvider and the card that will
 * house the module's component tree. Nothing required here.
 */

export const Wrapper = (props) => {

    const appointmentProps = props.location?.appointmentProps ? props.location?.appointmentProps : props.location?.state;
    const { history } = props;
    const clientId = history?.location?.clientId ?? appointmentProps?.clientId;

    return (
        <StepsHeader active={3}>
            <ApptContextProvider>
                <DisplayController {...appointmentProps} history={history} clientId={clientId} {...props} />
            </ApptContextProvider>
        </StepsHeader>
    )
}