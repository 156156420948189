import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, FormGroup, Label } from "reactstrap";
import { ContractContext } from "../../components/contexts/ContractContext";
import StandardInput from "../shared/StandardInput/StandardInput";
import Cleave from "cleave.js/react";
import { DropDownInput } from "../../components/inputs/DropDownInput";
import PhoneNumberInput from "../shared/PhoneNumberUtility/PhoneNumberInput";
import SpinnerButton from "../../components/shared/SpinnerButton/SpinnerButton";
import styles from "./PlanLookup.module.css";
import LoginPage from "./LoginPage";
import SimpleReactValidator from "simple-react-validator";
import { useMediaQuery } from "react-responsive";
import { sendVerificationToken } from "../../helpers";
import { LanguageContext } from "../../components/contexts/LanguageTranslation/LanguageContext";
import getClientContent from "../_BrandingProvider/brandContent";
import CallLink from "../shared/PhoneNumberUtility/CallLink";
import classnames from "classnames";

const UNAUTHORIZED = "found";
const NOT_FOUND = "not found";
const ERROR_204 = "204 error";
const ERROR_404 = "404 error";
const ERROR_500 = "500 error";
const SERVICE_ERROR = "error";

const PlanLookup = () => {
    const context = useContext(ContractContext);
    const languageContext = useContext(LanguageContext)

    const [planDetails, setPlanDetails] = useState({
        servicePlanNumber: context?.data?.planlookup.servicePlanNumber || "",
        zip: context?.data?.planlookup.zip || "",
        selectedSearchOption: context?.data?.planlookup.selectedSearchOption || "",
        email: context?.data?.planlookup.email || "",
        phone: context?.data?.planlookup.phone || ""
    });
    const [validationFired, setValidationFired] = useState(false);
    const [statusMsg, setStatusMsg] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [loginForm, setLoginForm] = useState(true);
    const [, forceUpdate] = useState();
    const clientId = context?.data?.clientId || context?.data?.contract?.clientId || "aig";
    const customerServicePhone = getClientContent(clientId)?.variableText?.customerService?.phone;
    const planName = getClientContent(clientId)?.variableText?.planName;
    const history = useHistory();

    const validator = useRef(
        new SimpleReactValidator({
            messages: {
                phone: "Please enter a valid phone number.",
                email: "Please enter a valid email address.",
                required: ":attribute is required."
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        })
    );

    const searchOptions = [
        { data: "email", display: "Email" },
        { data: "phone", display: "Phone" }
    ];

    const handleLoginNavigation = () => {
        setLoginForm(true);
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        value = name === "phone" ? e.target.rawValue : value;
        setPlanDetails({
            ...planDetails,
            [name]: value,
        });
        context.updatePlanLookup({
            ...planDetails,
            [name]: value,
        });
        setStatusMsg("");
    };

    const handleForgotPlan = () => {
        const languageCode = languageContext?.language ?? "en";
        const countryCode = languageContext?.country ?? "us";
        history.push('/forgotplan', { languageCode: languageCode, countryCode: countryCode })
    };

    const handleContinue = async (e) => {
        e.preventDefault();
        setIsSearching(true);
        setValidationFired(true);
        if (validator.current.allValid()) {
            setValidationFired(false);

            const reqObj = {
                "Email": planDetails.email,
                "ContractNumber": planDetails.servicePlanNumber,
                "Phone": planDetails.phone,
                "Zip": planDetails.zip,
                "SearchOption": planDetails.selectedSearchOption
            }
            fetch(`api/plansearch/contract/${context.data.clientId}`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(reqObj)
            }).then((res) => {
                if (res.ok && res.status === 200) {
                    res.json().then(async data => {
                        if (!data.length) {
                            setIsSearching(false);
                            setStatusMsg(ERROR_204); // No contracts found
                        }
                        else {
                            if (data.length == 1) {
                                let value = context;
                                value.updateContract(data[0]);
                                value.updateParty(data[0].party);
                                value.updateContracts(data);  // the inital plan overview page loaded with the context records we just added the logic what we did in plan search submission
                                if (value.data.hasLogin === true) {
                                    let email = data[0].party?.partyEmailModel[0]?.address;
                                    const languageCode = languageContext?.language ?? "en";
                                    const countryCode = languageContext?.country ?? "us"
                                    const clientId = context.data.clientId;
                                    const partyId = data[0].party?.partyId;
                                    const warrantyTypeId = 9;
                                    const tokenTypeId = 6;
                                    const hasUser = false;
                                    const forgotPassword = false;
                                    var sent = await sendVerificationToken(warrantyTypeId, partyId, tokenTypeId, countryCode, languageCode, "VerifyItsYou", hasUser, email, clientId);
                                    if (sent) {
                                        history.push('/accountverification', { email: email, partyId: partyId, countryCode: countryCode, languageCode: languageCode, hasUser: hasUser, tokenTypeId: tokenTypeId, clientId: clientId, forgotPassword: forgotPassword });
                                    }
                                    else {
                                        setIsSearching(false);
                                        setStatusMsg(SERVICE_ERROR); // send generic error if we could not send the email
                                    }
                                }
                                else {
                                    let value = context;
                                    value.setLoggedIn(true);
                                    history.push('/planoverview');
                                }
                            }
                            else {
                                setIsSearching(false);
                                setStatusMsg(NOT_FOUND); // TODO: needs to be defined
                            }
                        }
                    })
                }
                else if (res.status === 204) {
                    setIsSearching(false);
                    return setStatusMsg(ERROR_204); // No contracts found
                }
                else if (res.status === 404) {
                    setIsSearching(false);
                    return setStatusMsg(ERROR_404); // Endpoint not found
                }
                else if (res.status === 401) {
                    setIsSearching(false);
                    return setStatusMsg(UNAUTHORIZED); // User already exists
                }
                else if (res.status === 500) // Server error
                {
                    setIsSearching(false)
                    setStatusMsg(ERROR_500);
                }
                else {
                    setIsSearching(false);
                    setStatusMsg(SERVICE_ERROR); // Unhandled
                }
            }).catch(err => {
                setIsSearching(false)
                setStatusMsg(ERROR_500); // Server error
            })
        } else {
            validator.current.showMessages();
            forceUpdate(1);
            setIsSearching(false);
        }
    }
    const handleSearchBy = (opt) => {
        setPlanDetails({
            ...planDetails,
            selectedSearchOption: opt
        });
        context.updatePlanLookup({
            ...planDetails,
            selectedSearchOption: opt
        });
        setStatusMsg("");
    }
    const isMobile = useMediaQuery({ maxWidth: "767px" });

    const ValidationMessages = () => {
        if (statusMsg === ERROR_204) {
            return <p className="validation-summary text-center">Sorry, we couldn't find your plan. Please try again or call us at <CallLink phone={customerServicePhone}/>.</p>
        }

        if (statusMsg === UNAUTHORIZED) {
            return <p className="validation-summary text-center">
                We found an existing account for this plan number. Please <a className="link" href="/plansearch" target="_blank">login</a> or <a className="link" href="/forgotpassword" target="_blank">reset your password</a>.
            </p>
        }
        
        if (statusMsg === ERROR_404 || statusMsg === ERROR_500) {
            return <p className="validation-summary text-center">Something went wrong, please try again later.</p>
        }

        if (statusMsg === SERVICE_ERROR) {
            return <p className="validation-summary text-center">An error occurred. Please enter the correct information.</p>
        }
        return null;
    }

    const clientUpper = clientId?.toUpperCase();

    return (
        <>
            <Container fluid={clientId?.toLowerCase() === "cutler" ? false : true} className={styles.mainContainer}>
                <div className={clientId?.toLowerCase() === "cutler" ? styles.landingBackground : styles.constellationHero}>
                    <Container>
                        <Row>
                            <Col
                                sm="12"
                                md={{ size: 8, offset: 2 }}
                                lg={{ size: 6, offset: clientId?.toLowerCase() === "cutler" ? 1 : 6 }}
                                xl={{ size: 6, offset: clientId?.toLowerCase() === "cutler" ? 1 : 7 }}
                            >
                                {!loginForm ?
                                    (
                                        <Card className={classnames(styles.fileClaimCard, styles[clientUpper])}>
                                            <h3 className="text-center mb-2 font-weight-bold">File or Track a Claim</h3>
                                            <p className="text-center mb-4">Let's start by finding your plan.</p>
                                            <ValidationMessages />
                                            <Row>
                                                <Col md="7" className={!isMobile ? "pr-2" : ""}>
                                                    <StandardInput
                                                        label={planName + " Number"}
                                                        hasError={!validator.current.check(planDetails.servicePlanNumber, "required|alpha_num") && validationFired}
                                                        fieldName="servicePlanNumber"
                                                        ariaLabelAndFieldId="servicePlanNumber"
                                                        value={planDetails.servicePlanNumber}
                                                        onChange={handleInputChange}
                                                        validator={validator.current.message("servicePlanNumber", planDetails.servicePlanNumber, "required|alpha_num", {
                                                            messages: {
                                                                alpha_num: `Please enter a valid ${planName?.toLowerCase()} number.`,
                                                                required: `${planName} number is required.`
                                                            }
                                                        })}
                                                    />
                                                </Col>
                                                <Col md="5" className={!isMobile ? "pl-2" : ""}>
                                                    <FormGroup className={validationFired && !validator.current.check(planDetails.zip, "required|numeric|size:5") && "form-error"}>
                                                        <Label for="zipCode" className="form-label">Zip/Postal</Label>
                                                        <span aria-hidden="true" className="required-indicator">*</span>
                                                        <Cleave
                                                            options={{
                                                                blocks: [5],
                                                                numericOnly: true
                                                            }}
                                                            className="form-control"
                                                            id="zipCode"
                                                            name="zip"
                                                            onChange={handleInputChange}
                                                            value={planDetails.zip}
                                                        />
                                                        {validator.current.message("zip", planDetails.zip, "required|numeric|size:5", {
                                                            messages: {
                                                                required: "Zip/Postal is required.",
                                                                size: "Please enter a valid zip/postal code."
                                                            }
                                                        })}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup className={(validationFired && planDetails.selectedSearchOption === "") ? "form-error" : ""}>
                                                <Label for="searchBy" className="form-label">Search By</Label>
                                                <span aria-hidden="true" className="required-indicator">*</span>
                                                <DropDownInput
                                                    name="searchBy"
                                                    options={searchOptions}
                                                    className="form-control"
                                                    onChangedValue={handleSearchBy}
                                                    placeholder="Please Select..."
                                                    defaultValue={planDetails.selectedSearchOption}
                                                    validator={validator.current.message("searchBy", planDetails.selectedSearchOption, "required", {
                                                        messages: {
                                                            required: "Please select an option."
                                                        }
                                                    })}
                                                />
                                            </FormGroup>
                                            {planDetails.selectedSearchOption === "phone" && (
                                                <PhoneNumberInput
                                                    fieldName="phone"
                                                    label={"Phone"}
                                                    value={planDetails.phone}
                                                    onChange={handleInputChange}
                                                    validationFired={validationFired}
                                                    error={validator.current.message("phone", planDetails.phone, "required|phone")}
                                                />
                                            )}
                                            {planDetails.selectedSearchOption === "email" && (
                                                <StandardInput
                                                    label="Email"
                                                    hasError={validationFired && validator.current.message("email", planDetails.email, "required|email") && "form-error"}
                                                    fieldName="email"
                                                    ariaLabelAndFieldId="email"
                                                    maxLength={320}
                                                    value={planDetails.email}
                                                    onChange={handleInputChange}
                                                    validator={validator.current.message("email", planDetails.email, "email|required")}
                                                />
                                            )}
                                            <SpinnerButton
                                                type="primary"
                                                onClick={handleContinue}
                                                text="Get Started"
                                                spinning={isSearching}
                                                disabled={isSearching}
                                                ariaLabel="Click get started button to file or track your claim"
                                            />
                                            <a onClick={handleForgotPlan} className="btnLink mt-4 mb-5 text-center">
                                                Forgot plan number?
                                            </a>
                                            <div className={styles.divider}></div>
                                            <div className="text-center mb-2">Already have an account?</div>
                                            <SpinnerButton
                                                type="secondary"
                                                onClick={() => {
                                                    handleLoginNavigation();
                                                }}
                                                text="Login"
                                                ariaLabel="Click login to file or track your claim"
                                            />
                                        </Card>
                                    ) : (
                                        <LoginPage
                                            loginFormHandler={setLoginForm}
                                            context={context}
                                            languageContext={languageContext}
                                            clientId={clientId}
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
            {clientId?.toLowerCase() === "constellation" && (
                <div className={styles.constellationSwoosh}></div>
            )}
        </>
    )
}

export { PlanLookup };