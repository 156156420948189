import React from 'react';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import NeedHelp from '../../../components/modals/NeedHelp/NeedHelp';
import styles from './CutlerFooter.module.css';

export const CutlerFooter = () => {
    return (
        <>
            <Container className={classNames(styles.cutlerFooterText, "px-3")}>
                <div className={styles.footerTextArea}>
                    Cutler Home Service Plans are sold by Cutler Real Estate. Plans are issued and administered by AIG WarrantyGuard, Inc., parts of AIG, or one or more of its affiliates. 
                    Plans are subject to detailed terms, conditions, and limitations. For further information, please review the full plan <a href="#" onClick={(e) => { e.preventDefault(); window.open('/coveragedocs', '_blank') }}>terms and conditions</a>.
                    Cutler Real Estate is not affiliated with AIG or any of its affiliates. Trademarks used with permission.
                </div>
            </Container>
            <footer className={styles.cutlerFooter}>
                <Container className={classNames(styles.cutlerFooterPadding, "px-0")}>
                    <ul className={styles.navItem}>
                        <li><a href="https://www.aig.com/privacy-policy" target="_blank"> Privacy Policy</a></li>
                        <li><a href="https://www.aig.com/terms-of-use" target="_blank"> Terms of Use</a></li>
                    </ul>
                    <p>Copyright &#169; {(new Date().getFullYear())}, American International Group, Inc. All rights reserved.</p>
                </Container>
                <NeedHelp />
            </footer>
        </>
    )
}