import React from 'react';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import NeedHelp from '../../../components/modals/NeedHelp/NeedHelp';
import styles from './ConstellationFooter.module.css';

const ConstellationFooter = () => {
    return (
        <>
            <Container className={classNames(styles.footerText, "px-3")}>
                <div className={styles.footerTextArea}>
                    <p>
                        Protection Plans are offered and sold by Constellation Home Products &amp; Services, LLC (Constellation) and issued by AIG WarrantyGuard, Inc., part of AIG, or one
                        or more of its affiliates. Limitations and exclusions apply. Detailed terms and conditions for protection plans are available at
                        <a href="#" className="ml-1 mr-1" onClick={(e) => { e.preventDefault(); window.open('/coveragedocs', '_blank') }}>terms and conditions</a>
                        or by mail upon request. AIG is the marketing name for the worldwide property-casualty and general insurance operations of American
                        International Group, Inc. Constellation is not affiliated with AIG or any of its affiliates.
                    </p>
                    <p>All trademarks are owned by their respective companies. Constellation trademarks are used with permission.</p>
                </div>
            </Container>
            <footer className={styles.constellationFooter}>
                <Container className={classNames(styles.footerPadding, "px-0")}>
                    <ul className={styles.navItem}>
                        <li><a href="https://www.aig.com/privacy-policy" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://www.aig.com/terms-of-use" target="_blank">Terms of Use</a></li>
                    </ul>
                    <p>Copyright &#169; {(new Date().getFullYear())}, American International Group, Inc. All rights reserved.</p>
                </Container>
                <NeedHelp />
            </footer>
        </>
    )
}

export default ConstellationFooter;