import React from 'react';
import { Card, CardHeader, CardBody, Col, Row, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import AIGStatusBadge from '../../shared/StatusBadge/StatusBadge';
import styles from './MyPlans.module.css'
import classNames from 'classnames';
import moment from 'moment';
import { ReactComponent as ACSystemIcon } from '../../../assets/images/air-conditioner.svg';
import { ReactComponent as ElectricalSystem } from '../../../assets/images/electrical-system.svg';
import { ReactComponent as SurgeProtectionIcon } from '../../../assets/images/Vendor/ET/electric-surge.svg';
import { ReactComponent as VehicleChargerIcon } from '../../../assets/images/Vendor/ET/vehicle-charger.svg';
import { useEffect, useState } from 'react';
import { ReactComponent as ToggleIcon } from '../../../assets/images/toggle.svg';

export const PlanCard = ({ contract, ...props }) => {
    const [contractDetails, setContractDetails] = useState({});
    
    useEffect(() => {
        getContractDetails();
    }, [])

    const paymentOptionLabel = (id) => {
        const contractTerms = props?.contractTerms?.find((x) => x.termId == id);
        const label = contractTerms
            ? contractTerms?.termName?.trim().includes("One Month")
                ? "Monthly"
                : contractTerms?.termName?.trim()
            : "";
        return label;
    };

    const getContractDetails = () => {
        const asset = contract.assets[0] ?? null;
        let summary = null;

        if (asset) {
            const contractAsset = contract.contractAssetModel.find(ca => ca.assetId == asset.assetId)
            const psDate = moment(contractAsset?.contractAssetCoverageModel[0]?.startDate).format('MM/DD/YYYY');
            const waitPeriod = contract.riskProfile?.waitPeriod ?? 0;
            const wpEndsMoment = moment(psDate, 'MM/DD/YYYY').add(waitPeriod, 'days');
            const wpEnds = waitPeriod > 0 ? moment(psDate, 'MM/DD/YYYY').add(waitPeriod, 'days').format('MM/DD/YYYY') : "";
            const calcStatusId = contract.statusId == 1 && wpEndsMoment.isAfter(moment().format()) ? 2 : contract.statusId;

            summary = {
                assetId: asset.assetId,
                assetName: asset.assetName,
                assetTypeId: asset.assetTypeId,
                assetIndustryId: asset.assetIndustryId,
                assetDisplayCategory: asset.assetDisplayCategory,
                removePurchaseDate: asset.removePurchaseDate,
                planStartDate: psDate,
                contractNumber: contract.contractNumber,
                paymentOption: paymentOptionLabel(contractAsset?.offer?.riskProfileModel?.termId ?? 0),
                contractStatus: contract.statusModel.statusName ?? "",
                statusId: calcStatusId,
                contractAssetId: contractAsset?.contractAssetId,
                waitPeriodEnds: wpEnds,
                purchaseDate: contract.purchaseDate,
                riskProfileId: contractAsset?.offer?.riskProfileId,
                contract: contract
            }
        }

        setContractDetails(summary);
    }

    const toggleDropdown = () => {
        return (
            <UncontrolledDropdown direction="right" position="top">
                <DropdownToggle tag="span" className={styles.toggleContainer}>
                    <ToggleIcon className={styles.toggleIcon} alt="toggle dropdown"/>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => contractDetails ? props?.handleSelectedActionClick("ViewPlanDetails", contractDetails) : ""}>View Plan Details</DropdownItem>
                    <DropdownItem onClick={() => props?.handleSelectedActionClick("ReprintContract")}>Reprint Contract</DropdownItem>
                    <DropdownItem onClick={() => contractDetails ? props?.handleSelectedActionClick("TermsAndConditions", contractDetails) : ""}>Terms and Conditions</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    return (
        <Card className={classNames(styles.planCard, "shadow", "mb-4")}>
            <CardHeader className={styles.cardHeader}>
                <Row>
                    <Col xs="12" md="10" lg="11">
                        <h6 className="mb-1">{contractDetails?.assetName}
                            <span className="d-sm-none pl-2"><AIGStatusBadge statusId={contractDetails?.statusId} statusType='contract' /></span>
                        </h6>
                        <div className="d-none d-sm-block">
                            <AIGStatusBadge statusId={contractDetails?.statusId} statusType='contract' />
                        </div>
                    </Col>
                    {/* DESKTOP/TABLET VIEW: hide for small devices */}
                    <Col md="2" lg="1" className="d-none d-sm-block">
                        {toggleDropdown()}
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className={styles.cardBody}>
                <Row>
                    <Col xs="6" md="8" lg="8">
                        <ul className={classNames(styles.listDetails, "noBullets")}>
                            <li>Plan Start: <strong>{moment(contractDetails?.planStartDate).format("MM/DD/YYYY")}</strong></li>
                            <li>Contract: #
                                <a
                                    href="javascript:void(0);"
                                    onClick={() => props?.handleSelectedActionClick("ViewPlanDetails", contractDetails)}
                                    className="btnLink"
                                >
                                    {contractDetails?.contractNumber}
                                </a>
                            </li>
                            <li>{contract?.riskProfile?.termModel?.recurring ? "Payment" : "Term"}: <strong>{contractDetails?.paymentOption}</strong></li>
                        </ul>
                    </Col>
                    <Col xs="5" md="4" lg="4">
                        {contractDetails?.assetName?.replace(/\s/g, '').toLowerCase() === "a/csystem" && <ACSystemIcon className={styles.assetIcon} />}
                        {contractDetails?.assetName?.replace(/\s/g, '').toLowerCase() === "electricvehiclecharger" && <VehicleChargerIcon className={styles.assetIcon} />}
                        {contractDetails?.assetName?.replace(/\s/g, '').toLowerCase() === "electricalsystems" && !contractDetails?.assetName.includes("Vehicle") && <ElectricalSystem className={styles.assetIcon} />}
                        {(contractDetails?.assetName?.replace(/\s/g, '').toLowerCase() === "surge" || contractDetails?.assetName?.replace(/\s/g, '').toLowerCase() === "surgeprotection" || contractDetails?.assetName?.replace(/\s/g, '').toLowerCase() === "dwelling") &&
                            <SurgeProtectionIcon className={styles.assetIcon} />
                        }
                    </Col>
                    {/* MOBILE VIEW: hide for large devices */}
                    <Col xs="1" className="d-sm-none pt-3 pl-0 pr-0">
                        {toggleDropdown()}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}