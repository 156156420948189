import React, { useContext } from 'react';
import { ContractContext } from '../contexts/ContractContext';
import CoverageDocuments from './CoverageDocuments';

export const CoverageDocsController = () => {
    const context = useContext(ContractContext);
    const coveragePlanType = context.data?.coveragePlanType ?? 'HomeWarranty';

    switch (coveragePlanType?.toLowerCase()) {
        case "account":
        case "homewarranty":
        default:
            return <CoverageDocuments />
    }
}