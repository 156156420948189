import React, { useContext, useEffect, useState } from 'react';
import { Route, withRouter, Switch } from 'react-router';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { PlanSearch } from './screens/PlanSearch';
import { Entitlement } from './components/FileClaim/Entitlement';
import { Payment } from './components/FileClaim/Payment';
import { VerifyInfo } from './components/FileClaim/VerifyInfo';
import { Reimbursement } from './components/FileClaim/Reimbursement';
import { PlanOverview } from './screens/PlanOverview/PlanOverview';
//import PlanOverview from './components/screens/CustomerPlanOverview';
import { ContractContext } from './components/contexts/ContractContext';
import { ContractsOverview } from './screens/PlanOverview/ContractsOverview';
import { Confirmation } from './components/FileClaim/Confirmation';
import Landing from './screens/Vendor/Apple/Landing';
import { ClaimOverview } from './screens/Vendor/Apple/ClaimOverview';
import { DetailsOverview } from './screens/PlanOverview/DetailsOverview';
import AssetCoverageOverview from './components/PlanOverview/Asset/AssetCoverageOverview';
import ClaimDocuments from './screens/Vendor/Apple/ClaimDocuments';
import ClaimReview from './screens/Vendor/Apple/ClaimReview';
import ClaimScripting from './screens/Vendor/Apple/ClaimScripting';
import EntitlementContainer from './screens/Vendor/Apple/EntitlementContainer';
import VerifyInfoContainer from './screens/Vendor/Apple/VerifyInfoContainer';
import PaymentContainer from './screens/Vendor/Apple/PaymentContainer';
import { ConfirmationLT } from './components/Vendor/Apple/IntakeLoss/ConfirmationLT';
import ErrorMessages from './components/Vendor/Apple/IntakeLoss/ErrorMessages';
import '../src/css/autodropdown.css'
import history from './components/services/history';
import { UploadDocsContainer } from './screens/Vendor/Apple/UploadDocsContainer';
import Faqs from './screens/Vendor/Apple/Faqs';
import { Helmet } from 'react-helmet'
import HWAFavicon from './assets/images/Vendor/HWA/favicon.ico';
import AIGFavicon from './assets/images/aigfavicon.ico';
import ETFavicon from './assets/images/Vendor/ET/favicon.ico'
import { EConsent } from './screens/Vendor/Apple/EConsent';
import { ProgramSummary } from './screens/Vendor/Apple/ProgramSummary';
import PlanType from "./components/Vendor/Apple/FileInfo/PlanType";
import ContactUs from './screens/Vendor/Apple/ContactUs';
import { ContactConfirmation } from './components/Vendor/Apple/Contact/ContactConfirmation';
import { ETContactConfirmation } from './components/Vendor/ET/Contact/ContactConfirmation';
import { ApptsWrapper } from './components/FileClaim/Appointments';
import RedirectComponent from './screens/Vendor/Apple/RedirectComponent';
import { AltServiceOptions } from './components/AltServiceOptions/AltServiceOptions';
import { HeaderController } from './components/Headers/HeaderController';
import { FooterController } from './components/Footers/FooterController';
import { CoverageDocsController } from './components/CoverageDocs/CoverageDocsController';
import qs from 'qs';
import { verifyHwaToken, verifyAigToken, verifyAigJwtToken, getHwaRedirectUrl, isAppleRoute } from '../src/helpers';
import FAQ from './components/FAQ/FAQ';
import CustomerInfo from './components/Sales/CustomerInfo';
import CustomerSurvey from './components/Vendor/Apple/CustomerSurvey/CustomerSurvey';
import ChatEnd from './components/Vendor/Hwa/ChatEnd';
import { PlanLookup } from './components/PlanSearch/PlanLookup';
import VerifyAigToken from './components/Vendor/Hwa/VerifyAigToken';
import ViewPlanDetails from './screens/PlanOverview/ViewPlanDetails';
import CustomerDetails from './components/Vendor/ET/CustomerDetails';
import DevelopmentBrandToggler from './components/_BrandingProvider/DevelopmentBrandToggler';
import { useEnvironment } from './components/_ReactHooks/useEnvironment';
import { Providers } from './Providers';
import PdfViewer from './components/Global/PdfViewer';
import ResumeClaimSubmission from './screens/Vendor/Apple/ResumeClaimSubmission';
import ForgotPlanNumber from './components/PlanSearch/ForgotPlanNumber';
import AccountVerification from './components/PlanSearch/AccountVerification';
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer';
import HandleTimeoutModal from './SessionTimeout/HandleTimeoutModal';
import ViewAllAccounts from './components/PlanSearch/ViewAllAccounts';
import CreateNewPassword from './components/PlanSearch/CreateNewPassword';
import ForgotPassword from './components/PlanSearch/ForgotPassword';
import AppleCoverageDocs from "./screens/Vendor/Apple/CoverageDocs";
import SystemErrorMessage from './components/shared/SystemErrorMessage';
import { useLogout }  from './components/shared/logout';


export default function App() {
    const { isDevOrLower, isAnyNonProduction, isStagingOrLower, showBrandButtons } = useEnvironment()
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    let claim = location?.state?.claim;
    const { data = {} } = useContext(ContractContext) || {};
    const { contract: { clientId = "aig" } = {} } = !data || data === null ? {} : data;
    const [isCancelClaimFlow, setIsCancelClaimFlow] = useState(false);
    const [showIdleModal, setShowIdleModal] = useState(false);
    const { handleLogout } = useLogout();

    const keepOnPage = (e) => {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';
        if (clientId != 'apple') {
            alert("Closing");
            console.log("closing", e, location, claim)

            if (claim != null && typeof claim != 'undefined') {
                // update claim
                console.log('update claim')
                const step = "";
                const partyModel = null
                const model = { claimModel: claim, partyModel: partyModel }
                fetch(`api/claim/SaveForLater/${claim?.claimId}/${step}/0/${clientId}`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(model)
                })
            }
        }
        else {
            console.log(clientId);
        }
    }

    // Fired on every route change
    useEffect(() => {
        checkAndVerifyToken();
        return () => { }
    }, [location]);

    const checkAndVerifyToken = async () => {
        if (location.pathname.includes('hwa') || clientId == 'hwa') {
            const token = qs.parse(window.location.search, { ignoreQueryPrefix: true }).id;
            if (typeof token !== "undefined" && token !== "" && token !== null) {
                // hwa, verify the token and redirect if invalid
                const isTokenValid = await verifyHwaToken(token);
                if (!isTokenValid) {
                    const url = await getHwaRedirectUrl();
                    window.location.href = `${url}/auth/login`;
                }
            }
        }
        else {
            const token = typeof params.token == 'undefined' ? qs.parse(location?.search, { ignoreQueryPrefix: true })?.token : params.token;
            // Leaving as a reference in case it's needed, can be removed after 02/02/2023
            // const token = qs.parse(window.location.search, { ignoreQueryPrefix: true }).token;
            const tokenResponse = await verifyAigJwtToken(token);
            const isTokenValid = tokenResponse.success;
            const url = tokenResponse.redirect;
            if (token) {
                // verify the token and redirect if invalid
                const isTokenValid = await verifyAigToken(token);
                if (!isTokenValid) {
                    history.push({ pathname: '/plansearch' })
                }
            }
        }
    }

    const handleSkip = (e) => {
        e.preventDefault();
        document.getElementById('reach-skip-nav').scrollIntoView({
            behavior: 'smooth'
        });
        document.getElementById("reach-skip-nav").focus();
    }

    const logoClickHandler = () => {
        if (window.location.pathname.toLowerCase().includes("fileclaim/entitlement")) {
            setIsCancelClaimFlow(true)
        } else {
            window.location = '/planoverview'
        }
    }

    const cancelClaimResumeHandler = () => {
        setIsCancelClaimFlow(false);
    }

    const onPrompt = () => {
        setShowIdleModal(true);
    }

    const onIdleLogout = () => {
        setShowIdleModal(false);
        handleLogout();
    }

    const handleContinue = () => {
        setShowIdleModal(false)
    }

    return (
        <IdleTimerProvider
            timeout={1000 * 60 * 15}
            onPrompt={onPrompt}
            promptBeforeIdle={1000 * 60 * 2}
            startManually={true}
            crossTab={true}
            syncTimers={200}
        >
            <Providers>

                <div className="skip_nav" role="banner" aria-label="skip navigation">
                    <a href="" onClick={handleSkip} data-reach-skip-link data-reach-skip-nav-link>
                        Skip to Content
                    </a>
                </div>
                <HeaderController onLogoClickHandler={logoClickHandler} />
                {showIdleModal && (
                    <HandleTimeoutModal
                        showModal={showIdleModal}
                        onContinue={handleContinue}
                        onLogout={onIdleLogout}
                    />
                )}
                <main role="main" style={{ minHeight: "calc(100vh - 235px)", position: "relative" }}>
                    <Switch>
                        {(window.location.href.toLowerCase().includes("aigtheftandloss") || window.location.href.toLowerCase().includes("theftandloss")) ?
                            (window.location.href.toLowerCase().includes("customersurvey") ?
                                <Route exact path='/vendor/apple/customersurvey/:claimId' component={CustomerSurvey} />
                                : <Route exact path='/' component={Landing} />)
                            :
                            (window.location.href.toLowerCase().includes("customersurvey") ?
                                <Route exact path='/vendor/apple/customersurvey/:claimId' component={CustomerSurvey} />
                                : <Route exact path='/' component={PlanSearch} />)
                        }
                        <Route exact path='/plansearch' component={PlanSearch} />
                        <Route exact path='/plansearch/:channelId/:clientId/:userId/:plannumber/:lastname' component={PlanLookup} />
                        <Route exact path='/detailsoverview' component={DetailsOverview} />
                        <Route exact path='/planoverview' component={PlanOverview} />
                        <Route exact path='/planoverview/contractsoverview' component={ContractsOverview} />
                        <Route exact path='/contractoverview' component={ViewPlanDetails} />
                        <Route exact path='/fileclaim/confirmation' component={Confirmation} history={history} />
                        <Route exact path='/fileclaim/entitlement/:assetId/:claimId/:contractId/:coverageId' render={(props) => <Entitlement cancelClaim={isCancelClaimFlow} resumeClaim={cancelClaimResumeHandler} history={history} {...props} showTimeoutModal={showIdleModal} />} />
                        <Route exact path='/fileclaim/payment' component={Payment} />
                        <Route exact path='/fileclaim/verifyinfo' component={VerifyInfo} />
                        <Route exact path='/fileclaim/reimbursement' component={Reimbursement} />
                        <Route exact path='/vendor/apple' component={Landing} />
                        <Route exact path='/vendor/apple/resume/:token' component={ResumeClaimSubmission} />
                        <Route exact path='/vendor/apple/claimoverview' component={ClaimOverview} history={history} />
                        <Route exact path='/vendor/apple/claimdocuments/:claimId' component={ClaimDocuments} /> {/* Upload Documents page SEU-712 */}
                        <Route exact path='/vendor/apple/claimfailed/:claimId' component={ClaimScripting} /> {/* Failed business rules SEU-713 */}
                        <Route exact path='/vendor/apple/claimreview/:claimId' component={ClaimReview} /> {/* Upload Documents page SEU-712 (Documents loaded) */}
                        <Route exact path='/vendor/apple/entitlement' component={EntitlementContainer} history={history} />
                        <Route exact path='/vendor/apple/verifyinfo' component={VerifyInfoContainer} />
                        <Route exact path='/vendor/apple/payment' component={PaymentContainer} />
                        <Route exact path='/vendor/apple/confirmation' component={ConfirmationLT} />
                        <Route exact path='/vendor/apple/error' component={ErrorMessages} />
                        <Route exact path='/vendor/apple/FAQ' component={Faqs} />
                        <Route exact path='/vendor/apple/uploaddocs' component={UploadDocsContainer} history={history} />
                        <Route exact path='/vendor/apple/eConsent' component={EConsent} />
                        <Route exact path='/eConsent' component={EConsent} />
                        <Route exact path='/vendor/apple/programsummarydisclosures' component={ProgramSummary} />
                        <Route exact path='/programsummarydisclosures' component={ProgramSummary} />
                        <Route exact path="/vendor/apple/coveragedocs" component={AppleCoverageDocs} />
                        <Route exact path="/coveragedocs" component={CoverageDocsController} />
                        <Route exact path="/assetcoverageoverview" component={AssetCoverageOverview} />
                        <Route exact path="/programcoveragedocs" component={PlanType} />
                        <Route exact path="/coveragedocs/plantype" component={PlanType} />
                        <Route exact path='/vendor/apple/contactus' component={ContactUs} />
                        <Route exact path='/info/contact-us' component={ContactUs} />
                        <Route exact path='/vendor/apple/contactconfirmation' component={ContactConfirmation} />
                        <Route exact path='/vendor/et/contactconfirmation' component={ETContactConfirmation} />
                        <Route exact path='/paynow/:token/:clientId' component={RedirectComponent} />
                        <Route exact path='/altServiceoptions' component={AltServiceOptions} />
                        <Route exact path='/appointment' render={(props) => <ApptsWrapper showTimeoutModal={showIdleModal} {...props} />} />
                        <Route exact path='/faq' component={FAQ} />
                        <Route exact path='/customerinfo' component={CustomerInfo} />
                        <Route exact path='/vendor/apple/customersurvey/:claimId' component={CustomerSurvey} />
                        <Route exact path='/chat' render={() => { window.location.href = "Mobile VA.html" }} />
                        <Route exact path='/chatend' component={ChatEnd} />
                        <Route exact path='/vendor/hwa/verifyaigtoken/:aigtoken/:agreementid' component={VerifyAigToken} />
                        <Route exact path='/vendor/VerifyAigToken' component={VerifyAigToken} />
                        <Route exact path='/vendor/handshake' component={PlanSearch} />
                        <Route exact path='/documents/:name' component={PdfViewer} />
                        {/*<Route exact path='/vendor/handshake' component={VerifyAigToken}/>*/}
                        {/* ADDING ROUTE FOR UI CHANGES TO BE VERIFY ON LOCAL, IF REQUIRED CAN CHANGE/REMOVE AFTER API CHANGES */}
                        <Route exact path='/customerDetails' component={CustomerDetails} />
                        <Route exact path='/forgotplan' component={ForgotPlanNumber} />
                        <Route exact path='/accountverification' component={AccountVerification} />
                        <Route exact path='/createnewpassword' component={CreateNewPassword} />
                        <Route exact path='/forgotpassword' component={ForgotPassword} />
                        <Route exact path='/accounts' component={ViewAllAccounts} />
                        <Route exact path='/system-error' component={SystemErrorMessage} />
                    </Switch>
                    <HelmetComponent />
                </main>

                {/* DISPLAY FOOTER IN CERTAIN SCENARIOS */}
                {!(window.location.pathname.toLowerCase().includes("apple")
                    || window.location.pathname.toLowerCase().includes("file/payment")
                    || window.location.pathname.toLowerCase().includes("file/entitlement")
                    || window.location.pathname.toLowerCase().includes("fileclaim/verifyinfo")
                    || window.location.pathname.toLowerCase().includes("econsent")
                    || window.location.pathname.toLowerCase().includes("programsummarydisclosure")
                    || window.location.href.toLowerCase().includes("aigtheftandloss")
                    || window.location.pathname.toLowerCase().includes("planoverview")
                    || window.location.pathname.toLowerCase().includes("fileclaim/entitlement")
                    || window.location.pathname.toLowerCase().includes("appointment")
                    || window.location.pathname.toLowerCase().includes("fileclaim/confirmation")
                    || window.location.pathname.toLowerCase().includes("detailsoverview")
                    || window.location.pathname.toLowerCase().includes("contractoverview")
                    || window.location.pathname.toLowerCase().includes("chat")
                    || window.location.pathname.toLowerCase().includes("faq")
                    || window.location.pathname.toLowerCase().includes("coveragedocs")
                    || window.location.pathname.toLowerCase().includes("contact-us")
                    || window.location.pathname.toLowerCase().includes("info/contact-us")
                    || window.location.href.toLowerCase().includes("theftandloss")
                    || window.location.href.toLowerCase().includes("constellation")
                ) ? (
                    null
                ) : (
                    <FooterController />
                )}
                {isStagingOrLower && showBrandButtons && (
                    <DevelopmentBrandToggler />
                )}
            </Providers>
        </IdleTimerProvider>
    );

}


// ALLOWS HELMET TO ACCESS ContractContext 
const HelmetComponent = () => {
    const context = useContext(ContractContext);
    const idleTimer = useIdleTimerContext();

    // condition activates timeout modal
    if (context?.data?.loggedIn) {
        idleTimer.start();
    } else {
        idleTimer.reset();
        idleTimer.pause();
    }

    if (isAppleRoute()) {
        return (
            <Helmet>
                <link rel='icon' href={AIGFavicon} />
            </Helmet>
        )
    }
    if (!context.data) return (
        <Helmet>
            <link rel="icon" href='' />
            <title></title>
        </Helmet>
    )


    const { clientId } = (context.data.contract || context)

    return (
        <Helmet>
            <meta charSet="utf-8" />
            {/* <meta name="description" content="this is react-helmet tutorial" /> */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="icon" href={getMetaDataByClient(clientId).favicon} />
            <title>{getMetaDataByClient(clientId?.toLowerCase).title}</title>
        </Helmet>
    )
}

// Can take a client ID and a page and return a full title, IE "AIG Claims | Plan Overview"
export const getMetaDataByClient = (clientId, append) => {
    const concatenated = titleString => {
        if (!append) return titleString;
        return titleString.concat(' | ', append)
    }

    const defaultMetaData = {
        favicon: null,
        title: concatenated('AIG Claims Portal')
    }

    if (isAppleRoute()) return {
        favicon: AIGFavicon,
        title: ''
    }

    switch (clientId) {
        case 'hwa':
            return {
                favicon: HWAFavicon,
                title: concatenated('HWA Claims Portal')
            };
        case 'et':
            return {
                favicon: ETFavicon, /* TODO: need to pull the actual ETFavicon (not provided yet)*/
                title: concatenated('Energy Texas Claims Portal')
            };
        default:
            return defaultMetaData;
    }
}