import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Badge, Card, CardBody, CardHeader, Button } from 'reactstrap';
import styles from './ViewAllAccounts.module.css';
import { ReactComponent as Home } from '../../assets/images/home.svg';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useContext } from "react";
import { ContractContext } from "../contexts/ContractContext";
import getClientContent from '../_BrandingProvider/brandContent';
import AIGStatusBadge from "../shared/StatusBadge/StatusBadge";

const ViewAllAccounts = () => {
    const context = useContext(ContractContext);
    const { data: { clientId } } = context;
    const { variableText: { customerService } } = getClientContent(clientId);

    const [showPlans, setShowPlans] = useState([]);
    const history = useHistory();
    const contracts = context.data?.contracts || [];
    const email = context.data?.party?.partyEmailModel[0]?.address || context.data?.planLogin?.email || "";

    useEffect(() => {

        const fetchPlans = async () => {
            try {
                const res = await fetch(`api/contract/FindContractsByEmailAndClient`, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        clientId: clientId,
                        emailAddress: email,
                        sendEmail: false
                    })
                });
                if (res.ok) {
                    const data = await res.json();
                    setShowPlans(processData(data));
                }
            } catch (error) {
                history.push('/system-error');
            }
        };

        if (contracts?.length === 0) {
            fetchPlans();
        } else {
            setShowPlans(processData(contracts));
        }

    }, []);

    const processData = (accountData) => {
        return accountData?.map((data) => {
            const { firstName, lastName } = data?.party;
            const partyName = `${firstName} ${lastName}`;
            const statusId = data?.statusId;
            const planName = data?.riskProfile?.name;
            const { address1, city, state, zip, address2 } = data?.party?.serviceAddress;
            const serviceAddress = `${address1 && address1 + ", "}${address2 && address2 + ", "}${city && city + ",  "}${state && state + " "}${zip && zip}`
            const clientContractNumber = data?.clientContractNumber;
            const planStartDate = data?.contractAssetModel[0]?.contractAssetCoverageModel[0]?.enforcementDate;
            return {
                partyName,
                statusId,
                planName,
                serviceAddress,
                clientContractNumber,
                planStartDate
            };
        });
    };

    const viewPlan = () => {
        const pathName = '/planoverview';
        history.push({
            pathname: pathName,
        });
    }

    return (
        <div>
            <section className={styles.planhero}>
                <Container>
                    <Row>
                        <Col md={5} lg={7}>
                            <h1>My Plans</h1>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className={styles.cardContainer}>
                        {showPlans?.map((plan) => {
                            return <Col md={6} lg={6}>
                                <Card className={styles.card}>
                                    <CardHeader className={styles.cardHeader}>
                                        <h3>{plan?.planName}</h3>
                                        <AIGStatusBadge
                                            statusId={plan?.statusId}
                                            statusType="contract"
                                        />
                                    </CardHeader>
                                    <CardBody className={styles.cardBody}>
                                        <Col className="p-0">
                                            <ul className="noBullets">
                                                <li>{plan?.partyName}</li>
                                                <li>Plan Start: <span>{moment(plan?.planStartDate).format("MM/DD/YYYY")}</span></li>
                                                <li>Service Address: <span>{plan?.serviceAddress}</span></li>
                                                <li>Contract: #<span>{plan?.clientContractNumber}</span></li>
                                            </ul>
                                            <Button
                                                color="secondary"
                                                onClick={(e) => viewPlan()}
                                            >
                                                View Plan
                                            </Button>
                                        </Col>
                                        <Home className={styles.cardIcon} />
                                    </CardBody>
                                </Card>
                            </Col>
                        })}
                    </Row>
                    <p className={styles.contactInfo}>
                        Incorrect plan? Call us at
                        <a
                            href={`tel:${customerService?.phone}`}
                            className="ml-1 callLink font-weight-bold"
                            aria-label={`Click to Call ${customerService?.phone}`}
                        >
                            {customerService?.phone}
                        </a>
                        .
                    </p>
                </Container>
            </section>
        </div>
    );
};

export default ViewAllAccounts;