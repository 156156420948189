import React, { Component, useContext } from 'react';
import {
    Row, Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { DropDownInput } from '../../../../components/inputs/DropDownInput';
import { DropDownAutoComplete } from '../../../../components/inputs/DropDownAutoComplete';
import CancelModal from '../../../../components/modals/CancelModal';
import SaveModal from '../../../../components/modals/SaveModal';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import { Spinner } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import SuggestedAddress from '../../../modals/SuggestedAddress';
import InvalidAddress from '../../../modals/InvalidAddress';
import { verifyAddress, VerificationResponseCodes, getLocationFromZip } from '../../../api/addressverification';
import PhoneNumberInput from '../../../shared/PhoneNumberUtility/PhoneNumberInput';
import { AddressForm, StaticAddress } from './CountryAddressForms';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent'
import { LanguageContext } from '../../../contexts/LanguageTranslation/LanguageContext';
import { ContractContext, BrandingContext } from '../../../contexts/ContractContext';
import IntakeLossButtons from './IntakeLossButtons';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { ISOCountryCodes } from '../../../shared/PhoneNumberUtility/phoneUtils';
import { countryNameFromCode } from '../../../../helpers';

export default class VerifyInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientId: (typeof this.props.entitlementDetails === "undefined" || this.props.entitlementDetails === null || typeof this.props.entitlementDetails.clientId === "undefined") ? "apple" : this.props.entitlementDetails.clientId,
            useMailing: true,
            firstName: this.props.entitlementDetails.party.firstName,
            lastName: this.props.entitlementDetails.party.lastName,
            address1: this.props.entitlementDetails?.party?.serviceAddress?.address1 || this.props.entitlementDetails?.party?.billingAddress?.address1 || '',
            address2: this.props.entitlementDetails?.party?.serviceAddress?.address2 || this.props.entitlementDetails?.party?.billingAddress?.address2 || '',
            city: this.props.entitlementDetails?.party?.serviceAddress?.city || this.props.entitlementDetails?.party?.billingAddress?.city || '',
            state: this.props.entitlementDetails?.party?.serviceAddress?.state || this.props.entitlementDetails?.party?.billingAddress?.state || '',
            stateId: this.props.entitlementDetails?.party?.serviceAddress?.stateId || this.props.entitlementDetails?.party?.billingAddress?.stateId || '',
            zip: this.props.entitlementDetails?.party?.serviceAddress?.zip || this.props.entitlementDetails?.party?.billingAddress?.zip || '',
            contactPhone: this.props.entitlementDetails?.partyPhone || this.props.entitlementDetails.party.partyPhoneModel[0]?.number || '',
            email: typeof this.props.entitlementDetails.party.partyEmailModel[0] === "undefined" ? "" : this.props.entitlementDetails.party.partyEmailModel[0].address,
            states: null,
            activeCountries: null,
            searching: false,
            claimNumber: this.props.entitlementDetails.claimNumber,
            caseNumber: this.props.entitlementDetails.caseNumber,
            pageTitle: 'Shipping Info | AIG AppleCare+ with Theft and Loss Claims',
            showSuggestedAddressValidationModal: false,
            showInvalidAddressModal: false,
            verifiedAddress: null,
            addressToVerify: null,
            countryCode: this.props.entitlementDetails?.countryCode ?? "us",
            shipToCountryCode: this.props.entitlementDetails?.shipToCountryCode ?? this.props.entitlementDetails?.countryCode,
            zipValidator: "required|numeric|size:5",
            zipLength: 5,
            validationFired: false,
            incomingAddressFromProps: {
                firstName: this.props.entitlementDetails.party.firstName,
                lastName: this.props.entitlementDetails.party.lastName,
                address1: this.props.entitlementDetails?.party?.serviceAddress?.address1 || this.props.entitlementDetails?.party?.billingAddress?.address1 || '',
                address2: this.props.entitlementDetails?.party?.serviceAddress?.address2 || this.props.entitlementDetails?.party?.billingAddress?.address2 || '',
                city: this.props.entitlementDetails?.party?.serviceAddress?.city || this.props.entitlementDetails?.party?.billingAddress?.city || '',
                state: this.props.entitlementDetails?.party?.serviceAddress?.state || this.props.entitlementDetails?.party?.billingAddress?.state || '',
                stateId: this.props.entitlementDetails?.party?.serviceAddress?.stateId || this.props.entitlementDetails?.party?.billingAddress?.stateId || '',
                zip: this.props.entitlementDetails?.party?.serviceAddress?.zip || this.props.entitlementDetails?.party?.billingAddress?.zip || '',
                country: this.props.entitlementDetails?.party?.serviceAddress?.countryCode || this.props.entitlementDetails?.party?.billingAddress?.countryCode || this.props.entitlementDetails?.countryCode
            }
        }
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.keepOnPage = this.keepOnPage.bind(this);
        this.saveForLater = this.saveForLater.bind(this);
        this.formatNullText = this.formatNullText.bind(this);
        this.validateExistingAddress = this.validateExistingAddress.bind(this);
        this.validator = new SimpleReactValidator({
            /*validators: {
                zip: {
                    message: 'The :attribute is invalid.',
                    rule: (val, params, validator) => {
                        // validate zip as numeric 5 digits for US and DE, 4 digits for AU and A9A 9A9 for DE
                        // i.e. W1F 9HT or RG27 0YX
                        return validator.helpers.testRegex(val, /^(0[1-9]|1[0-2])\/?([0-9]{2})$/) && params.indexOf(val) === -1
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                }
            },*/
            messages: {
                phone: "Please enter a valid phone number.",
                email: "Please enter a valid email.",
                required: ':attribute is required.'
            },
            validators: {
                valid_phone: {
                    message: 'Please enter a valid phone number.',
                    rule: (val, params, validator) => {
                        return !new RegExp("^([0-9])\\1+$").test(val.match(/\d+/g).join(''))
                    }
                },
                pobox_check: {
                    message: "Sorry, we are unable to ship to PO Boxes. Please update your shipping address.",
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(?![\d#]\ \w+)?^((?!^ *((^(?![\d#]).*p[ \.-/]*\ ?(o|0)[-. \/\\]*[\ \.\-]+[-]?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? +((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box)|(\bPOB\b)).)*$/igm) && params.indexOf(val) === -1
                    }
                }
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        });
    }


    onSaveForLaterAction = e => {
        e.preventDefault();
        this.setState({ showConfirmation: true });
    }

    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
    }

    validateExistingAddress = (address) => {
        if (!address) {
            return false;
        }
        if (this.formatNullText(address?.address1) == "" || this.formatNullText(address?.city) == "" || this.formatNullText(address?.zip) == "" || this.formatNullText(this.props.entitlementDetails.party.firstName) == "" || this.formatNullText(this.props.entitlementDetails.party.lastName) == "") {
            return true;
        }
        return false;
    }

    componentWillMount() {
        window.addEventListener('beforeunload', this.keepOnPage);        
    }

    handleCheckChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: !this.state.useMailing
        })
    }

    handleInputChange = e => {
        let { name, value } = e.target;


        if (name === "contactPhone") {
            // console.log("=== e.target ===", e.target.rawValue, e.target.phoneNumberModel)
            const { significant, input } = e.target.phoneNumberModel.number
            value = significant || input
        }

        if (name.includes("zip") && value.length >= 5) {
            getLocationFromZip(value, this.state.clientId, this.onZipVerified);
            this.setState({
                zipCode: value,
                zip: value
            });
        }

        this.setState({
            [name]: value
        });
    }

    onZipVerified = (result) => {
        if (result.successful) {
            var state = this.state.states.find(({ abbreviation }) => abbreviation === result.location.state);
            if (!state) return;
            this.setState({
                city: result.location.city,
                stateId: state.data,
                state: state.display
            });
        }
    }

    handleStateDropDown = e => {
        var state = this.state.states.find(({ data }) => data === e).display;
        this.setState({ stateId: e, state: state });
    }


    handleVerifyAddress = () => {
        this.setState({ validationFired: true });
        console.log(this.validator, this.state)

        if (this.validator.allValid()) {
            let shipState = this.state.stateId;
            let shipToCountry = this.state.shipToCountryCode ?? this.props.entitlementDetails?.countryCode;
            var stateInfo = this.state.states.filter(function (el) {
                return el.data === shipState;
            });

            let addressToVerify = {
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: stateInfo.length === 1 ? stateInfo[0].display : "",
                zip: this.state.zip,
                country: shipToCountry ?? "us",
            }
            if (this.state.countryCode.toLowerCase() === 'cl') {
                addressToVerify.state = this.state.state;
            }

            this.setState({
                addressToVerify: addressToVerify
            });

            verifyAddress(addressToVerify, this.state.clientId, this.onAddressVerified);
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onAddressVerified = (result) => {
        //console.log(`LOG>> onAddressVerified: Result Code: ${result.code}`);

        if (result.code === VerificationResponseCodes.addressVerified) {
            this.setState({
                showSuggestedAddressValidationModal: false
            }, () => this.continue());
        } else if (result.code === VerificationResponseCodes.addressChanged) {
            this.setState({
                showSuggestedAddressValidationModal: true,
                verifiedAddress: result.returnedAddress
            });
        } else {
            this.setState({
                showInvalidAddressModal: true
            });
        }
    }

    onContinueSaveAction = (selectedAddress) => {

        if (selectedAddress === "suggestedAddress") {
            let newAddress = this.state.verifiedAddress;
            let suggestedState;
            let stateId = null;
            let state = null;
            if (this.state.states.length) {
                suggestedState = this.state.states.find(st => st.abbreviation === newAddress.stateAbbreviation || st.display === newAddress.stateAbbreviation);
                stateId = suggestedState ? suggestedState.data : null;
                state = suggestedState ? newAddress.stateAbbreviation : null;
            }

            if (this.state.countryCode.toLowerCase() === 'cl') {
                state = newAddress.stateAbbreviation;
                stateId = null;
            }

            this.setState({
                showSuggestedAddressValidationModal: false,
                address1: (`${newAddress.address1} ${newAddress.suite}`).trim(),
                address2: newAddress.address2,
                stateId: stateId,
                state: state,
                city: newAddress.city,
                zip: newAddress.zip,
            }, () => this.continue());
        } else {
            this.setState({
                showSuggestedAddressValidationModal: false,
                showInvalidAddressModal: false
            }, () => this.continue());
        }
    }

    onCloseAddressModal = () => {
        this.setState({
            showSuggestedAddressValidationModal: false,
            showInvalidAddressModal: false
        });
    }

    updateAddress = (partyAddress, entitlementDetails, type) => {
        let shipToCountryCode = this.state.shipToCountryCode ?? this.props.entitlementDetails?.countryCode;
        let countryId = this.state.activeCountries?.find(({ countryCode }) => countryCode?.toLowerCase() === shipToCountryCode?.toLowerCase())?.countryId;        
        if (typeof partyAddress.find(({ addressTypeId }) => addressTypeId === type) != "undefined") {
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).address1 = this.state.address1;
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).address2 = this.state.address2;
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).city = this.state.city;
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).state = this.state.state;
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).stateId = this.state.stateId;
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).zip = this.state.zip;
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).countryId = countryId;
            partyAddress.find(({ addressTypeId }) => addressTypeId === type).countryCode = shipToCountryCode;
        }
        else {
            let newAddress = {
                addressTypeId: type,
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                stateId: this.state.stateId,
                zip: this.state.zip,
                countryId: countryId,
                countryCode: shipToCountryCode
            };
            partyAddress.push(newAddress);
        }
    }

    continue = e => {
        this.setState({ searching: true });
        let updatedPhone =
            typeof this.props.entitlementDetails?.party?.partyPhoneModel[0] === "undefined"
                ? ""
                : this.props.entitlementDetails?.party?.partyPhoneModel[0]?.number;
        let updatedEmail =
            typeof this.props.entitlementDetails?.party?.partyEmailModel[0] === "undefined"
                ? ""
                : this.props.entitlementDetails?.party?.partyEmailModel[0]?.address;

        //update phone and email only when its valid for Save for Later    
        if (this.validator.fieldValid('contactPhone')) {
            updatedPhone = this.state.contactPhone;
        }
        if (this.validator.fieldValid('email')) {
            updatedEmail = this.state.email;
        }

        const pn = parsePhoneNumber(updatedPhone, ISOCountryCodes[this.state.countryCode?.toUpperCase()] || 'us')
        let entitlementDetails = this.props.entitlementDetails;
        if (this.state.useMailing) {
            entitlementDetails.firstName = this.state.incomingAddressFromProps.firstName;
            entitlementDetails.lastName = this.state.incomingAddressFromProps.lastName;
            entitlementDetails.party.shippingAddress = { ...entitlementDetails?.party?.serviceAddress }
        } else {
            if (entitlementDetails.party.shippingAddress === null) {
                entitlementDetails.party.shippingAddress = {};
            }
            entitlementDetails.firstName = this.state.firstName;
            entitlementDetails.lastName = this.state.lastName;
            entitlementDetails.party.shippingAddress.address1 = this.state.address1;
            entitlementDetails.party.shippingAddress.address2 = this.state.address2;
            entitlementDetails.party.shippingAddress.city = this.state.city;
            entitlementDetails.party.shippingAddress.state = this.state.state;
            entitlementDetails.party.shippingAddress.zip = this.state.zip;
            entitlementDetails.party.shippingAddress.stateId = this.state.stateId;
            entitlementDetails.party.shippingAddress.countryCode = this.state.shipToCountryCode
        }
        entitlementDetails.contactPhone = pn.getNumber('significant');
        entitlementDetails.email = updatedEmail;
        entitlementDetails.clientId = this.state.clientId;

        let party = entitlementDetails.party;
        party.partyName = entitlementDetails.firstName + " " + entitlementDetails.lastName;
        party.firstName = entitlementDetails.firstName;
        party.lastName = entitlementDetails.lastName;

        let partyPhone = party.partyPhoneModel;
        console.log('=== partyPhone ===', partyPhone)
        let phoneNumber = partyPhone.find(({ phoneTypeId }) => phoneTypeId === 2);

        console.log('=== entitlementDetails.contactPhone ===', entitlementDetails.contactPhone)
        if (!(typeof phoneNumber === "undefined" || phoneNumber === null)) {
            partyPhone.find(({ phoneTypeId }) => phoneTypeId === 2).number = entitlementDetails.contactPhone;
        }
        else {
            // Add new element to array
            var newPhone = { partyId: party.partId, phoneTypeId: 2, number: entitlementDetails.contactPhone, IsPrimary: true };
            partyPhone.push(newPhone);
        }

        party.partyPhoneModel = partyPhone;

        let partyEmail = party.partyEmailModel;
        if (partyEmail.length) {
            partyEmail[0].address = entitlementDetails.email;
        }
        else {
            // Add new element to array
            var newEmail = { partyId: party.partId, address: entitlementDetails.email, IsPrimary: true };
            partyEmail.push(newEmail);
        }

        party.partyEmailModel = partyEmail;

        let partyAddress = party.partyAddressModel;
        const partyMailingAddress = partyAddress.find(({ addressTypeId }) => addressTypeId === 2);
        if (partyMailingAddress.address1 === null || partyMailingAddress.address1 === undefined || partyMailingAddress.address1 === '') {

            this.updateAddress(partyAddress, entitlementDetails, 1);
            this.updateAddress(partyAddress, entitlementDetails, 2);
        }

        if (party.serviceAddress.address1 === null || party.serviceAddress.address1 === undefined || party.serviceAddress.address1 === '') {
            entitlementDetails.party.serviceAddress = { ...entitlementDetails.party.shippingAddress };
            entitlementDetails.party.billingAddress = { ...entitlementDetails.party.shippingAddress };
        }

        this.updateAddress(partyAddress, entitlementDetails, 3);

        party.partyAddressModel = partyAddress;
        console.log(party, partyAddress, partyEmail, partyPhone, entitlementDetails, this.state.clientId);

        entitlementDetails.shippingAddress = partyAddress.find(p => p.addressTypeId == 3); // 3- shipping Address Type        

        let shipToCountryCode = this.state.shipToCountryCode ?? this.props.entitlementDetails?.countryCode;

        if (!(typeof shipToCountryCode === "undefined" || shipToCountryCode === null)) {
            entitlementDetails.shipToCountryCode = shipToCountryCode?.toLowerCase();
            // Save the Ship to country code
            fetch(`api/Contract/SaveContractConfiguration/${this.state.clientId}`,
                {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            contractId: this.props.entitlementDetails.contract.contractId,
                            contractAttributeId: 32, // ship-to Country code
                            value: shipToCountryCode?.toLowerCase()
                        }
                    )
                }).then(res => {
                    if (res.ok) {
                        if (res.status === "204") {
                            res.json().then(data => {
                                console.log(data);
                            });
                        }
                    }
                    else {
                        console.log(res);
                    }
                })
        }

        //let statusId = this.state.isSaveForLater ? 4 : (this.props.entitlementDetails.contract.party.partyStatusId == 4 ? 1 : this.props.entitlementDetails.contract.party.partyStatusId);
        fetch('api/party/'.concat(this.props.entitlementDetails.contract.party.partyId, "/")
            .concat(this.state.clientId),
            {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        partyId: this.props.entitlementDetails.contract.party.partyId,
                        //partyStatusId: statusId,
                        firstName: entitlementDetails.firstName,
                        lastName: entitlementDetails.lastName,
                        partyName: entitlementDetails.firstName + " " + entitlementDetails.lastName,
                        partyAddressModel: partyAddress,
                        partyPhoneModel: partyPhone,
                        partyEmailModel: partyEmail
                    }
                )
            }).then(res => {
                if (res.ok) {
                    console.log(res);
                    if (res.status === 200) {
                        res.json().then(data => {
                            this.setState((prev) => {
                                return {
                                    party: data,
                                    editing: !prev.editing,
                                    saving: !prev.saving
                                }
                            });
                        });
                    }
                    else {
                        this.setState({ searching: false })
                        this.props.history.push('/vendor/apple/error');
                    }
                }
                else {
                    this.setState({ searching: false })
                    this.props.history.push('/vendor/apple/error');
                }
            }).then(() => {
                if (this.state.isSaveForLater) {
                    //let claim = this.props.history.location.state.claim;
                    //claim.incidentDescription = this.state.incidentDescription;
                    //claim.lossDate = this.state.incidentDate;
                    //claim.coverageId = this.state.coverageId;
                    this.saveForLaterAction(this.props.history.location.state.claim, false);
                    //this.props.history.push('/vendor/apple/confirmation', { statusType: "savedClaim", caseNumber: this.props.history.location.state.claim.caseNumber });
                    return;
                }

                this.props.history.push('/vendor/apple/payment', { 
                    entitlementDetails: entitlementDetails, 
                    claim: this.props.history.location.state.claim 
                });
                this.setState({ searching: false });
            }).catch(error => {
                console.log(error);
            });
    }

    isAddressAvailable(addressObj) {
        if (!addressObj) {
            return false;
        }
        if (addressObj.address1 === null || addressObj.address1 === undefined || addressObj.address1 === '') {
            return false;
        }
        return true;
    }

    componentDidMount(prevProps) {
        const { history } = this.props;
        if (history?.location?.pathname === "/vendor/apple/verifyinfo") {
            window.history.pushState(window.scrollTo(0, 0), document.title, window.location.href);
            window.addEventListener('popstate', function (event) {
                window.history.pushState(window.scrollTo(0, 0), document.title, window.location.href);
            });
        }  
        let isFromPayment = this.props.history?.location?.state?.fromPayment;

        let countryCode = isFromPayment ? this.props.entitlementDetails.party.shippingAddress.countryCode : this.props.entitlementDetails.party?.serviceAddress?.countryCode || this.props.entitlementDetails?.countryCode || "us";
        let zipLength = 5;
        let zipValidator = "required| numeric | size:5";

        switch (countryCode) {
            case "aus":
                zipLength = 4;
                zipValidator = "required|numeric|size:4";
                break;
            case "uk":
                zipLength = 8;
                zipValidator = "required|size:8";
                break;
            default:
                zipLength = 5;
                zipValidator = "required|numeric|size:5";
                break;
        }

        fetch('api/party/states/' + countryCode + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    states: data.map(s => {
                        return {
                            data: s.stateId,
                            display: s.name,
                            abbreviation: s.abbreviation
                        }
                    }),
                });
            });

        fetch('api/party/activecountries/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    activeCountries: data.map(s => {
                        return {
                            countryId: s.countryId,
                            countryCode: s.iso2 == 'AU' ? 'AUS' : s.iso2 == 'GB' ? 'UK' : s.iso2,
                            countryName: s.countryName
                        }
                    })
                });
            });

        if (isFromPayment) {
            let servAddress = this.props.entitlementDetails?.party.serviceAddress;
            let shipAddress = this.props.entitlementDetails?.party.shippingAddress;
            if (servAddress.address1 !== shipAddress.address1 || servAddress.address2 !== shipAddress.address2 || servAddress.city !== shipAddress.city || servAddress.zip !== shipAddress.zip) {
                this.setState({
                    useMailing: false,
                    address1: this.props.entitlementDetails.party?.shippingAddress?.address1,
                    address2: this.props.entitlementDetails.party?.shippingAddress?.address2,
                    city: this.props.entitlementDetails.party?.shippingAddress?.city,
                    state: this.props.entitlementDetails.party?.shippingAddress?.state,
                    stateId: this.props.entitlementDetails.party?.shippingAddress?.stateId,
                    zip: this.props.entitlementDetails.party?.shippingAddress?.zip,
                })
            }
        } else {
            const partyMailingAddress = this.props.entitlementDetails.party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 2)
            const partyBillingAddress = this.props.entitlementDetails.party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 1)
            const isMailingAddressAvailable = this.isAddressAvailable(partyMailingAddress) || this.isAddressAvailable(partyBillingAddress) ? true : false
            this.setState({ useMailing: isMailingAddressAvailable });
        }

        this.setState({
            incomingAddressFromProps: {
                firstName: this.props.entitlementDetails.party.firstName,
                lastName: this.props.entitlementDetails.party.lastName,
                address1: this.props.entitlementDetails?.party?.serviceAddress?.address1 || this.props.entitlementDetails?.party?.billingAddress?.address1 || '',
                address2: this.props.entitlementDetails?.party?.serviceAddress?.address2 || this.props.entitlementDetails?.party?.billingAddress?.address2 || '',
                city: this.props.entitlementDetails?.party?.serviceAddress?.city || this.props.entitlementDetails?.party?.billingAddress?.city || '',
                state: this.props.entitlementDetails?.party?.serviceAddress?.state || this.props.entitlementDetails?.party?.billingAddress?.state || '',
                stateId: this.props.entitlementDetails?.party?.serviceAddress?.stateId || this.props.entitlementDetails?.party?.billingAddress?.stateId || '',
                zip: this.props.entitlementDetails?.party?.serviceAddress?.zip || this.props.entitlementDetails?.party?.billingAddress?.zip || '',
                country: this.props.entitlementDetails?.party?.serviceAddress?.countryCode || this.props.entitlementDetails?.party?.billingAddress?.countryCode || this.props.entitlementDetails?.countryCode
            }
        })

        window.addEventListener('beforeunload', this.keepOnPage);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        this.setState({ isSaveForLater: true, abandonClaim: true }, () => { this.continue() });
    }

    saveForLaterAction = (claim, claimAbandoned) => {
        if (!claim) { return; }
        this.setState({ uploading: true });
        console.log(this.props, claim);
        const entitlementDetails = this.props.entitlementDetails;
        const partyModel = entitlementDetails?.party
        const model = { claimModel: claim, partyModel: partyModel }
        fetch(`api/claim/SaveForLater/${claim.claimId}/2/${claimAbandoned}/apple`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        })
            .then(data => {
                if (data.status === 204) {
                }
                if (data.status === 200 && !claimAbandoned) {
                    this.props.history.push(
                        '/vendor/apple/confirmation', 
                        { 
                            statusType: "savedClaim", 
                            caseNumber: claim.clientClaimNumber, 
                            claimEmail: this.state.email 
                        }
                    );
                }
                this.setState({ uploading: false });
            });
    }

    handleChangeShipToCountry = (states, shipToCountryCode) => {
        if (shipToCountryCode?.toLowerCase() === this.props.entitlementDetails?.party?.serviceAddress?.countryCode?.toLowerCase()) {
            this.setState({
                states: states,
                shipToCountryCode: shipToCountryCode?.toLowerCase(),
                state: this.props.entitlementDetails?.party?.serviceAddress?.state || '',
                stateId: this.props.entitlementDetails?.party?.serviceAddress?.stateId || '',
            })
        } else {
            this.setState({
                states: states,
                shipToCountryCode: shipToCountryCode?.toLowerCase(),
                stateId: null,
                state: ''
            })
        }
    }

    keepOnPage(e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';

        alert("Closing");

        this.saveForLaterAction(this.props.history.location.state.claim, true);
    }

    // Might move this one to helpers
    formatNullText = (text) => {
        if (typeof text === "undefined" || text === null) {
            return "";
        }
        return text;
    }

    render() {
        //const testingCountry = 'us' // change the countries fo testing 
        const { pageTitle, incomingAddressFromProps } = this.state;
        const { data: { isChileApple } } = this.context;
        let party = this.props.entitlementDetails.party;
        let address = party?.serviceAddress;
        address = (
            address == null
                || typeof address == "undefined"
                || Object.values(address).every(v => !v)
                ? null : address
        );
        let isFromPayment = this.props.history?.location?.state?.fromPayment;

        // console.log(address != null, !this.validateExistingAddress(address));
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/IntakeLoss/VerifyInfo'}>
                {international => {
                    const {
                        country,
                        translations,
                    } = international
                    return (
                        <DocumentTitle title={pageTitle}>
                            <>
                                <Form>
                                    <fieldset>
                                        <legend className="cardTitle mb-4" tabIndex="0" id="reach-skip-nav">{translations.whereTo || 'Where would you like your replacement device sent?'} <span aria-hidden="true" className="required-indicator">*</span></legend>
                                        {address != null && !this.validateExistingAddress(address) &&
                                            <div className="checkbox">
                                            <Input className="mr-2" type="checkbox" id="mailingaddress" checked={this.state.useMailing} name="useMailing" onChange={this.handleCheckChange} defaultChecked={this.state.useMailing} />
                                                <Label
                                                    for="mailingaddress"
                                                    className={`checkboxLabel ${isChileApple ? 'southbridge' : ''}`}
                                                >
                                                    {translations.useMailing || 'Use my mailing address'}
                                                </Label>
                                            </div>
                                        }
                                        {!this.state.useMailing ? (
                                            <Col md="8" id="shippingAddressFields" className="p-0 mb-3">
                                                <AddressForm
                                                    allowVariableCountry
                                                    onUpdateStates={this.handleChangeShipToCountry}
                                                    clientId={this.state.clientId}
                                                    includeNameFields
                                                    address={{
                                                        firstName: this.state.firstName,
                                                        lastName: this.state.lastName,
                                                        address1: this.state.address1,
                                                        address2: this.state.address2,
                                                        city: this.state.city,
                                                        zip: this.state.zip,
                                                        state: this.state.state,
                                                        stateId: this.state.stateId,
                                                        country: isFromPayment
                                                            ? this.props.entitlementDetails.party?.shippingAddress?.countryCode
                                                            : this.props.entitlementDetails.party?.serviceAddress?.countryCode
                                                            || this.props.entitlementDetails?.countryCode || 'us'
                                                    }}
                                                    states={this.state.states}
                                                    handleInputChange={this.handleInputChange}
                                                    handleStateDropDown={this.handleStateDropDown}
                                                    validator={this.validator}
                                                    validationFired={this.state.validationFired}
                                                    countryCode={this.state.shipToCountryCode}
                                                    isChileApple={isChileApple}
                                                />
                                            </Col>
                                        ) : (
                                            <StaticAddress
                                                showName
                                                showCountry={this.state.countryCode?.toLowerCase() !== this.props.entitlementDetails?.shipToCountryCode}
                                                addressData={incomingAddressFromProps}
                                                countryCode={this.state.shipToCountryCode}                                                
                                                clientId={this.state.clientId}
                                            />
                                        )}
                                    </fieldset>
                                    <fieldset>
                                        <legend className="cardTitle mb-4">{translations.provideInfo || 'We will reach you at the below provided contact details regarding your claim:'}</legend>
                                        <Col md="8" id="contactInformation" className="p-0">
                                            <PhoneNumberInput
                                                fieldName='contactPhone'
                                                label={translations.contactPhone || "Contact Phone"}
                                                value={this.state.contactPhone}
                                                onChange={this.handleInputChange}
                                                validationFired={this.state.validationFired}
                                                error={this.validator.message(
                                                    "contactPhone",
                                                    this.state.contactPhone,
                                                    `required|valid_phone${country === 'US' ? '|phone' : ''}|min:6`,
                                                    {
                                                        messages: {
                                                            required: translations.errors?.contactPhoneRequired || "Contact phone is required.",
                                                            phone: translations.errors?.contactPhoneInvalid || "The phone number must be a valid number.",
                                                            valid_phone: translations.errors?.contactPhoneInvalid || "The phone number must be a valid number.",
                                                            min: translations.errors?.contactPhoneInvalid || "The phone number must be a valid number.",
                                                        }
                                                    }
                                                )}
                                            />

                                            <FormGroup className={classNames(this.state.validationFired && this.validator.message("email", this.state.email, "required|email") && "form-error")}>
                                                <Label for="emailAddress" className="form-label">{translations.email || "Email Address"}</Label>
                                                <span aria-hidden="true" className="required-indicator">*</span>
                                                <Input
                                                    className="form-control"
                                                    autoComplete="email"
                                                    id="emailAddress"
                                                    type="email"
                                                    name="email"
                                                    onChange={this.handleInputChange}
                                                    value={this.state.email}
                                                    aria-required="true"
                                                />
                                                {this.validator.message(
                                                    "email",
                                                    this.state.email,
                                                    "required|email",
                                                    {
                                                        messages: {
                                                            required: translations.errors?.emailRequired || 'Email is required.',
                                                            email: translations.errors?.emailInvalid || 'The email must be a valid email address.'
                                                        }
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </fieldset>
                                </Form>
                                <Row>
                                    <IntakeLossButtons
                                        // PRIMARY BUTTON PROPS
                                        buttonText={translations.continue || "Continue"}
                                        onButtonClick={this.handleVerifyAddress}
                                        buttonDisabled={this.state.searching}
                                        buttonSpinning={this.state.searching}
                                        buttonAriaLabel={translations.continueBtnAria || "Click continue to proceed to pay deductible step"}
                                        // FIRST LINK UNDER BUTTON
                                        showLink1={true}
                                        onLink1Click={this.onSaveForLaterAction}
                                        link1AriaLabel={translations.saveBtnAria || "Opens a modal to save your claim"}
                                        link1Text={translations.save || "Save For Later"}
                                        // CANCEL LINK PROPS CONTROL CANCEL MODAL
                                        saveClaimClickActionFromWithinCancelModal={this.onSaveForLaterAction}
                                        caseNumberToCancel={this.state.caseNumber}
                                        claimNumberToCancel={this.state.claimNumber}
                                    />
                                    {this.state.showConfirmation &&
                                        <SaveModal
                                            isOpen={this.state.showConfirmation}
                                            showConfirmation={this.state.showConfirmation}
                                            history={this.props.history}
                                            continuing={this.state.isSaveForLater}
                                            onCancel={this.onSaveCancel}
                                            onSaveForLaterAction={this.saveForLater} client={"apple"}
                                        />
                                    }
                                </Row>
                                {this.state.showSuggestedAddressValidationModal &&
                                    <SuggestedAddress
                                        showSuggestedAddressValidationModal={this.state.showSuggestedAddressValidationModal}
                                        enteredAddress={this.state.addressToVerify}
                                        suggestedAddress={this.state.verifiedAddress}
                                        onContinueAction={this.onContinueSaveAction}
                                        onEditAddress={this.onCloseAddressModal}
                                        clientId={this.state.clientId}

                                    />}

                                {this.state.showInvalidAddressModal &&
                                    <InvalidAddress
                                        showInvalidAddressModal={this.state.showInvalidAddressModal}
                                        onEditAddress={this.onCloseAddressModal}
                                        onContinueAction={this.onContinueSaveAction} />}
                            </>
                        </DocumentTitle>
                    );
                }
                }
            </UseTranslationInClassComponent>
        )
    }
}

VerifyInfo.contextType = ContractContext