import React, { useEffect } from 'react';
import styles from './Entitlement.module.css';
import classNames from 'classnames';
import {
    Col, Row,  Label, Input
} from 'reactstrap';

let cardTitle = styles.cardTitle;
let wifiDesc = styles.wifiDesc;

function FollowUp({
    followUpChosen, 
    symptom, 
    symptoms, 
    currentQuestionModel, 
    showError,
    selectedAnswer
}) {
    let answers = '';
    let quesDesc = '';
    let questionName = '';
    useEffect(() => {
        var inputs = document.getElementsByTagName('input');
        for (var i = inputs.length - 1; i >= 0; i--) {
            if (inputs[i].getAttribute('type') === 'radio')
                inputs[i].checked = false;
        }

    }, []);

  const  getAnswerGroup = () => {

        // get answers for question

        var chosenSymptom = symptoms?.find(s => s.questionSymptomMappingId == symptom.questionSymptomMappingId);
        var questionId = currentQuestionModel?.questionId ?? 0;

        var questionSetMappingModel = chosenSymptom?.questionSet?.questionSetMappingModel;
        var questionSetMapping = null;

        if (questionSetMappingModel == null && typeof questionSetMappingModel == 'undefined') {
            return null;
        }
        else if (Array.isArray(questionSetMappingModel)) {
            questionSetMapping = questionSetMappingModel?.find(qsm => qsm.questionId == questionId);
        }
        else {
            questionSetMapping = questionSetMappingModel?.questionId == questionId ? questionSetMapping : null;
        }

        if (questionSetMapping != null && typeof questionSetMapping != 'undefined') {
            var answers = questionSetMapping.questionAnswerMappingModel.filter(a => a.answerId != 3);
            return (
                answers.map((questionAnswerMapping, id) => {
                    var name = getAnswerName(questionAnswerMapping.answerId);
                    var answerId = questionAnswerMapping.answerId;
                    if (answerId != 3 || answerId != 0) {
                        return (
                            <div key={id} className="radioButton col-md-6">
                                <Input 
                                    type="radio" 
                                    name="yesNoGroup" 
                                    id={name} 
                                    value={answerId} 
                                    onChange={e => followUpChosen(e.target.value)}
                                    checked={answerId == selectedAnswer}
                                />
                                <Label className="radio-controls-label" htmlFor={name}>{name}</Label>
                            </div>
                        )
                    }
                    else {
                        return null;
                    }
                })
            );
        }

        return null;

    }

    const getAnswerName = (answerId) => {

        switch (answerId) {
            case 1:
                return 'No';
            case 2:
                return 'Yes';
            case 22:
                return 'I\'m not sure';
            default:
                return null;
        }
    }

    // NPB 041321 - SEEMS TO NOT BE IN USE. DELETE IN FUTURE IF NOT BREAKING
    // const clearRadioButtons = () => {
    //     var inputs = document.getElementsByTagName('input');
    //     for (var i = inputs.length - 1; i >= 0; i--) {
    //         if (inputs[i].getAttribute('type') === 'radio')
    //             inputs[i].checked = false;
    //     }
    // }

    // clear selection of the last answer group
    questionName = (currentQuestionModel == null || currentQuestionModel.questionName == null || currentQuestionModel.questionName == "priorityservice") ? questionName : currentQuestionModel.questionName;
    quesDesc = (currentQuestionModel == null || currentQuestionModel && currentQuestionModel.questionDescription == null) ? '' : currentQuestionModel.questionDescription;
    answers = getAnswerGroup() == null ? answers : getAnswerGroup();
    return <div id="followQuestion">
        <form>
            <div className="form-group">
                <h2 className={classNames(cardTitle, "mb-0")} htmlFor="followUpGroup">{currentQuestionModel?.questionName}</h2>
                <p className={classNames("helpText", wifiDesc)}>{quesDesc || ''}</p>
                <Row className="stepsFormBlock">
                    <Col md="5" lg="4">
                        <Row role="radiogroup" className="symptomGroup">
                            {answers || ''}
                        </Row>
                        {showError && 
                            <p className="errorMsg ">
                                Select an option to continue.
                            </p>
                        }
                    </Col>
                </Row>
            </div>
        </form>
    </div>

}

export default FollowUp;