import React from 'react';

// DEFAULT AIG
import {ReactComponent as CalendarIcon} from '../../assets/images/Calender_Icon.svg'
import {ReactComponent as Phone} from '../../assets/images/Call_Icon.svg'
import {ReactComponent as Headset} from '../../assets/images/Servicer_Icon.svg'
import {ReactComponent as Replacement} from '../../assets/images/claim-complete.svg'
import {ReactComponent as Denied} from '../../assets/images/claim-cancelled.svg'
import { ReactComponent as Customer } from '../../assets/images/Customer.svg'
import { ReactComponent as ClaimSaved } from '../../assets/images/claim-saved.svg'
import { ReactComponent as ChatEndIcon } from "../../assets/images/chat-complete.svg";
import { ReactComponent as EmailSentIcon } from "../../assets/images/email-sent.svg";
import { ReactComponent as SystemErrorIcon } from '../../assets/images/exclamation-circle_new.svg';
// HWA
import {ReactComponent as HWACalendarIcon} from '../../assets/images/Vendor/HWA/Calender_Icon.svg'
import {ReactComponent as HWAPhone} from '../../assets/images/Vendor/HWA/Call_Icon.svg'
import {ReactComponent as HWAHeadset} from '../../assets/images/Vendor/HWA/Servicer_Icon.svg'
import {ReactComponent as HWAReplacement} from '../../assets/images/Vendor/HWA/claim-complete.svg'
import {ReactComponent as HWADenied} from '../../assets/images/Vendor/HWA/claim-cancelled.svg'
import { ReactComponent as HWACustomer } from '../../assets/images/Vendor/HWA/Customer.svg'
import { ReactComponent as HWAClaimSaved } from '../../assets/images/Vendor/HWA/claim-saved.svg'
import { ReactComponent as HWAChatEndIcon } from "../../assets/images/Vendor/HWA/chat-complete.svg";
import { ReactComponent as HWASystemErrorIcon } from '../../assets/images/Vendor/HWA/exclamation-circle.svg';
// ET
import {ReactComponent as ETCalendarIcon} from '../../assets/images/Vendor/ET/Calender_Icon.svg'
import {ReactComponent as ETPhone} from '../../assets/images/Vendor/ET/Call_Icon.svg'
import {ReactComponent as ETHeadset} from '../../assets/images/Vendor/ET/Servicer_Icon.svg'
import {ReactComponent as ETReplacement} from '../../assets/images/Vendor/ET/claim-complete.svg'
import {ReactComponent as ETDenied} from '../../assets/images/Vendor/ET/claim-cancelled.svg'
import { ReactComponent as ETCustomer } from '../../assets/images/Vendor/ET/Customer.svg'
import { ReactComponent as ETClaimSaved } from '../../assets/images/Vendor/ET/claim-saved.svg'
import { ReactComponent as ETChatEndIcon } from "../../assets/images/Vendor/ET/chat-complete.svg";
import { ReactComponent as ETEmailSentIcon } from "../../assets/images/Vendor/ET/email-sent.svg";
import { ReactComponent as ETSystemErrorIcon } from '../../assets/images/Vendor/ET/exclamation-circle.svg';
// Southbridge 
import { ReactComponent as SBCalendarIcon } from '../../assets/images/Vendor/SB/Calender_Icon.svg'
import { ReactComponent as SBPhone } from '../../assets/images/Vendor/SB/Call_Icon.svg'
import { ReactComponent as SBHeadset } from '../../assets/images/Vendor/SB/Servicer_Icon.svg'
import { ReactComponent as SBReplacement } from '../../assets/images/Vendor/SB/claim-complete.svg'
import { ReactComponent as SBDenied } from '../../assets/images/Vendor/SB/claim-cancelled.svg'
import { ReactComponent as SBCustomer } from '../../assets/images/Vendor/SB/Customer.svg'
import { ReactComponent as SBClaimSaved } from '../../assets/images/Vendor/SB/claim-saved.svg'
import { ReactComponent as SBChatEndIcon } from "../../assets/images/Vendor/SB/chat-complete.svg";
import { ReactComponent as SBEmailSentIcon } from "../../assets/images/Vendor/SB/email-sent.svg";
// Constellation 
import { ReactComponent as ConstellationCalendarIcon } from '../../assets/images/Vendor/Constellation/Calender_Icon.svg'
import { ReactComponent as ConstellationPhone } from '../../assets/images/Vendor/Constellation/Call_Icon.svg'
import { ReactComponent as ConstellationHeadset } from '../../assets/images/Vendor/Constellation/Servicer_Icon.svg'
import { ReactComponent as ConstellationReplacement } from '../../assets/images/Vendor/Constellation/claim-complete.svg'
import { ReactComponent as ConstellationDenied } from '../../assets/images/Vendor/Constellation/claim-cancelled.svg'
import { ReactComponent as ConstellationCustomer } from '../../assets/images/Vendor/Constellation/Customer.svg'
import { ReactComponent as ConstellationClaimSaved } from '../../assets/images/Vendor/Constellation/claim-saved.svg'
import { ReactComponent as ConstellationChatEndIcon } from "../../assets/images/Vendor/Constellation/chat-complete.svg";
import { ReactComponent as ConstellationEmailSentIcon } from "../../assets/images/Vendor/Constellation/email-sent.svg";
import { ReactComponent as ConstellationSystemErrorIcon } from '../../assets/images/Vendor/Constellation/exclamation-circle.svg';
import { ReactComponent as SBErrorIcon } from '../../assets/images/Vendor/SB/exclamation-circle.svg';
import { ReactComponent as SBSystemErrorIcon } from '../../assets/images/Vendor/SB/exclamation-circle.svg';
// Cutler
import  { ReactComponent as CutlerSystemErrorIcon } from '../../assets/images/exclamation-circle_new.svg';

import styles from './WhiteLabeling.module.css';


const BrandedIcon = ({client, iconType}) => {

    const getIcon = () => {
        const clientLower = client?.toLowerCase();
        // REPEAT THIS "IF" FOR EACH NEW BRAND
        if (clientLower === 'hwa') {
            if (iconType.toLowerCase() === 'confirm1') return <HWACalendarIcon/>
            if (iconType.toLowerCase() === 'confirm2-3') return <HWAPhone/>
            if (iconType.toLowerCase() === 'confirm4') return <HWAHeadset/>
            if (iconType.toLowerCase() === 'denied') return <HWADenied/>
            if (iconType.toLowerCase() === 'replacement') return <HWAReplacement/>
            if (iconType.toLowerCase() === 'customer') return <HWACustomer />
            if (iconType.toLowerCase() === 'confirmsavedclaim') return <HWAClaimSaved />
            if (iconType.toLowerCase() === 'chatcomplete') return <HWAChatEndIcon />
            if (iconType.toLowerCase() === 'system-error') return <HWASystemErrorIcon />
        }
        if (clientLower === 'et') {
            if (iconType.toLowerCase() === 'confirm1') return <ETCalendarIcon/>
            if (iconType.toLowerCase() === 'confirm2-3') return <ETPhone/>
            if (iconType.toLowerCase() === 'confirm4') return <ETHeadset/>
            if (iconType.toLowerCase() === 'denied') return <ETDenied/>
            if (iconType.toLowerCase() === 'replacement') return <ETReplacement/>
            if (iconType.toLowerCase() === 'customer') return <ETCustomer />
            if (iconType.toLowerCase() === 'confirmsavedclaim') return <ETClaimSaved />
            if (iconType.toLowerCase() === 'chatcomplete') return <ETChatEndIcon />
            if (iconType.toLowerCase() === 'emailsent') return <ETEmailSentIcon />
            if (iconType.toLowerCase() === 'system-error') return <ETSystemErrorIcon/>
        }
        if (clientLower === 'southbridge') {
            if (iconType.toLowerCase() === 'confirm1') return <SBCalendarIcon />
            if (iconType.toLowerCase() === 'confirm2-3') return <SBPhone />
            if (iconType.toLowerCase() === 'confirm4') return <SBHeadset />
            if (iconType.toLowerCase() === 'denied') return <SBDenied />
            if (iconType.toLowerCase() === 'replacement') return <SBReplacement />
            if (iconType.toLowerCase() === 'customer') return <SBCustomer />
            if (iconType.toLowerCase() === 'confirmsavedclaim') return <SBClaimSaved />
            if (iconType.toLowerCase() === 'chatcomplete') return <SBChatEndIcon />
            if (iconType.toLowerCase() === 'emailsent') return <SBEmailSentIcon />
            if (iconType.toLowerCase() === 'system-error') return <SBSystemErrorIcon/>
        }
        if (clientLower === 'cutler') {
            if (iconType.toLowerCase() === 'system-error') return <CutlerSystemErrorIcon/>
        }
        if (clientLower === 'constellation') {
            if (iconType.toLowerCase() === 'confirm1') return <ConstellationCalendarIcon />
            if (iconType.toLowerCase() === 'confirm2-3') return <ConstellationPhone />
            if (iconType.toLowerCase() === 'confirm4') return <ConstellationHeadset />
            if (iconType.toLowerCase() === 'denied') return <ConstellationDenied />
            if (iconType.toLowerCase() === 'replacement') return <ConstellationReplacement />
            if (iconType.toLowerCase() === 'customer') return <ConstellationCustomer />
            if (iconType.toLowerCase() === 'confirmsavedclaim') return <ConstellationClaimSaved />
            if (iconType.toLowerCase() === 'chatcomplete') return <ConstellationChatEndIcon />
            if (iconType.toLowerCase() === 'emailsent') return <ConstellationEmailSentIcon />
            if (iconType.toLowerCase() === 'system-error') return <ConstellationSystemErrorIcon />
        }

        // FALLTHROUGH IF CLIENT NOT DEFINED OR NO MATCH FOUND
        if (iconType.toLowerCase() === 'confirm1') return <CalendarIcon/>
        if (iconType.toLowerCase() === 'confirm2-3') return <Phone/>
        if (iconType.toLowerCase() === 'confirm4') return <Headset/>
        if (iconType.toLowerCase() === 'denied') return <Denied/>
        if (iconType.toLowerCase() === 'replacement') return <Replacement/>
        if (iconType.toLowerCase() === 'customer') return <Customer />
        if (iconType.toLowerCase() === 'confirmsavedclaim') return <ClaimSaved />
        if (iconType.toLowerCase() === 'chatcomplete') return <ChatEndIcon />
        if (iconType.toLowerCase() === 'emailsent') return <EmailSentIcon />
        if (iconType.toLowerCase() === 'system-error') return <SystemErrorIcon/>
    
        // FALLTHROUGH IF NO MATCH ON CLIENT OR TYPE.
        return null;
    }
    
    return(
        <div className={styles.variableIconWrapper}>
            {getIcon()}
        </div>
    )
}

export default BrandedIcon;