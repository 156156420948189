import React, { Component, useEffect, useContext } from 'react';
import { MasterPlanLookup } from '../components/PlanSearch/MasterPlanLookup';
import DocumentTitle from 'react-document-title';
import { ContractContext } from '../components/contexts/ContractContext';
import { getMetaDataByClient } from '../App';
import { PlanLookup } from '../components/PlanSearch/PlanLookup';


export const PlanSearch = ({ history }) => {
    const context = useContext(ContractContext);

    //UI team, you are free to use getClientId to get the value
    const getClientId = () => {
        let clientId
        if (context.data) {
            clientId = context.data.contract.clientId
        }
        return clientId
    }

    useEffect(() => {
        let clientId
        if (context.data) {
            clientId = context.data.contract.clientId
        }

        const pageTitle = getMetaDataByClient(clientId || 'AIG', "Plan Search").title
        document.title = pageTitle
    })
    return (
        <>
            {context.data?.clientId?.toLowerCase() === 'cutler' || context.data?.clientId?.toLowerCase() === 'constellation' /* TODO: make this condition depend on a backdoor variable */
                ? <PlanLookup history={history} />
                : <MasterPlanLookup history={history} />
            }
        </>
    );
}