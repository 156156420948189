/**
 * USE THIS NEW BRAND TEMPLATE TO CREATE NEW BRANDS.
 * COPY AND PASTE INTO 'BRANDS' OBJECT BELOW, THEN 
 * UPDATE ANY NEEDED VALUES BASED ON STYLE GUIDE.
 */

const newAccountTemplate = {
    accoumntName: {
        colors: {
            primaryBrand: null,
            secondaryBrand: null,
            primaryText: null,
            focus: null,
            error: null,
            neutral1: '#f5f5f5',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#dee9f6',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#b9c6d4',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#5d738d',        // DO NOT ALLOW CUSTOMIZATION
            neutral5: '#616160',        // DO NOT ALLOW CUSTOMIZATION
        },
        layout: {
            maxWidth: null,
        },
        type: {
            textFont: null,
            fontSize: null,
            lineHeight: null,
            linkColor: null,
            iconColor: null,
            letterSpacing: null,
        },
        h: {
            headingFont: null,
            headingFontWeight: null,
            headingColor: null,
            h1Size: null,
            h1LineHeight: null,
            h1Weight: null,
            h2Size: null,
            h2LineHeight: null,
            h3Size: null,
            h3LineHeight: null,
            h4Size: null,
            h4LineHeight: null,
            h5Size: null,
            h5LineHeight: null,
            h5Weight: null,
            h6Size: null,
            h6LineHeight: null,
            h6Weight: null,
        },
        btn: {
            btnAndInputFontSize: null,
            btnAndInputActiveBorder: null,
            borderRadius: null,
            padding: null,
            lineHeight: null,
            primaryBg: null,
            primaryText: null,
            primaryBorder: null,
            primaryHoverBg: null,
            primaryHoverBorder: null,
            primaryHoverText: null,
            secondaryBg: null,
            secondaryBorder: null,
            secondaryText: null,
            secondaryHoverBg: null,
            secondaryHoverBorder: null,
            secondaryHoverText: null,
            linkText: null,
            disabledBg: null,
            disabledBorder: null,
            disabledText: null,
        },
        input: {
            labelColor: null,
            labelWeight: null,
            labelLineHeight: null,
            labelMarginBottom: null,
            requiredAsterisk: null,
            placeholder: null,
            border: null,
            borderRadius: null,
            lineHeight: null,
            entered: null,
            focusBorder: null,
            errorColor: null,
            errorBg: null,
            disabledBg: null,
            disabledText: null,
            disabledBorder: null,
            icon: null,
            iconHeight: null,
            iconWidth: null,
        },
        miscInput: {
            toggleColor: null,

            checkboxBorder: null,
            checkboxHeight: null,
            checkboxWidth: null,
            checkboxCheckedBg: null,
            checkboxCheckColor: null,

            dropdownArrowColor: null,
            dropdownOptionHoverBg: null,

            radioAsButtonBorder: null,
            radioAsButtonBg: null,
            radioAsButtonText: null,
            radioAsButtonCheckedBorder: null,
            radioAsButtonCheckedBg: null,
            radioAsButtonCheckedText: null,
        },
        table: {
            rowBg: null,
            border: null,
        },
        tabs: {
            activeTabColor: null,
            activeTabText: null,
            categoryTabColor: null,
        },
        hero: {
            bg: null,
            heading: null,
            text: null,
        },
        notifications: {
            headerBg: null,
            headerText: null,
            bg: null,
            border: null,
            text: null,
            link: null,
        },
        progress: {
            active: null,
            activeText: null,
            statusBg: null,
            statusBorder: null,
            statusActiveBg: null,
            statusActiveCheck: null,
        }
    },
}

export const accounts = {

    // AIG Values are defined with variable definitions. useWhiteLabelHook returns before overrides if brand === 'AIG'
    aig: null,
    hwa: {
        colors: {
            primaryBrand: '#00849B',
            secondaryBrand: '#f47321',
            primaryText: '#666',
            focus: null,
            error: null,
            neutral1: '#f7f7f7',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: null,        // DO NOT ALLOW CUSTOMIZATION
            neutral3: null,        // DO NOT ALLOW CUSTOMIZATION
            neutral4: null,        // DO NOT ALLOW CUSTOMIZATION
            neutral5: null,        // DO NOT ALLOW CUSTOMIZATION
        },
        layout: {
            maxWidth: '75%',
        },
        type: {
            textFont: '"hwa_roboto", Arial, Helvetica, sans-serif',
            fontSize: null,
            lineHeight: null,
            linkColor: '#1352de',
            iconColor: 'var(--PrimaryBrandColor)',
            letterSpacing: null,
        },
        h: {
            headingFont: null,
            headingFontWeight: '300',
            headingColor: 'var(--PrimaryBrandColor)',
            h1Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173)',
            h1LineHeight: null,
            h1Weight: '300',
            h2Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173)',
            h2LineHeight: null,
            h2Weight: '300',
            h3Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173)',
            h3LineHeight: null,
            h4Size: 'calc(1.125rem * 1.173 * 1.173)',
            h4LineHeight: null,
            h5Size: 'calc(1.125rem * 1.173)',
            h5LineHeight: null,
            h5Weight: null,
            h6Size: '1.125rem',
            h6LineHeight: null,
            h6Weight: null,
        },
        btn: {
            btnAndInputFontSize: null,
            btnAndInputActiveBorder: null,
            borderRadius: '.3rem',
            padding: null,
            lineHeight: null,
            primaryBg: 'var(--SecondaryBrandColor)',
            primaryText: 'white',
            primaryBorder: '1px solid var(--SecondaryBrandColor)',
            primaryHoverBg: '#c05124',
            primaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            primaryHoverText: 'white',
            secondaryBg: 'white',
            secondaryBorder: '2px solid var(--Color__Neutral3)',
            secondaryText: 'var(--PrimaryBrandColor)',
            secondaryHoverBg: '#6c757d',
            secondaryHoverBorder: '1px solid #6c757d',
            secondaryHoverText: 'white',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            disabledText: 'var(--Color__Neutral4)',
        },
        input: {
            labelColor: 'var(--PrimaryTextColor)',
            labelWeight: null,
            labelLineHeight: null,
            labelMarginBottom: null,
            requiredAsterisk: null,
            placeholder: null,
            border: null,
            borderRadius: null,
            lineHeight: null,
            entered: null,
            focusBorder: null,
            errorColor: null,
            errorBg: null,
            disabledBg: null,
            disabledText: null,
            disabledBorder: null,
            icon: null,
            iconHeight: null,
            iconWidth: null,
        },
        miscInput: {
            toggleColor: null,

            checkboxBorder: null,
            checkboxHeight: null,
            checkboxWidth: null,
            checkboxCheckedBg: '#d8d8d8',
            checkboxCheckColor: 'var(--PrimaryBrandColor)',

            dropdownArrowColor: null,
            dropdownOptionHoverBg: 'var(--PrimaryBrandColor)',

            radioAsButtonBorder: '1px solid var(--Color__Neutral3)',
            radioAsButtonBg: 'white',
            radioAsButtonText: 'var(--PrimaryTextColor)',
            radioAsButtonCheckedBorder: 'var(--PrimaryBrandColor)',
            radioAsButtonCheckedBg: 'var(--PrimaryBrandColor)',
            radioAsButtonCheckedText: 'white',
        },
        table: {
            rowBg: 'var(--Color__Neutral1)',
            border: '1px solid var(--Color__Neutral2)',
        },
        tabs: {
            activeTabColor: ' var(--PrimaryBrandColor)',
            activeTabText: 'var(--PrimaryBrandColor)',
            categoryTabColor: 'var(--PrimaryBrandColor)',
        },
        hero: {
            bg: 'white',
            heading: 'var(--PrimaryBrandColor)',
            text: 'var(--PrimaryTextColor)',
        },
        notifications: {
            headerBg: 'var(--Color__Neutral1)',
            headerText: 'var(--PrimaryTextColor)',
            bg: 'var(--Color__Neutral1)',
            border: '1px solid var(--Color__Neutral2)',
            text: 'var(--PrimaryTextColor)',
            link: 'var(--LinkColor)',
        },
        progress: {
            active: 'var(--PrimaryBrandColor)',
            activeText: 'white',
            statusBg: 'transparent',
            statusBorder: '1px solid var(--PrimaryBrandColor)',
            statusActiveBg: 'var(--PrimaryBrandColor)',
            statusActiveCheck: 'white',
        }
    },
    et: {
        colors: {
            primaryBrand: '#00AEEF',
            secondaryBrand: '#102247',
            primaryText: '#212529',
            focus: null,
            error: null,
            neutral1: '#EDF4F7',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#F5F5F5',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: null,        // DO NOT ALLOW CUSTOMIZATION
            neutral4: null,        // DO NOT ALLOW CUSTOMIZATION
            neutral5: null,        // DO NOT ALLOW CUSTOMIZATION
        },
        layout: {
            maxWidth: '1140px',
        },
        type: {
            textFont: '"aig_sanssourcepro", Arial, Helvetica, sans-serif',
            fontSize: null,
            lineHeight: null,
            linkColor: '#007BFF',
            iconColor: '#000000',
            letterSpacing: null,
        },
        h: {
            headingFont: null,
            headingFontWeight: 600,
            headingColor: 'var(--SecondaryBrandColor)',
            h1Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173 * 1.173)',
            h1LineHeight: null,
            h1Weight: 700,
            h2Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173)',
            h2LineHeight: null,
            h3Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173)',
            h3LineHeight: null,
            h4Size: 'calc(1.125rem * 1.173 * 1.173)',
            h4LineHeight: null,
            h5Size: 'calc(1.125rem * 1.173)',
            h5LineHeight: null,
            h5Weight: null,
            h6Size: 'calc(1.125rem)',
            h6LineHeight: null,
            h6Weight: null,
        },
        btn: {
            btnAndInputFontSize: null,
            btnAndInputActiveBorder: null,
            borderRadius: '0',
            padding: null,
            lineHeight: null,
            primaryBg: '#C80000',
            primaryText: 'white',
            primaryBorder: '1px solid #C80000',
            primaryHoverBg: 'white',
            primaryHoverBorder: '1px solid var(--PrimaryBrandColor)',
            primaryHoverText: 'var(--PrimaryBrandColor)',
            secondaryBg: 'white',
            secondaryBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryText: 'var(--SecondaryBrandColor)',
            secondaryHoverBg: 'white',
            secondaryHoverBorder: '1px solid var(--PrimaryBrandColor)',
            secondaryHoverText: 'var(--PrimaryBrandColor)',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3',
            disabledText: 'var(--Color__Neutral4)',
        },
        input: {
            labelColor: '#000000',
            labelWeight: null,
            labelLineHeight: null,
            labelMarginBottom: null,
            requiredAsterisk: null,
            placeholder: null,
            border: null,
            borderRadius: null,
            lineHeight: null,
            entered: null,
            focusBorder: null,
            errorColor: null,
            errorBg: null,
            disabledBg: null,
            disabledText: null,
            disabledBorder: null,
            icon: null,
            iconHeight: null,
            iconWidth: null,
        },
        miscInput: {
            toggleColor: null,

            checkboxBorder: null,
            checkboxHeight: null,
            checkboxWidth: null,
            checkboxCheckedBg: 'var(--PrimaryBrandColor)',
            checkboxCheckColor: 'var(--SecondaryBrandColor)',

            dropdownArrowColor: null,
            dropdownOptionHoverBg: 'var(--PrimaryBrandColor)',

            radioAsButtonBorder: '1px solid var(--Color__Neutral3)',
            radioAsButtonBg: 'white',
            radioAsButtonText: 'var(--Heading__Color)',
            radioAsButtonCheckedBorder: 'var(--PrimaryBrandColor)',
            radioAsButtonCheckedBg: 'var(--PrimaryBrandColor)',
            radioAsButtonCheckedText: 'var(--SecondaryBrandColor)',
        },
        table: {
            rowBg: 'var(--Color__Neutral2)',
            border: '1px solid var(--Color__Neutral2)',
        },
        tabs: {
            activeTabColor: 'var(--PrimaryBrandColor)',
            activeTabText: 'var(--SecondaryBrandColor)',
            categoryTabColor: 'var(--SecondaryBrandColor)',
        },
        hero: {
            bg: 'linear-gradient(180deg,#102247,#00AEEF)',
            heading: 'white',
            text: 'white',
        },
        notifications: {
            headerBg: 'white',
            headerText: 'var(--PrimaryTextColor)',
            bg: 'white',
            border: '1px solid #979797',
            text: 'var(--PrimaryTextColor)',
            link: 'var(--LinkColor)',
        },
        progress: {
            active: 'var(--SecondaryBrandColor)',
            activeText: 'White',
            statusBg: 'transparent',
            statusBorder: '1px solid var(--PrimaryBrandColor)',
            statusActiveBg: 'var(--SecondaryBrandColor)',
            statusActiveCheck: 'white',
        }
    },
    cutler: {
        colors:{
            primaryBrand: '#235191',
            secondaryBrand: '#F26722',
            primaryText: '#3B3B3B',
            focus: '#00a4e4',
            error: '#ec1944',
            neutral1: '#F2F2F2',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#E7E7E7',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#DCDCDC',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#97989A',        // DO NOT ALLOW CUSTOMIZATION
            neutral5: '#616160',        // DO NOT ALLOW CUSTOMIZATION
        },
        layout: {
            maxWidth: '1140px',
        },
        type: {
            textFont: '"aig_sanssourcepro", Arial, Helvetica, sans-serif',
            fontSize: '16px',
            lineHeight: '24px',
            linkColor: 'var(--PrimaryBrandColor)',
            iconColor: 'var(--PrimaryBrandColor)',
            letterSpacing: '0.5px',
        },
        h: {
            headingFont: 'var(--TextFont)',
            headingFontWeight: 600,
            headingColor: 'var(--PrimaryTextColor)',
            h1Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173 * 1.173)',
            h1LineHeight: '52px',
            h1Weight: 700,
            h2Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173)',
            h2LineHeight: '34px',
            h3Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173)',
            h3LineHeight: '34px',
            h4Size: 'calc(1.125rem * 1.173 * 1.173)',
            h4LineHeight: '32px',
            h5Size: 'calc(1.125rem * 1.73)',
            h5LineHeight: '24px',
            // h5Weight: null,
            h6Size: 'calc(1.125rem)',
            h6LineHeight: '24px',
            // h6Weight: null,
        },
        btn: {
            btnAndInputFontSize: '1rem',
            btnAndInputActiveBorder: '1px solid var(--FocusColor)',
            borderRadius: '6px',
            padding: '15px 40px',
            lineHeight: 1.15,
            primaryBg: 'var(--PrimaryBrandColor)',
            primaryText: 'white',
            primaryBorder: '1px solid var(--PrimaryBrandColor)',
            primaryHoverBg: 'var(--SecondaryBrandColor)',
            primaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            primaryHoverText: 'white',
            secondaryBg: 'white',
            secondaryBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryText: 'var(--SecondaryBrandColor)',
            secondaryHoverBg: 'var(--SecondaryBrandColor)',
            secondaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryHoverText: 'white',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            disabledText: '#5D738D',
        },
        input: {
            labelColor: 'var(--PrimaryBrandColor)',
            labelWeight: 600,
            labelLineHeight: 1.5,
            labelMarginBottom: '8px',
            requiredAsterisk: 'var(--ErrorColor)',
            placeholder: 'var(--Color__Neutral3)',
            border: '1px solid var(--Color__Neutral3)',
            borderRadius: 'var(--Btn__BorderRadius)',
            lineHeight: '24px',
            entered: 'var(--PrimaryTextColor)',
            focusBorder: '2px solid var(--FocusColor)',
            errorColor: 'var(--ErrorColor)',
            errorBg: '#fefbfb',
            disabledBg: 'var(--Color__Neutral1)',
            disabledText: 'var(--Color__Neutral4)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            icon: 'var(--IconColor)',
            iconHeight: '28px',
            iconWidth: '28px',
        },
        miscInput: {
            toggleColor: 'var(--SecondaryBrandColor',

            checkboxBorder: '1px solid var(--Color__Neutral3)',
            checkboxHeight: '24px',
            checkboxWidth: '24px',
            checkboxCheckedBg: 'var(--SecondaryBrandColor)',
            checkboxCheckColor: 'white',

            dropdownArrowColor: '000000',
            dropdownOptionHoverBg: 'var(--SecondaryBrandColor)',

            radioAsButtonBorder: '1px solid var(--Color__Neutral3)',
            radioAsButtonBg: 'white',
            radioAsButtonText: 'var(--Heading__Color)',
            radioAsButtonCheckedBorder: 'var(--Input__Border)',
            radioAsButtonCheckedBg: 'var(--PrimaryBrandColor)',
            radioAsButtonCheckedText: 'white',
        },
        table: {
            rowBg: 'var(--Color__Neutral2)',
            border: '1px solid var(--Color__Neutral2)',
        },
        tabs: {
            activeTabColor: 'var(--SecondaryBrandColor)',
            activeTabText: 'var(--PrimaryTextColor)',
            categoryTabColor: 'var(--PrimaryBrandColor)',
        },
        hero: {
            bg: 'white',
            heading: 'white',
            text: 'white',
        },
        notifications: {
            headerBg: 'white',
            headerText: 'var(--PrimaryTextColor)',
            bg: 'white',
            border: '1px solid #979797',
            text: 'var(--PrimaryTextColor)',
            link: 'var(--PrimaryBrandColor)',
        },
        progress: {
            active: 'var(--PrimaryBrandColor)',
            activeText: 'White',
            statusBg: 'transparent',
            statusBorder: '1px solid var(--PrimaryBrandColor)',
            statusActiveBg: 'var(--PrimaryBrandColor)',
            statusActiveCheck: 'white',
        }
    },
    southbridge: {
        colors: {
            primaryBrand: '#2D387C',
            secondaryBrand: '#4F74E3',
            primaryText: '#1F2830',
            focus: '#00a4e4',
            error: '#ec1944',
            neutral1: '#F2F2F2',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#E7E7E7',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#D0D0D0',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#B1B1B1',        // DO NOT ALLOW CUSTOMIZATION
            neutral5: '#616160',        // DO NOT ALLOW CUSTOMIZATION
        },
        layout: {
            maxWidth: '1140px',
        },
        type: {
            textFont: '"aig_sanssourcepro", Arial, Helvetica, sans-serif',
            fontSize: '16px',
            lineHeight: '24px',
            linkColor: '#1352DE',
            iconColor: 'var(--SecondaryBrandColor)',
            letterSpacing: '0.5px',
        },
        h: {
            headingFont: 'var(--TextFont)',
            headingFontWeight: 400,
            headingColor: 'var(--PrimaryTextColor)',
            h1Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173 * 1.173)',
            h1LineHeight: '52px',
            h1Weight: 400,
            h2Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173)',
            h2LineHeight: '34px',
            h3Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173)',
            h3LineHeight: '34px',
            h4Size: 'calc(1.125rem * 1.173 * 1.173)',
            h4LineHeight: '32px',
            h5Size: 'calc(1.125rem * 1.73)',
            h5LineHeight: '24px',
            // h5Weight: null,
            h6Size: 'calc(1.125rem)',
            h6LineHeight: '24px',
            // h6Weight: null,
        },
        btn: {
            btnAndInputFontSize: '1rem',
            btnAndInputActiveBorder: '1px solid var(--FocusColor)',
            borderRadius: '2px',
            padding: '15px 40px',
            lineHeight: 1.15,
            primaryBg: 'var(--PrimaryBrandColor)',
            primaryText: 'white',
            primaryBorder: '1px solid var(--PrimaryBrandColor)',
            primaryHoverBg: 'var(--SecondaryBrandColor)',
            primaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            primaryHoverText: 'white',
            secondaryBg: 'white',
            secondaryBorder: 'var(--Input__Border)',
            secondaryText: 'var(--PrimaryBrandColor)',
            secondaryHoverBg: 'var(--SecondaryBrandColor)',
            secondaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryHoverText: 'white',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3',
            disabledText: '#5D738D',
        },
        input: {
            labelColor: 'var(--primaryText)',
            labelWeight: null,
            labelLineHeight: 1.5,
            labelMarginBottom: '8px',
            requiredAsterisk: 'var(--ErrorColor)',
            placeholder: 'var(--Color__Neutral3)',
            border: '1px solid var(--Color__Neutral3)',
            borderRadius: 'var(--Btn__BorderRadius)',
            lineHeight: '24px',
            entered: 'var(--PrimaryTextColor)',
            focusBorder: '2px solid var(--FocusColor)',
            errorColor: 'var(--ErrorColor)',
            errorBg: '#fff',
            disabledBg: 'var(--Color__Neutral1)',
            disabledText: '#5D738D',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            icon: 'var(--IconColor)',
            iconHeight: '28px',
            iconWidth: '28px',
        },
        miscInput: {
            toggleColor: 'var(--SecondaryBrandColor',

            checkboxBorder: 'var(--Input__Border)',
            checkboxHeight: '24px',
            checkboxWidth: '24px',
            checkboxCheckedBg: 'var(--SecondaryBrandColor)',
            checkboxCheckColor: 'white',

            dropdownArrowColor: '#00000',
            dropdownOptionHoverBg: 'var(--SecondaryBrandColor)',

            radioAsButtonBorder: 'var(--Input__Border)',
            radioAsButtonBg: 'white',
            radioAsButtonText: 'var(--Heading__Color)',
            radioAsButtonCheckedBorder: 'var(--SecondaryBrandColor)',
            radioAsButtonCheckedBg: 'var(--Color__Neutral1)',
            radioAsButtonCheckedText: 'var(--PrimaryTextColor)',
        },
        table: {
            rowBg: 'var(--Color__Neutral1)',
            border: '1px solid var(--Color__Neutral2)',
        },
        tabs: {
            activeTabColor: 'var(--PrimaryBrandColor)',
            activeTabText: 'var(--PrimaryBrandColor)',
            categoryTabColor: 'var(--PrimaryBrandColor)',
        },
        hero: {
            bg: 'white',
            heading: 'white',
            text: 'white',
        },
        notifications: {
            headerBg: 'white',
            headerText: 'var(--primaryText)',
            bg: 'white',
            border: '1px solid #979797',
            text: 'var(--primaryText)',
            link: 'var(--LinkColor)',
        },
        progress: {
            active: 'var(--SecondaryBrandColor)',
            activeText: 'White',
            statusBg: 'transparent',
            statusBorder: '1px solid var(--PrimaryBrandColor)',
            statusActiveBg: 'var(--SecondaryBrandColor)',
            statusActiveCheck: 'white',
        }
    },
    constellation: {
        colors: {
            primaryBrand: '#2372B9',
            secondaryBrand: '#F47B27',
            primaryText: '#333333',
            focus: '#00a4e4',
            error: '#ec1944',
            neutral1: '#F5F5F5',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#E7E7E7',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#DCDCDC',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#5D738D',        // DO NOT ALLOW CUSTOMIZATION
            neutral5: '#616160',        // DO NOT ALLOW CUSTOMIZATION
        },
        layout: {
            maxWidth: '1140px',
        },
        type: {
            textFont: '"aig_sanssourcepro", Arial, Helvetica, sans-serif',
            fontSize: '16px',
            lineHeight: '24px',
            linkColor: 'var(--PrimaryBrandColor)',
            iconColor: 'var(--PrimaryBrandColor)',
            letterSpacing: '0.5px',
        },
        h: {
            headingFont: 'var(--TextFont)',
            headingFontWeight: 600,
            headingColor: 'var(--PrimaryBrandColor)',
            h1Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173 * 1.173)',
            h1LineHeight: '52px',
            h1Weight: 700,
            h2Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173)',
            h2LineHeight: '34px',
            h3Size: 'calc(1.125rem * 1.173 * 1.173 * 1.173)',
            h3LineHeight: '34px',
            h4Size: 'calc(1.125rem * 1.173 * 1.173)',
            h4LineHeight: '32px',
            h5Size: 'calc(1.125rem * 1.73)',
            h5LineHeight: '24px',
            // h5Weight: null,
            h6Size: 'calc(1.125rem)',
            h6LineHeight: '24px',
            // h6Weight: null,
        },
        btn: {
            btnAndInputFontSize: '1rem',
            btnAndInputActiveBorder: '1px solid var(--FocusColor)',
            borderRadius: '0',
            padding: '15px 40px',
            lineHeight: 1.15,
            primaryBg: 'var(--PrimaryBrandColor)',
            primaryText: '#ffffff',
            primaryBorder: '1px solid var(--PrimaryBrandColor)',
            primaryHoverBg: 'var(--SecondaryBrandColor)',
            primaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            primaryHoverText: '#ffffff',
            secondaryBg: '#ffffff',
            secondaryBorder: 'var(--Input__Border)',
            secondaryText: 'var(--PrimaryBrandColor)',
            secondaryHoverBg: 'var(--SecondaryBrandColor)',
            secondaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryHoverText: '#ffffff',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            disabledText: 'var(--Color__Neutral4)',
        },
        input: {
            labelColor: 'var(--PrimaryBrandColor)',
            labelWeight: 600,
            labelLineHeight: 1.5,
            labelMarginBottom: '8px',
            requiredAsterisk: 'var(--ErrorColor)',
            placeholder: 'var(--Color__Neutral3)',
            border: '1px solid var(--Color__Neutral3)',
            borderRadius: 'var(--Btn__BorderRadius)',
            lineHeight: '24px',
            entered: 'var(--PrimaryTextColor)',
            focusBorder: '2px solid var(--FocusColor)',
            errorColor: 'var(--ErrorColor)',
            errorBg: '#ffffff',
            disabledBg: 'var(--Color__Neutral1)',
            disabledText: 'var(--Color__Neutral4)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            icon: 'var(--IconColor)',
            iconHeight: '28px',
            iconWidth: '28px',
        },
        miscInput: {
            toggleColor: 'var(--SecondaryBrandColor)',

            checkboxBorder: 'var(--Input__Border)',
            checkboxHeight: '24px',
            checkboxWidth: '24px',
            checkboxCheckedBg: 'var(--PrimaryBrandColor)',
            checkboxCheckColor: '#ffffff',

            dropdownArrowColor: '#000000',
            dropdownOptionHoverBg: 'var(--SecondaryBrandColor)',

            radioAsButtonBorder: 'var(--Input__Border)',
            radioAsButtonBg: '#ffffff',
            radioAsButtonText: 'var(--Heading__Color)',
            radioAsButtonCheckedBorder: 'var(--PrimaryBrandColor)',
            radioAsButtonCheckedBg: 'var(--Color__Neutral1)',
            radioAsButtonCheckedText: 'var(--Heading__Color)',
        },
        table: {
            rowBg: 'var(--Color__Neutral1)',
            border: '1px solid var(--Color__Neutral2)',
        },
        tabs: {
            activeTabColor: 'var(--PrimaryBrandColor)',
            activeTabText: 'var(--Heading__Color)',
            categoryTabColor: 'var(--PrimaryTextColor)',
        },
        hero: {
            bg: 'var(--PrimaryBrandColor)',
            heading: '#ffffff',
            text: '#ffffff',
        },
        notifications: {
            headerBg: '#12395E',
            headerText: '#ffffff',
            bg: '#ffffff',
            border: '#12395E',
            text: 'var(--PrimaryTextColor)',
            link: 'var(--LinkColor)',
        },
        progress: {
            active: 'var(--PrimaryBrandColor)',
            activeText: '#ffffff',
            statusBg: 'transparent',
            statusBorder: '1px solid var(--PrimaryBrandColor)',
            statusActiveBg: 'var(--PrimaryBrandColor)',
            statusActiveCheck: '#ffffff',
        }
    },
    otherBrand2: {
        // ...
    },
    // ...More Brands
}