import React, { useState, useRef, useEffect } from 'react';
import { useContext } from 'react';
import { CardBody, Row, Col, Container, FormGroup } from 'reactstrap';
import { SpecialNotesToServicer } from '.';
import { RecommendServicer }  from './RecommendServicer';
import AIGFormInputField from '../../shared/AIGFormInputField';
import ScriptBox from '../../shared/ScriptBox/ScriptBox';
import { ApptContext } from './AppointmentContext';
import Cleave from 'cleave.js/react';
import SimpleReactValidator from 'simple-react-validator'
import styles from './AppointmentSelect.module.css';
import getClientContent from '../../_BrandingProvider/brandContent.jsx';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-i-icon.svg';
import { ContractContext } from '../../contexts/ContractContext';
import { dataLayerPush, pushHistory, paths } from '../../../helpers'
import classNames from 'classnames';
import CancelModal from '../../modals/CancelModal';
import SaveModal from '../../modals/SaveModal';

const AltOptionButton = ({ label, buttonText, altOption, classNames, isAriaChecked, onSelect }) => {
    return (
        <div className={`radio ${classNames}`} role="radio" tabIndex="0" ariaChecked={isAriaChecked}>
            <input type="radio" ariaLabeledBy={label} name="serviceOptions" id={altOption} onChange={() => onSelect(altOption)} />
            <label className="radio-controls-label m-0 w-100" for={altOption}>{buttonText}</label>
        </div>
    )
}

export const NoServicers = (props) => {
    const [altOption, setAltOption] = useState('');
    const { state, dispatch } = useContext(ApptContext);
    const context = useContext(ContractContext);
    const { data: { contract: { clientId } } } = context;
    const [ history, setHistory ] = useState(props.history);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ isSaveForLater, setIsSaveForLater ] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [partyModel, setPartyModel] = useState(null);


    useEffect(() => {
        dataLayerPush('/suggestservicer', 'Recommend a Servicer')
    })

    useEffect(() => {

        if (props.showTimeoutModal) {
            window.removeEventListener('beforeunload', keepOnPage);
        }
        else {
            window.addEventListener('beforeunload', keepOnPage);
        }
        return () => {
            window.removeEventListener('beforeunload', keepOnPage);
        }

    }, [props.showTimeoutModal])

    useEffect(() => {
        //dispatch({type: 'SELECT_APPOINTMENT', appointment: getFirstAvailable(props.appts)});
        async function getParty() {
            const res = await fetch(`api/contract/${context.data?.contract?.contractId}/${clientId}`);
            const data = await res.json();
            const party = data.party;
            setPartyModel(party);
        }

        if (partyModel == null) {
            getParty()
        }
    }, [])


    const handleReimburseToggle = () => dispatch({ type: 'TOGGLE_REIMBURSE_TERMS' })

    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ':attribute is required.'
        },
    }));

    const {
        acceptReimbursementTerms: {
            accepted
        },
        suggestedServicer: {
            name,
            phone
        },
        noServicerAlternative,
        noServicersFound,
        notesForServicer
    } = state;

    const handleContinueWithOption = async () => {
        //if (!altOption) return;
        //dispatch({
        //    type: 'SET_ALT_OPTION',
        //    altOpt: altOption,
        //})

        /**
         * ============= WIRE UP: =================
         * This is the "Continue" button. 
         * Add some logic here for when the user "continues" after adding details.
         * Submit data from "state" (see AppointmentContext.jsx for state structure).
         * Reroute to Various confirmation screens.
         * 
         */
        await handleRecommendedServicer();
    }

    const handleClearSelection = () => {
        setAltOption('');
        dispatch({
            type: 'SET_ALT_OPTION',
            altOpt: '',
        })
    }

    const handleRecommendedServicer = async () => {
        const eventNumber = props?.result?.eventModel[0]?.eventNumber ??
            (props?.history?.location?.appointmentProps?.claim?.eventNumberToResume?.toString() ?? props?.claim?.eventNumberToResume?.toString())

        const body = {
            claimId: props?.claim?.claimId,
            alternativeType: "recommend",
            servicerName: state.suggestedServicer.name,
            servicerPhone: state.suggestedServicer.phone,
            specialInstructions: state.notesForServicer,
            eventNumber
        }
        const noServicerSettings = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(`/api/dispatchservice/appointments/noServicer/${props?.clientId}`, noServicerSettings);
        const data = await response.json();

        if (data.result === true) {
            pushHistory(props, paths.fileClaimConfirmation, { statusType: 'type4', appointmentProps: body }, props.history);
        }
    }

    const keepOnPage = e => {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';
        saveForLaterAction(props?.claim, true);
    }

    const onSaveForLaterAction = e => {
        e.preventDefault();
        setShowConfirmation(true);
        saveForLaterAction(props?.claim, false);
    }

    const saveForLater = (e) => {
        e && e.preventDefault();
        setIsSaveForLater(true);
        saveForLaterAction(props?.claim, false);
    }

    const onSaveCancel = e => {
        e.preventDefault();
        setShowConfirmation(false);
    }

    const saveForLaterAction = (claim, claimAbandoned) => {
        console.log(claim);
        if (!claim) { return; }
        setUploading(true);

        const model = {claimModel: claim, partyModel: partyModel}

        fetch(`api/claim/SaveForLater/${claim.claimId}/4/${claimAbandoned}/${clientId}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        })
            .then(data => {
                if (data.status === 200 && !claimAbandoned) {
                    pushHistory(props, paths.fileClaimConfirmation, { statusType: 'savedClaim' }, props);
                }

                setUploading(false);
            });
    }

    const disableBtn = noServicersFound ? false : !altOption
        || (altOption === "hire" && !accepted)
        && !altOption;

    return (

        <Container>
            <div className={styles.serviceOptions}>
                {noServicersFound && (
                    <Col lg="8" className={styles.recommendedServicerForm}>
                        <legend className={styles.intialText}>{getClientContent(clientId).variableVoice.scheduling.type4}</legend>
                        <legend className={styles.intialText}>{getClientContent(clientId).variableVoice.scheduling.recommendServicer}</legend>
                        <RecommendServicer  />
                    </Col>
                )}

                {noServicerAlternative && (
                    <>
                        <legend aria-label="Select an service option from the list" className="text-center mb-4 col-lg-7">We were unable to quickly locate a servicer in your area.
                            You have a couple of options for service, which do you prefer?
                        </legend>
                        <Col xs="12" md="6" lg="3">
                            <div className={styles.newRadioBtn} role="radio" tabIndex="0" aria-checked={altOption === "hire" ? "true" : "false"}>
                                <input type="radio" aria-labelledby="own servicer" name="servicerOptions" onClick={() => setAltOption('hire')} id="hireOwnServicerOption" />
                                <label className="radio-controls-label m-0 w-100" for="hireOwnServicerOption">
                                    Hire my own servicer
                                    <span className={styles.ownServInfo}>
                                        <InfoIcon className={noServicerAlternative === "hire" && styles.infoIcon} />
                                        <span className={styles.infoText}>You can hire your own servicer and submit the invoice to collect reimbursement (exclusions apply see <a>terms and conditions</a>).<br /><br />You will receive an email with instructions on how to submit the invoice for reimbursement.</span>
                                    </span>
                                </label>
                            </div>
                            <div className={styles.newRadioBtn} role="radio" tabIndex="0" aria-checked={altOption === "recommend" ? "true" : "false"}>
                                <input type="radio" aria-labelledby="recommend a servicer" name="servicerOptions" onClick={() => setAltOption('recommend')} id="recommendServicerOption" />
                                <label className="radio-controls-label m-0 w-100" for="recommendServicerOption">Recommend a servicer</label>
                            </div>
                            <div className={styles.newRadioBtn} role="radio" tabIndex="0" aria-checked={altOption === "find" ? "true" : "false"}>
                                <input type="radio" aria-labelledby="find servicer" name="servicerOptions" onClick={() => setAltOption('find')} id="findServicerOption" />
                                <label className="radio-controls-label m-0 w-100" for="findServicerOption">Find me a servicer</label>
                            </div>
                        </Col>

                        {altOption === "hire" && (
                            <FormGroup className="col-md-9 col-lg-7">
                                <div className="checkbox mb-0" aria-label="Affirmation checkbox is unchecked. Check box to affirm you know the terms and conditions.">
                                    <input className="" type="checkbox" value={accepted} onChange={() => handleReimburseToggle()} id="acceptReimburseTerms" />
                                    <label for="acceptReimburseTerms" className={`checkboxLabel ${styles.affirmCheckLabel}`}>I affirm that I understand not all repairs are covered by the contract.
                                        You must review the <a>terms and conditions</a> to ensure the repair is covered and eligible for reimbursement. </label>
                                </div>
                                <div className="errorMsg">Please affirm you understand repair reimbursement is based on the terms and conditions.</div>
                            </FormGroup>
                        )}
                    </>
                )}
                {noServicerAlternative === "hire" && (<div>Hire Own Servicer Confirmation Screen</div>)}
                {noServicerAlternative === "recommend" && (
                    <Col lg="8" className={styles.recommendedServicerForm}>
                        <legend className={styles.intialText}>We’re glad you have the inside scoop. Which servicer do you recommend? We’ll call you within 24 hours to schedule service.</legend>
                        <RecommendServicer />
                    </Col>
                )}
                {noServicerAlternative === "find" && (
                    <Col lg="6" className={styles.findServicerForm}>
                        <legend className={styles.intialText}>Our team will find a servicer in your area and call you within 24 hours to schedule service.</legend>
                        <Col md="8" className="p-0">
                            <SpecialNotesToServicer />
                        </Col>
                    </Col>
                )}
                {noServicerAlternative !== 'hire' && (
                    <>
                        <div className="btnWrapper mt-4">
                            <button className="btn btn-primary" onClick={() => handleContinueWithOption()} disabled={disableBtn}>Continue</button>
                        </div>

                        <Row className="stepsFormBlock mt-3">
                            {showConfirmation ?
                                <SaveModal buttonClassName={classNames("btnLink")} showConfirmation={showConfirmation} history={history} continuing={isSaveForLater}
                                    onCancel={onSaveCancel} onSaveForLaterAction={saveForLater} client={props?.clientId} />
                                : null}

                            <a href="#" className="btnLink" onClick={onSaveForLaterAction} >Save For Later</a>
                        </Row>
                    </>
                )}
            </div>
        </Container>
    )
}
