import { useEffect, useState } from 'react';
import { accounts } from './BrandValues';

const usingAIGBranding = ['aig', 'arw', 'apple', null, undefined, "cutler", "bestbuy"]

export const useWhiteLabel = (account) => {
    const accountLower = account?.toLowerCase() || 'aig'
    const [brandingComplete, setBrandingComplete] = useState(false)

    useEffect( () => {
        setBrandingComplete(false)
        // This prevents switching BACK to AIG
        if (usingAIGBranding.includes(accountLower)) {
            setBrandingComplete(true);
            return
        };

        const root = document.getElementsByTagName("html")[0];

        // deconstruct all values from selected brand
        const { colors, type, h, btn, input, miscInput, table, progress, notifications, hero, tabs, layout } = accounts[accountLower];
        // Primary Branding Values
        if (colors) {
            // console.log('doing colors')
            colors.primaryBrand && root.style.setProperty("--PrimaryBrandColor", colors.primaryBrand);
            colors.secondaryBrand && root.style.setProperty("--SecondaryBrandColor", colors.secondaryBrand);
            colors.primaryText && root.style.setProperty("--PrimaryTextColor", colors.primaryText);
            colors.focus && root.style.setProperty("--FocusColor", colors.focus);
            colors.error && root.style.setProperty("--ErrorColor", colors.error);
            colors.neutral1 && root.style.setProperty("--Color__Neutral1", colors.neutral1);
            colors.neutral2 && root.style.setProperty("--Color__Neutral2", colors.neutral2);
            colors.neutral3 && root.style.setProperty("--Color__Neutral3", colors.neutral3);
            colors.neutral4 && root.style.setProperty("--Color__Neutral4", colors.neutral4);
            colors.neutral5 && root.style.setProperty("--Color__Neutral5", colors.neutral5);
        }
        // Layout
        if (layout) {
            // console.log('doing layout')
            layout.maxWidth && root.style.setProperty("--Container__MaxWidth", layout.maxWidth);
        }
        // Typography
        if (type) {
            // console.log('doing type')
            type.textFont && root.style.setProperty("--TextFont", type.textFont);
            type.fontSize && root.style.setProperty("--FontSize", type.fontSize);
            type.lineHeight && root.style.setProperty("--LineHeight", type.lineHeight);
            type.linkColor && root.style.setProperty("--LinkColor", type.linkColor);
            type.iconColor && root.style.setProperty("--IconColor", type.iconColor);
            type.letterSpacing && root.style.setProperty("--LetterSpacing", type.letterSpacing);
        }
        // Headings
        if (h) {
            // console.log('doing h')
            h.headingFont && root.style.setProperty("--Heading__Font", h.headingFont);
            h.headingFontWeight && root.style.setProperty("--Heading__FontWeight", h.headingFontWeight);
            h.headingColor && root.style.setProperty("--Heading__Color", h.headingColor);
            h.h1Size && root.style.setProperty("--H1__Size", h.h1Size);
            h.h1LineHeight && root.style.setProperty("--H1__LineHeight", h.h1LineHeight);
            h.h1Weight && root.style.setProperty("--H1__Weight", h.h1Weight);
            h.h2Size && root.style.setProperty("--H2__Size", h.h2Size);
            h.h2LineHeight && root.style.setProperty("--H2__LineHeight", h.h2LineHeight);
            h.h3Size && root.style.setProperty("--H3__Size", h.h3Size);
            h.h3LineHeight && root.style.setProperty("--H3__LineHeight", h.h3LineHeight);
            h.h4Size && root.style.setProperty("--H4__Size", h.h4Size);
            h.h4LineHeight && root.style.setProperty("--H4__LineHeight", h.h4LineHeight);
            h.h5Size && root.style.setProperty("--H5__Size", h.h5Size);
            h.h5LineHeight && root.style.setProperty("--H5__LineHeight", h.h5LineHeight);
            h.h6Size && root.style.setProperty("--H6__Size", h.h6Size);
            h.h6LineHeight && root.style.setProperty("--H6__LineHeight", h.h6LineHeight);
        }
        if (btn) {
            // console.log('doing btn')
            btn.btnAndInputFontSize && root.style.setProperty("--BtnAndInput__FontSize", btn.btnAndInputFontSize);
            btn.btnAndInputActiveBorder && root.style.setProperty("--BtnAndInput__ActiveBorder", btn.btnAndInputActiveBorder);
            btn.borderRadius && root.style.setProperty("--Btn__BorderRadius", btn.borderRadius);
            btn.padding && root.style.setProperty("--Btn__Padding", btn.padding);
            btn.lineHeight && root.style.setProperty("--Btn__LineHeight", btn.lineHeight);
            btn.primaryBg && root.style.setProperty("--BtnPrimary__Bg", btn.primaryBg);
            btn.primaryText && root.style.setProperty("--BtnPrimary__Text", btn.primaryText);
            btn.primaryBorder && root.style.setProperty("--BtnPrimary__Border", btn.primaryBorder);
            btn.primaryHoverBg && root.style.setProperty("--BtnPrimary__HoverBg", btn.primaryHoverBg);
            btn.primaryHoverBorder && root.style.setProperty("--BtnPrimary__HoverBorder", btn.primaryHoverBorder);
            btn.primaryHoverText && root.style.setProperty("--BtnPrimary__HoverText", btn.primaryHoverText);
            btn.secondaryBg && root.style.setProperty("--BtnSecondary__Bg", btn.secondaryBg);
            btn.secondaryBorder && root.style.setProperty("--BtnSecondary__Border", btn.secondaryBorder);
            btn.secondaryText && root.style.setProperty("--BtnSecondary__Text", btn.secondaryText);
            btn.secondaryHoverBg && root.style.setProperty("--BtnSecondary__HoverBg", btn.secondaryHoverBg);
            btn.secondaryHoverBorder && root.style.setProperty("--BtnSecondary__HoverBorder", btn.secondaryHoverBorder);
            btn.secondaryHoverText && root.style.setProperty("--BtnSecondary__HoverText", btn.secondaryHoverText);
            btn.linkText && root.style.setProperty("--BtnLink__Text", btn.linkText);
            btn.disabledBg && root.style.setProperty("--BtnDisabled__Bg", btn.disabledBg);
            btn.disabledBorder && root.style.setProperty("--BtnDisabled__Border", btn.disabledBorder);
            btn.disabledText && root.style.setProperty("--BtnDisabled__Text", btn.disabledText);
        }
        if (input) {
            // console.log('doing input')
            input.labelColor && root.style.setProperty("--Input__LabelColor", input.labelColor);
            input.labelWeight && root.style.setProperty("--Input__LabelWeightinputLabelWeight", input.labelWeight);
            input.labelLineHeight && root.style.setProperty("--Input__LabelLineHeinputLabelLineHeight", input.labelLineHeight);
            input.labelMarginBottom && root.style.setProperty("--Input__LabelMarginBottom", input.labelMarginBottom);
            input.requiredAsterisk && root.style.setProperty("--Input__RequiredAsterisk", input.requiredAsterisk);
            input.placeholder && root.style.setProperty("--Input__Placeholder", input.placeholder);
            input.border && root.style.setProperty("--Input__Border", input.border);
            input.borderRadius && root.style.setProperty("--Input__BorderRadius", input.borderRadius);
            input.lineHeight && root.style.setProperty("--Input__LininputLineHeight", input.lineHeight);
            input.entered && root.style.setProperty("--Input__Entered", input.entered);
            input.focusBorder && root.style.setProperty("--InputFocus__Border", input.focusBorder);
            input.errorColor && root.style.setProperty("--InputError__Color", input.errorColor);
            input.errorBg && root.style.setProperty("--InputError__Bg", input.errorBg);
            input.disabledBg && root.style.setProperty("--InputDisabled__Bg", input.disabledBg);
            input.disabledText && root.style.setProperty("--InputDisabled__Text", input.disabledText);
            input.disabledBorder && root.style.setProperty("--InputDisabled__Border", input.disabledBorder);
            input.icon && root.style.setProperty("--Input__Icon", input.icon);
            input.iconHeight && root.style.setProperty("--Input__IconHeight", input.iconHeight);
            input.iconWidth && root.style.setProperty("--Input__IconWidth", input.iconWidth);
        }
        if (miscInput) {
            // console.log('doing misc')
            // Checkboxes
            miscInput.toggleColor && root.style.setProperty("--InputToggle__Color", miscInput.toggleColor);
            miscInput.checkboxBorder && root.style.setProperty("--Checkbox__Border", miscInput.checkboxBorder);
            miscInput.checkboxHeight && root.style.setProperty("--Checkbox__Height", miscInput.checkboxHeight);
            miscInput.checkboxWidth && root.style.setProperty("--Checkbox__Width", miscInput.checkboxWidth);
            miscInput.checkboxCheckedBg && root.style.setProperty("--Checkbox__CheckedBg", miscInput.checkboxCheckedBg);
            miscInput.checkboxCheckColor && root.style.setProperty("--Checkbox__CheckColor", miscInput.checkboxCheckColor);
            // Drobdowns
            miscInput.dropdownArrowColor && root.style.setProperty("--Drop__ArrowColor", miscInput.dropdownArrowColor);
            miscInput.dropdownOptionHoverBg && root.style.setProperty("--DropOption__HoverBg", miscInput.dropdownOptionHoverBg);
            // Radios
            miscInput.radioAsButtonBorder && root.style.setProperty("--RadioAsButton__Border", miscInput.radioAsButtonBorder);
            miscInput.radioAsButtonBg && root.style.setProperty("--RadioAsButton__Bg", miscInput.radioAsButtonBg);
            miscInput.radioAsButtonText && root.style.setProperty("--RadioAsButton__Text", miscInput.radioAsButtonText);
            miscInput.radioAsButtonCheckedBorder && root.style.setProperty("--RadioAsButton__CheckedBorder", miscInput.radioAsButtonCheckedBorder);
            miscInput.radioAsButtonCheckedBg && root.style.setProperty("--RadioAsButton__CheckedBg", miscInput.radioAsButtonCheckedBg);
            miscInput.radioAsButtonCheckedText && root.style.setProperty("--RadioAsButton__CheckedText", miscInput.radioAsButtonCheckedText);
        }
        if (table) {
            // console.log('doing table')
            table.rowBg && root.style.setProperty("--Table__RowBg", table.rowBg);
            table.border && root.style.setProperty("--Table__Border", table.border);
        }
        if (tabs) {
            // console.log('doing tabs')
            tabs.activeTabColor && root.style.setProperty("--ActiveTab", tabs.activeTabColor);
            tabs.activeTabText && root.style.setProperty("--ActiveTab__Text", tabs.activeTabText);
            tabs.categoryTabColor && root.style.setProperty("--CategoryTab__Color", tabs.categoryTabColor);
        }
        if (hero) {
            // console.log('doing hero')
            hero.bg && root.style.setProperty("--Hero__Bg", hero.bg)
            hero.heading && root.style.setProperty("--Hero__Heading", hero.heading)
            hero.text && root.style.setProperty("--Hero__Text", hero.text)
        }
        if (notifications) {
            // console.log('doing notifications')
            notifications.headerBg && root.style.setProperty("--Notification__HeaderBg", notifications.headerBg);
            notifications.headerText && root.style.setProperty("--Notification__HeaderText", notifications.headerText);
            notifications.bg && root.style.setProperty("--Notification__Bg", notifications.bg);
            notifications.border && root.style.setProperty("--Notification__Border", notifications.border);
            notifications.text && root.style.setProperty("--Notification__Text", notifications.text);
            notifications.link && root.style.setProperty("--Notification__Link", notifications.link);
        }
        if (progress) {
            // console.log('doing progress')
            progress.active && root.style.setProperty("--ProgressActive", progress.active)
            progress.activeText && root.style.setProperty("--ProgressActive__Text", progress.activeText)
            progress.statusBg && root.style.setProperty("--Status__Bg", progress.statusBg)
            progress.statusBorder && root.style.setProperty("--Status__Border", progress.statusBorder)
            progress.statusActiveBg && root.style.setProperty("--Status__ActiveBg", progress.statusActiveBg)
            progress.statusActiveCheck && root.style.setProperty("--Status__ActiveCheck", progress.statusActiveCheck)
        }

        setBrandingComplete(true)

    }, [account]);

    return brandingComplete;
}