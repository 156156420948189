import React, { useState } from 'react';
import styles from './MyPlans.module.css';
import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { AIGCircleBadge } from "../../shared/StatusBadge/CircularStatusBadge";
import moment from 'moment';
import { ReactComponent as ToggleIcon } from '../../../assets/images/toggle.svg';

const defaultSortedBy = [{
    dataField: "planStartDate",
    order: "desc",
 }];

export const MyPlansTableView = ({ contracts, contractDetails, ...props }) => {
    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
    };

    function actionsFormatter (data,rowData) {
        return <UncontrolledDropdown direction="right" position="top" className="text-right">
            <DropdownToggle tag="span" className={styles.toggleContainer}>
                <ToggleIcon className={styles.toggleIcon} alt="toggle dropdown"/>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => props?.handleSelectedActionClick("ViewPlanDetails", rowData)}>View Plan Details</DropdownItem>
                <DropdownItem onClick={() => props?.handleSelectedActionClick("ReprintContract")}>Reprint Contract</DropdownItem>
                <DropdownItem onClick={() => rowData ? props?.handleSelectedActionClick("TermsAndConditions", rowData) : ""}>Terms and Conditions</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    }
    
    function headerFormatter(column, index, { sortElement }) {
        const { order } = sortElement?.props || {};
        return (
            <span className={`${order === "asc" ? "ascending" : ''} ${order === "desc" ? "descending" : ''}`}>
                {column.text}
            </span>
        );
      }
      
    function StatusFormatter(row, col) {
        return <AIGCircleBadge status={col.contractStatus} />
    }

    const myPlansColumns = [
        {
            dataField: 'contractNumber',
            text: 'Contract Number',
            classes: 'btnLink',
            headerFormatter: headerFormatter,
            formatter: (contractNumber) => {
                return <a href="javascript:void(0);">{contractNumber}</a>
            },
            sort: 'true',
            sortFunc: (a, b, order) => {
                if (order === 'desc') {
                    return b - a;
                }
                return a - b; 
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    props.handleSelectedActionClick("ViewPlanDetails", row)
                }
            },
        }, 
        {
            dataField: 'assetName',
            text: 'Plan Name',
            headerFormatter: headerFormatter,
            sort:'true',
            style: { minWidth: '225px' },
        },
        {
            dataField: 'contractStatus',
            text: 'Contract Status',
            headerFormatter: headerFormatter,
            formatter: StatusFormatter,
            sort:'true'
        },
        {
            dataField: 'planStartDate',
            text: "Plan Start Date",
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return (
                    <span>{`${moment(col).format('MM/DD/YYYY')}`}</span>
                )
            },
            sortFunc: (a, b, order) => {
                if (order === "desc")
                    return moment(b).valueOf() - moment(a).valueOf();
                else if (order === "asc")
                    return moment(a).valueOf() - moment(b).valueOf();
            },
            sort:'true'
        },
        {
            dataField: 'paymentOption',
            text: "Payment/Term",
            headerFormatter: headerFormatter,
            sort:'true'
        },
        {
            dataField: 'actions',
            text: '',
            isDummyColumn: true,
            classes:styles.myClaimActions,
            headerFormatter: headerFormatter,
            formatter: actionsFormatter,
        },
    ];   
    
    const paymentOptionLabel = (id) => {
        const contractTerms = props?.contractTerms?.find((x) => x.termId == id);
        const label = contractTerms
            ? contractTerms?.termName?.trim().includes("One Month")
                ? "Monthly"
                : contractTerms?.termName?.trim()
            : "";      
        return label;
    };

    const contractList = contracts?.map(contract => {
        const asset = contract.assets[0] ?? null;
        const contractAsset = contract.contractAssetModel.find(ca => ca.assetId == asset?.assetId)
        const planStartDate = moment(contractAsset?.contractAssetCoverageModel[0]?.startDate).format('MM/DD/YYYY');
        const waitPeriod = contract.riskProfile?.waitPeriod ?? 0;
        const wpEndsMoment = moment(planStartDate, 'MM/DD/YYYY').add(waitPeriod, 'days');
        const calcStatusId = contract.statusId == 1 && wpEndsMoment.isAfter(moment().format()) ? 2 : contract.statusId;
        const calcStatusName = contract.statusId == 1 && wpEndsMoment.isAfter(moment().format()) ? "Wait" : contract.statusModel?.statusName;
        const wpEnds = waitPeriod > 0 ? moment(planStartDate, 'MM/DD/YYYY').add(waitPeriod, 'days').format('MM/DD/YYYY') : "";
   
        return {
            assetId: asset.assetId,
            assetName: asset.assetName,
            assetTypeId: asset.assetTypeId,
            assetIndustryId: asset.assetIndustryId,
            assetDisplayCategory: asset.assetDisplayCategory,
            removePurchaseDate: asset.removePurchaseDate,
            planStartDate: planStartDate,
            contractNumber: contract.contractNumber,
            paymentOption: paymentOptionLabel(contractAsset?.offer?.riskProfileModel?.termId ?? 0),
            contractStatus: calcStatusName,
            statusId: calcStatusId,
            contractAssetId: contractAsset?.contractAssetId,
            waitPeriodEnds: wpEnds,
            purchaseDate: contract.purchaseDate,
            riskProfileId: contractAsset?.offer?.riskProfileId,
            contract: contract
        }
    })

    return (
        <div>
            <BootstrapTable      
                keyField={'contractNumber'}
                columns={myPlansColumns}
                data={contractList}
                bordered={false}
                wrapperClasses={classNames("table-responsive", "table-bordered", "mb-4")}
                classes={styles.myPlansTable}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSortedBy}
            />
        </div>
    )
}