import React, { useContext, useEffect } from 'react';
import { Button } from 'reactstrap';
import { ContractContext } from '../contexts/ContractContext';
import LanguageToggle from '../Vendor/Apple/LanguageToggle';
import { useEnvironment } from '../_ReactHooks/useEnvironment';
import styles from './WhiteLabeling.module.css';
import { getCoveragePlan } from '../../helpers';
import { useLocation } from 'react-router';

const DevelopmentBrandToggler = () => {
    const { updateClientId, setIsChileApple, setCoveragePlanType } = useContext(ContractContext);
    const { isDevOrLower, isDevelopment, isStagingOrLower } = useEnvironment();
    const context = useContext(ContractContext);
    const clientId = context?.data?.clientId;
    const location = useLocation();

    const toggleBrand = (brand) => {
        if (brand === 'southbridge') {
            setIsChileApple(true);
            updateClientId('apple')
        } else {
            setIsChileApple(false);
            updateClientId(brand);
        }
    }

    useEffect(() => {
        if (location.pathname === '/planoverview' || location.pathname === '/coveragedocs') {

            const coveragePlan = async () => {
                const response = await getCoveragePlan(clientId);
                const coveragePlanType = response?.coveragePlanType ?? 'HomeWarranty';
                setCoveragePlanType(coveragePlanType);
            }

            coveragePlan();
        }
    }, [location.pathname, clientId])

    return (
        <div className={styles.footerAlign}>
            <div className={styles.footerAlign}>
                <p>Select Brand to Test:</p>
                <Button className="btn-primary ml-4" onClick={() => toggleBrand('et')}>ET</Button>
                <Button className="btn-primary ml-4" onClick={() => toggleBrand('aig')}>AIG</Button>
                <Button className="btn-primary ml-4" onClick={() => toggleBrand('hwa')}>HWA</Button>
                <Button className="btn-primary ml-4" onClick={() => toggleBrand('southbridge')}>SB</Button>
                <Button className="btn-primary ml-4" onClick={() => toggleBrand('cutler')}>Cutler</Button>
                <Button className="btn-primary ml-4" onClick={() => toggleBrand('constellation')}>Constellation</Button>
            </div>
            <LanguageToggle isForDev={isStagingOrLower} />
        </div>
    )
}

export default DevelopmentBrandToggler;