import React, { useContext } from 'react';
import {
    Container, Row, Col,
} from 'reactstrap';
import styles from './SystemErrorMessage.module.css';
import classNames from 'classnames';
import { ContractContext } from '../contexts/ContractContext';
import getClientContent from '../../components/_BrandingProvider/brandContent';
import { textValues } from '../../components/_BrandingProvider/brandContent';
import BrandedIcon from '../_BrandingProvider/BrandedIcon';

let confirmBold = styles.confirmBold;

const SystemErrorMessage = () => {
    const contractContext = useContext(ContractContext);
    const { data: { clientId } } = contractContext;
    const {variableText: {customerService}} = getClientContent(clientId);

    return (
        <Container>
            <section id="apiError" className={styles.confirmSection}>
                <Row>
                    <Col>
                        <BrandedIcon client={clientId} iconType="system-error"/>
                        <h1 className="h3">{'Oops, something went wrong on our side.'}</h1>
                        
                        <p className={styles.confirmBold}>
                            {'Our system is unable to process your request at this time.'}
                        </p>
                        
                        <p className={styles.confirmBold}>
                            {'Please call '}
                            <a href={`tel:${customerService?.phone}`} className={classNames("callLink", confirmBold)}>
                                {customerService?.phone}
                            </a>
                            {' or try again later.'}
                        </p>
                    </Col>
                </Row>
            </section>
        </Container>
    )
}

export default SystemErrorMessage;