import React, { useState, useContext } from 'react';
import { ReactComponent as AIGLogo } from '../../../assets/images/aig-logo_w.svg';
import styles from './ConstellationHeader.module.css';
import { Nav, NavItem, NavLink, NavbarToggler, Collapse, Modal, ModalBody, Navbar } from 'reactstrap';
import classnames from 'classnames';
import CloseMobile from '../../../assets/images/close-mobile.svg';
import { ReactComponent as ConstellationLogo } from '../../../assets/images/Vendor/Constellation/constellation-logo.svg';
import { useLogout }  from '../../shared/logout';
import { ContractContext } from '../../contexts/ContractContext';

const ConstellationHeader = (props) => {
    const context = useContext(ContractContext);
    const [activeTab, setActiveTab] = useState('1');
    const [isOpen, setIsOpen] = useState(false);
    const { logout } = useLogout();

    const handleLogoClick = (event) => {
        event.preventDefault();
        props.onLogoClickHandler(event)
    }

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        linkToRedirect(tab);
    }

    const linkToRedirect = (actionId) => {
        let route = '';
        switch (actionId) {
            case '1': {
                route = 'faq';
                break;
            }
            case '2': {
                route = 'coveragedocs';
                break;
            }
            case '3': {
                route = 'info/contact-us';
                break;
            }
        }
        const link = `/${route}`;
        window.open(link, '_blank')
    }

    const toggleClose = () => {
        setIsOpen(!isOpen);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Helper to wrap and thus only have to maintain one menu instead of two
    const ConditionalNavWrapper = ({ children }) => (
        isOpen ? (
            <Modal
                isOpen={true}
                toggle={toggle}
                className={`md header-content ${styles.navMenu}`}
                aria-modal="true"
                role="alertdialog"
                aria-describedby="suggest"
                aria-labelledby="modalHeading"
            >
                <h2 id="modalHeading" className={styles.hidden}>Navigation Menu</h2>
                <a onClick={toggleClose}>
                    <img
                        tabIndex={0}
                        className={`logoClose ${styles.closeButtonTop}`}
                        src={CloseMobile}
                        alt="Close"
                    />
                </a>
                <ModalBody>
                    {children}
                </ModalBody>
            </Modal>
        ) : (
            <Collapse navbar>
                {children}
            </Collapse>
        )
    )

    return (
        <header>
            <div className={styles.headerBorder}>
                <div className={classnames(styles.headerLg, 'container', 'px-0')}>
                    <div className={styles.logoContainer}>
                        <a onClick={(event) => handleLogoClick(event)}>
                            <ConstellationLogo className={styles.logo} />
                        </a>
                    </div>
                    <div className={styles.headerRight}>
                        <span>Powered by</span>
                        <div className={styles.aigLogo}>
                            <AIGLogo />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classnames(styles.navBarContainer, 'container', 'px-0')}>
                <div className={classnames(styles.navContainer)}>
                    <div className={styles.mobileLogo}>
                        <a onClick={(event) => handleLogoClick(event)}>
                            <ConstellationLogo className={styles.logo} />
                        </a>
                    </div>
                    <Navbar light expand="md" role="navigation" className={styles.mobileNav}>
                        <span className={styles.poweredBy}>Powered by AIG</span>&nbsp;
                        <NavbarToggler aria-label="On click opens a navigation menu" onClick={toggleMenu} />
                        <ConditionalNavWrapper>
                            <Nav className={classnames('ml-auto', styles.navTabs)} navbar>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                        FAQs
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                        Coverage Details
                                    </NavLink>
                                </NavItem>                                
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                        Contact Us
                                    </NavLink>
                                </NavItem>
                                {context?.data?.loggedIn &&
                                    <NavItem>
                                        <NavLink onClick={() => logout()}>
                                            Logout
                                        </NavLink>
                                    </NavItem>
                                }
                            </Nav>
                        </ConditionalNavWrapper>
                    </Navbar>
                </div>
            </div>
        </header>
    );
}

export default ConstellationHeader;