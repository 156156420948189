import React, { useState, useRef, useEffect, useContext } from "react";
import { Container, Row, Col, FormGroup, Card } from "reactstrap";
import classNames from "classnames";
import styles from "./CreateNewPassword.module.css";
import { ReactComponent as CheckIcon } from "../../assets/images/Vendor/Cutler/Check_icon.svg";
import SimpleReactValidator from "simple-react-validator";
import StandardInput from "../shared/StandardInput/StandardInput";
import SpinnerButton from "../../components/shared/SpinnerButton/SpinnerButton";
import { useHistory } from "react-router-dom";
import { ContractContext } from '../contexts/ContractContext';

const CreateNewPassword = (props) => {
    const context = useContext(ContractContext);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });
    const [validationCheck, setValidationCheck] = useState(false);
    const [passLengthCheck, setPassLengthCheck] = useState(false);
    const [passNumberCheck, setPassNumberCheck] = useState(false);
    const [passCharCheck, setPassCharCheck] = useState(false);
    const [passCaseCheck, setPassCaseCheck] = useState(false);
    const [isResetting, setIsResetting] = useState(false);
    const [, forceUpdate] = useState();
    const history = useHistory();
    const [emailAddress, setEmailAddress] = useState(history?.location?.state?.email || '');
    const [partyId, setPartyId] = useState(history?.location?.state?.partyId || 0);
    const [countryCode, setCountryCode] = useState(history?.location?.state?.countryCode || 'us');
    const [languageCode, setLanguageCode] = useState(history?.location?.state?.languageCode || 'en');
    const [clientId, setClientId] = useState(history?.location?.state?.clientId || 'aig');
    const [hasUser, setHasUser] = useState(history?.location?.state?.hasUser || false);
    const [tokenTypeId, setTokenTypeId] = useState(history?.location?.state?.tokenTypeId || 0);
    const [passCode, setPassCode] = useState(history?.location?.state?.passCode || false);

    useEffect(() => {
        if (emailAddress === '') {
            history.push('/plansearch');
        }
    }, [])

    const simpleValidator = useRef(
        new SimpleReactValidator({
            messages: {
                required: ":attribute is required.",
                in: "Passwords do not match.",
                min: "Password length should be minimum 8 characters",
            },
            element: (message) => (
                <div className="errorMsg" aria-live="polite">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
            validators: {
                valid_pass: {
                    message: "The :attribute is invalid.",
                    rule: (val, params, validator) => {
                        return (
                            validator.helpers.testRegex(
                                val,
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`~!@#$%^&\\*(),.?';":\/{}|<>[\]_+=-])(?=.{8,})/
                            ) && params.indexOf(val) === -1
                        );
                    },
                    required: true,
                },
            },
        })
    );

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "password") {
            setPassLengthCheck(value.length >= 8);
            setPassNumberCheck(/\d/.test(value));
            setPassCharCheck(/[`~!@#$%^&\\*(),.?';":\/{}|<>[\]_+=-]/.test(value));
            setPassCaseCheck(
                /^(?=.*[a-z])/.test(value) && /^(?=.*[A-Z])/.test(value)
            );
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        setIsResetting(true);
        if (simpleValidator.current.allValid()) {
            setValidationCheck(false);
            let request = {};

            let route = "";
            if (hasUser) {
                route = 'api/identity/ResetPassword';
                request = {
                    UserName: "",
                    Password: formData.password,
                    ApplicationId: "",
                    Id: "",
                    UserEmail: emailAddress,
                    Client: clientId,
                    TokenTypeId: tokenTypeId,
                    UserFriendlyToken: passCode
                };
            }
            else {
                route = 'api/identity/CreateNewUser'
                request = {
                    partyId: partyId,
                    emailAddress: emailAddress,
                    password: formData.password,
                    clientId: clientId,
                    hasUser: hasUser,
                    UserFriendlyToken: passCode
                };
            }

            fetch(route, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            })
                .then((res) => res.json())
                .then((data) => {
                    let value = context;
                    value.setLoggedIn(true);
                    history.push("/planoverview");
                })
                .catch((e) => {
                    setIsResetting(false);
                });
        } else {
            setValidationCheck(true);
            setIsResetting(false);
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    return (
        <Container className={styles.mainContainer}>
            <div className={styles.createNewPasswordBackground}>
                <Row>
                    <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 1 }}>
                        <Card className={styles.createNewPasswordCard}>
                            <h3 className="text-center mb-2">Create New Password</h3>
                            <p className="text-center mb-0">Enter a new password using the tips below.</p>
                            <StandardInput
                                labelCustomClass={'mt-4'}
                                label="New Password"
                                htmlInputType="password"
                                hasError={
                                    !simpleValidator.current.check(
                                        formData.password,
                                        "required|min:8|valid_pass"
                                    ) &&
                                    validationCheck &&
                                    "form-error"
                                }
                                fieldName="password"
                                ariaLabelAndFieldId="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                validator={simpleValidator.current.message(
                                    "password",
                                    formData.password,
                                    "required|min:8|valid_pass"
                                )}
                            />
                            <StandardInput
                                label="Confirm New Password"
                                htmlInputType="password"
                                hasError={
                                    !simpleValidator.current.check(
                                        formData.confirmPassword,
                                        `required|in:${formData.password}`
                                    ) &&
                                    validationCheck &&
                                    "form-error"
                                }
                                fieldName="confirmPassword"
                                ariaLabelAndFieldId="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                validator={simpleValidator.current.message(
                                    "confirmPassword",
                                    formData.confirmPassword,
                                    `required|in:${formData.password}`
                                )}
                            />
                            <SpinnerButton
                                type="primary"
                                onClick={handleResetPassword}
                                text="Create New Password"
                                spinning={isResetting}
                                disabled={
                                    isResetting ||
                                    !(
                                        passLengthCheck &&
                                        passNumberCheck &&
                                        passCharCheck &&
                                        passCaseCheck &&
                                        formData.confirmPassword.length > 0
                                    )
                                }
                            />
                            <Col className={styles.passwordRequirementContainer}>
                                <div className={styles.confirmPassHeadText}>
                                    Password Requirements
                                </div>
                                <div
                                    className={classNames("mb-1", styles.confirmPassCheckContainer)}
                                >
                                    <div
                                        className={
                                            passLengthCheck ? styles.confirmPassCheck : styles.checkText
                                        }
                                    >
                                        {passLengthCheck && <CheckIcon />}
                                    </div>
                                    <div>At least 8 characters long</div>
                                </div>
                                <div
                                    className={classNames("mb-1", styles.confirmPassCheckContainer)}
                                >
                                    <div
                                        className={
                                            passNumberCheck ? styles.confirmPassCheck : styles.checkText
                                        }
                                    >
                                        {passNumberCheck && <CheckIcon />}
                                    </div>
                                    <div>Include one number</div>
                                </div>
                                <div
                                    className={classNames("mb-1", styles.confirmPassCheckContainer)}
                                >
                                    <div
                                        className={
                                            passCharCheck ? styles.confirmPassCheck : styles.checkText
                                        }
                                    >
                                        {passCharCheck && <CheckIcon />}
                                    </div>
                                    <div>Include one special character</div>
                                </div>
                                <div
                                    className={classNames("mb-1", styles.confirmPassCheckContainer)}
                                >
                                    <div
                                        className={
                                            passCaseCheck ? styles.confirmPassCheck : styles.checkText
                                        }
                                    >
                                        {passCaseCheck && <CheckIcon />}
                                    </div>
                                    <div>One upper and one lower case character</div>
                                </div>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default CreateNewPassword;
