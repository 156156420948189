import { useEffect, useState } from "react"
import { getEnvironment } from "../../helpers"

export const useEnvironment = () => {
    const [env, setEnv] = useState('')
    const [buttons, setButtons] = useState('')
    const [hasLogin, setHasLogin] = useState(false)

    useEffect(() => {
        const fetchEnv = async () => {
            const env = await getEnvironment();
            const envLower = env.environment.toLowerCase();
            const brandButtons = env.brandingButtons;
            const login = env.hasAccount;
            setEnv(envLower)
            setButtons(brandButtons)
            setHasLogin(login)
        }
        fetchEnv();
    }, [])

    const isLocal = env === 'local'
    const isDevelopment = env === 'development'
    const isStaging = env === 'staging'
    const isProduction = env === 'production'
    const isAnyNonProduction = env !== 'production'

    return {
        isLocal,
        isDevelopment,
        isStaging,
        isProduction,
        isAnyNonProduction,
        isStagingOrLower: isLocal || isDevelopment || isStaging,
        isDevOrLower: isLocal || isDevelopment,
        showBrandButtons: buttons,
        hasLogin
    }
}