import React, { useContext, useEffect, useState, useRef } from 'react';
import { ContractContext } from '../../contexts/ContractContext';
import styles from './AssetCoverageOverview.module.css'
import { useLocation, useHistory } from 'react-router';
import DocumentTitle from 'react-document-title';
import getClientContent from '../../_BrandingProvider/brandContent';
import { Container, FormGroup, Label } from 'reactstrap';
import Accordion from '../../shared/AccordionCard/Accordion';
import { ReactComponent as Chevron } from '../../../assets/images/arrow-cheveron-down.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkmark.svg';
import { assetInformation } from '../../../screens/PlanOverview/AssetInformation';
import classNames from 'classnames';
import { DropDownInput } from '../../inputs/DropDownInput';
import SimpleReactValidator from 'simple-react-validator';


const AssetCoverageOverview = () => {
    const context = useContext(ContractContext);
    const location = useLocation();
    const history = useHistory();
    const clientTextValues = getClientContent(context.data.clientId);
    const title = clientTextValues.variableText.pageTitle;
    const pageTitle = title ? `Asset Coverage Overview | ${title}` : 'Asset Coverage Overview';
    const clientId = context?.data?.contract?.clientId || context?.data?.clientId;
    const [validationFired, setValidationFired] = useState(false);
    const [, forceUpdate] = useState();
    const [assetName, setAssetName] = useState(location.state?.assetName);
    const [contractAsset, setContractAsset] = useState(location.state?.contractAsset || context.data?.contract?.assets[0])
    const currentAsset = assetInformation[clientId.toLowerCase()][assetName];
    const [state, setState] = useState({
        coveredAssets: []
    })

    const validator = useRef(
        new SimpleReactValidator({
            element: (message) => (
                <div className="errorMsg" aria-live="polite">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
        })
    );
    
    const CoveredInformation = () => {
        return (
            <div className={styles.coveredInfoContainer}>
                {currentAsset?.coveredInfo.map(item => (
                    <div className={styles.coveredItemsRow}>
                        <div className={styles.coveredItem}>{item}</div>
                        <div className={styles.checkmarkContainer}> <CheckmarkIcon className={styles.checkmarkIcon} /></div>
                    </div>
                ))}
            </div>
        )
    }

    const NotCoveredInformation = () => {
        return (
            <div className={styles.notCoveredInfoContainer}>
                <ul className='mb-0'>
                    {currentAsset?.notCoveredInfo.map(item => (
                        <li>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const redirectToCoverageDetails = () => {
        const pathName = '/coveragedocs';

        history.push({
            pathname: pathName,
        });
    }

    useEffect(() => {
        getAssetsCovered();
    }, [clientId])

    const getAssetsCovered = async () => {
        fetch(`api/document/GetCoverageDocuments/${clientId}`)
            .then(res => res.json())
            .then(data => {
                if (Array.isArray(data)) {
                    let coveredAssets = [];

                    data[0]?.assetCoverageModels?.map(asset => {
                        if (!coveredAssets.find(existingAsset => existingAsset.data == asset.assetId)) {
                            coveredAssets.push({
                                data: asset.assetId,
                                display: asset.assetName
                            })
                        }
                    })

                    setState(prev => ({
                        ...prev,
                        coveredAssets
                    }));
                }
            });
    }

    const handleCoveredAssetDropdown = (assetId, display) => {
        setAssetName(display);

        context?.data?.contract?.contractAssetModel?.map(assetModel => {
              if (assetId === assetModel?.assetId) {
                setContractAsset(assetModel);
              }  
        })
    }

    const fileNewClaim = () => {
        setValidationFired(true);
        if (validator.current.allValid()) {
            history.push({
                pathname: '/planoverview',
                state: {
                    view: 'view',
                    activeOverviewTab: 'fileClaim',
                    activeAssetId: contractAsset?.assetId,
                    activeAssetCategory: contractAsset?.assetDisplayCategory
                }
            });
        } else {            
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const getAssetIdFromAssetName = (assetName) => {
        const filteredAsset = state.coveredAssets.filter(asset => asset.display === assetName);
        return filteredAsset[0]?.data;
    }
    
    return ( 
        <DocumentTitle title={pageTitle}>
            <div className={`container contentWrapper`}>
                <div className={styles.subNavBar}>
                    <a className={styles.viewLink} onClick={redirectToCoverageDetails}>
                        <Chevron className={styles.arrowIcon} /> Coverage Details
                    </a>
                    <FormGroup className={(validationFired && !assetName) ? "form-error" : ""}>
                        <Label for="coverageByProduct" className="form-label">Coverage By Product</Label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <DropDownInput
                            options={state.coveredAssets}
                            name="coveredAsset"
                            className="form-control"
                            onChangedValue={handleCoveredAssetDropdown}
                            placeholder={"Please select..."}
                            id="covByProduct"
                            defaultValue={getAssetIdFromAssetName(assetName)}
                        />
                        {validator.current.message(
                            "coveredAsset",
                            assetName,
                            "required",
                            { messages: {required: "Required field."} }
                        )}
                    </FormGroup>
                </div>
                <Container className={styles.assetContainer}>
                        <div id="assetRowContainer" className={styles.assetRow}>
                            <div className={styles.assetIcon}>{currentAsset?.icon}</div>
                            <h2 className={styles.assetNameHeader}>{assetName}</h2>
                            <button className={classNames(styles.fileClaimButton, 'btn btn-primary')} onClick={() => fileNewClaim()}>
                                File Claim
                            </button>
                        </div>
                        <div id="coverageContainer" className={styles.coverageContainer}>
                            <p>
                                {currentAsset?.description}
                            </p>
                            <Accordion
                                id=''
                                question="What's Covered"
                                answer={<CoveredInformation />}
                                type='component'
                            />
                            <Accordion
                                id=''
                                question="What's Not Covered"
                                answer={<NotCoveredInformation />}
                                type='component'
                            />
                        </div>    
                </Container>
            </div>
        </DocumentTitle> 
    )
}

export default AssetCoverageOverview;