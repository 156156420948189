import React, { createContext, Component, useState, useEffect, useMemo } from 'react';
import { useEnvironment } from '../_ReactHooks/useEnvironment'

export const ContractContext = createContext();

export const ContractProvider = ({ children }) => {
    // on first load, get values from session and use for initial state
    const restoreSession = useMemo(() => {
        return JSON.parse(sessionStorage.getItem('appState'))
    }, [])

    const planSearchOption = useMemo(() => {
        return JSON.parse(sessionStorage.getItem('searchOptions'))
    }, [])

    // TODO: We should change these from "update" to "set" to follow React conventions.
    const [contract, updateContract] = useState(restoreSession?.contract ?? {});
    const [contracts, updateContracts] = useState(restoreSession?.contracts ?? [])
    const [party, updateParty] = useState(restoreSession?.party ?? {})
    const [clientId, updateClientId] = useState(contract?.clientId ?? null);
    const [viaClientHandshake, updateViaClientHandshake] = useState(restoreSession?.contract ?? false)
    const [hwaToken, updateHwaToken] = useState(restoreSession?.contract ?? '') // PROBABLY DEPRECATED?
    const [handshakeSuccessful, setHandshakeSuccessful] = useState(null);
    const [planlookup, updatePlanLookup] = useState(planSearchOption || {});
    const [planLogin, setPlanLogin] = useState({});
    const [loggedIn, setLoggedIn] = useState(restoreSession?.loggedIn ?? false); // used to make timeout modal active
    const [coverageDocs, setCoverageDocs] = useState(null)
    const [isChileApple, setIsChileApple] = useState(false);
    const { isDevOrLower, hasLogin } = useEnvironment();
    const [isFetchComplete, setIsFetchComplete] = useState(true);
    const [coveragePlanType, setCoveragePlanType] = useState(restoreSession?.coveragePlanType ?? 'HomeWarranty');
    const [login, setLogin] = useState(restoreSession?.login ?? false);

    // SET INITIAL BRAND BY URL
    useEffect(() => {
        var client = contract?.clientId || 'AIG';
        updateClientId(client);
        setLogin(hasLogin)

        if (!isDevOrLower && Object.keys(contract).length === 0)
            clientSetup();
    }, [])

    useEffect(() => {
        if (contract?.clientId && clientId) {
            getCoverageDocs();
        }
    }, [contract?.clientId])

    // KEEP SESSION STORAGE IN SYNC WITH STATE. 
    useEffect(() => {
        const appState = {
            contract, party, contracts, viaClientHandshake, hwaToken, clientId, handshakeSuccessful, coverageDocs, planlookup, loggedIn, planLogin,
            coveragePlanType, hasLogin
        }
        sessionStorage.setItem('appState', JSON.stringify(appState))
    }, [
        contract, contracts, party, clientId, viaClientHandshake, handshakeSuccessful, planlookup,
        hwaToken, coverageDocs, loggedIn, planLogin, coveragePlanType, hasLogin
    ])

    const getCoverageDocs = async () => {
        if (contract?.clientId) {
            fetch(`api/document/GetCoverageDocuments/${contract?.clientId}`)
                .then(res => res.json())
                .then(data => {
                    setCoverageDocs(data);
                });
        }
    }

    const clientSetup = async () => {
        setIsFetchComplete(false)
        await fetch('api/helper/getClientByUrl', {
            method: 'get',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(res => res.json())
            .then(envRes => {
                const clientId = envRes.clientId.toUpperCase();
                const coveragePlan = envRes?.coveragePlanType;
                const hasLogin = envRes?.hasLogin ?? false;
                updateClientId(clientId);
                setCoveragePlanType(coveragePlan);
                setLogin(hasLogin);
            })
            .catch(err => { console.error(err) })
            .finally(() => setIsFetchComplete(true))
    }

    if (!isFetchComplete) return null

    return (
        <ContractContext.Provider
            value={{
                // Ideally, do away with "data" and just return values
                data: {
                    contract,
                    contracts,
                    isChileApple,
                    party,
                    clientId,
                    viaClientHandshake,
                    hwaToken,
                    coverageDocs,
                    handshakeSuccessful,
                    planlookup,
                    loggedIn,
                    planLogin,
                    coveragePlanType,
                    hasLogin
                },
                // contract,
                updateContract,
                // contracts,
                updateContracts,
                // party,
                updateParty,
                // clientId,
                updateClientId,
                // viaClientHandshake,
                updateViaClientHandshake,
                // hwaToken,
                updateHwaToken,
                // handshakeSuccessful
                setHandshakeSuccessful,
                // chileApple
                setIsChileApple,
                // coverageDocs
                setCoverageDocs,
                // pPlanlookup
                updatePlanLookup,
                // loggedIn
                setLoggedIn,
                // planLogin
                setPlanLogin,
                // coveragePlanType
                setCoveragePlanType,
                // hasLogin
                setLogin
            }}
        >
            {children}
        </ContractContext.Provider>
    )
}
