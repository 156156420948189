import React, { useContext, useState, useRef, useEffect } from "react";
import { Row, Col, Container, Card, CardBody, CardHeader, Button } from 'reactstrap';
import classNames from 'classnames'
import SimpleReactValidator from "simple-react-validator";
import { ContractContext } from '../../../components/contexts/ContractContext';
import styles from './CustomerDetails.module.css'
import { ReactComponent as Chevron } from "../../../assets/images/arrow-cheveron-down.svg";
import { AddressForm, StaticAddress } from './CustomerAddressForm';
import { ContactInfoForm, StaticContactInfo } from '../../../components/shared/ContactInformation/ContactInformation';
import SuggestedAddress from '../../modals/SuggestedAddress';
import InvalidAddress from '../../modals/InvalidAddress';
import { verifyAddress, VerificationResponseCodes } from '../../api/addressverification';
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import getClientContent from '../../_BrandingProvider/brandContent';

const CustomerDetails = ({ history }) => {
    const [editContactInfo, setEditContactInfo] = useState(false);
    const [states, setStates] = useState([]);
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const [validationFired, setValidationFired] = useState(false);
    const location = useLocation();
    const [token, setToken] = useState(parse(location.search)?.id || parse(location.search)?.token)
    const [, forceUpdate] = useState();
    const context = useContext(ContractContext);
    const contextClientId = context?.data?.clientId || context?.data?.contract?.clientId || "aig";
    const salesPhone = getClientContent(contextClientId)?.variableText?.sales?.phone ?? '';
    const { party, contract } = JSON.parse(sessionStorage.getItem("appState"))
    const clientId = party?.clientId;
    const [activeTabView, setActiveTabView] = useState(location?.state?.activeOverviewTab || "fileClaim");
    const [activePlanView, setActivePlanView] = useState(location?.state?.view || "kanban");
    const [formData, setFormData] = useState({
        name: party.partyName === null ? party.firstName.concat(' ', party.lastName) : party.partyName,
        firstname: party.firstName,
        lastName: party.lastName,
        email: party.partyEmailModel[0].address,
        alternatePhone: party.partyPhoneModel.find(({ phoneTypeId }) => phoneTypeId === 1)?.number ?? "",
        mobilePhone: party.partyPhoneModel.find(({ phoneTypeId }) => phoneTypeId === 2)?.number ?? "",     ///Primary phone number
        address1: party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 3)?.address1 ?? "",
        address2: party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 3)?.address2 ?? "",
        city: party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 3)?.city ?? "",
        state: party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 3)?.state ?? "",
        stateId: party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 3)?.stateId ?? "",
        zip: party.partyAddressModel.find(({ addressTypeId }) => addressTypeId === 3)?.zip ?? "",
        billingAddress: {
            address1: party.billingAddress?.address1 ?? "",
            address2: party.billingAddress?.address2 ?? "",
            city: party.billingAddress?.city ?? "",
            state: party.billingAddress?.state ?? "",
            stateId: party.billingAddress?.stateId ?? "",
            zip: party.billingAddress?.zip ?? "",
        },
        serviceAddress: {
            address1: party.serviceAddress?.address1 ?? "",
            address2: party.serviceAddress?.address2 ?? "",
            city: party.serviceAddress?.city ?? "",
            state: party.serviceAddress?.state ?? "",
            stateId: party.serviceAddress?.stateId ?? "",
            zip: party.serviceAddress?.zip ?? "",
        },
        mailingAddress: {
            address1: party.shippingAddress?.address1 ?? "",
            address2: party.shippingAddress?.address2 ?? "",
            city: party.shippingAddress?.city ?? "",
            state: party.shippingAddress?.state ?? "",
            stateId: party.shippingAddress?.stateId ?? "",
            zip: party.shippingAddress?.zip ?? "",
        }
    })
    const [showSuggestedAddressValidationModal, setSuggestedAddressModal] = useState(false);
    const [showInvalidAddressModal, setInvalidAddressModal] = useState(false);
    const [verifiedAddress, setVerifiedAddress] = useState(null);
    const [addressToVerify, setAddressToVerfy] = useState(null);
    const [bypassServiceMelissaValidation, setBypassServiceMelissaValidation] = useState(false);
    const [bypassMailingMelissaValidation, setBypassMailingMelissaValidation] = useState(false);
    const [editedData, setEditedData] = useState();
    const validator = useRef(
        new SimpleReactValidator({
            element: (message) => (
                <div className="errorMsg" aria-live="polite">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
            messages: {
                phone: 'Please enter a valid phone number.',
            },
            validators: {
                valid_phone: {
                    message: 'Please enter a valid phone number.',
                    rule: (val, params, validator) => {
                        return !new RegExp("^([0-9])\\1{9}$").test(val.match(/\d+/g).join(''))
                    }
                }
            },
        })
    );

    const handleInputChange = (e) => {
        setDisableSaveButton(false);
        let { name, value } = e.target;
        console.log({ name, value })
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const handleStateDropDown = (e) => {
        setDisableSaveButton(false);
        const selectedState = Array.isArray(states.state) ? states.state.find(({ data }) => data === e).display : 0;
        setFormData({
            ...formData,
            state: selectedState,
            stateId: e
        });
    }
    useEffect(() => {
        fetch('api/party/states/'.concat(contract.clientId))
            .then(res => res.json())
            .then(data => {
                const result = [];
                data.map(s => {
                    result.push({
                        data: s.stateId,
                        display: s.name
                    })
                    setStates({ state: result })
                })
            })
    }, []);
    const handleContactInfo = () => {
        setValidationFired(true);
        const checkPhoneError = formData.alternatePhone !== "" || formData.mobilePhone !== "";
        if (validator.current.allValid() && checkPhoneError) {
            const addressObj = {
                address1: formData?.address1 ?? "",
                address2: formData?.address2 ?? "",
                city: formData?.city ?? "",
                state: formData?.state ?? "",
                stateId: formData.stateId ?? "",
                zip: formData?.zip ?? "",
                country: formData?.state?.countryModel?.Iso2 ?? "US"
            }
            setAddressToVerfy(addressObj)
            if (!bypassServiceMelissaValidation) {
                verifyAddress(addressObj, contract.clientId, onAddressVerified);
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }
    const onAddressVerified = (result) => {
        if (result.code === VerificationResponseCodes.addressVerified) {
            setVerifiedAddress(result.returnedAddress);
            setSuggestedAddressModal(true);
        } else if (result.code === VerificationResponseCodes.addressChanged) {
            setVerifiedAddress(result.returnedAddress);
            setSuggestedAddressModal(true);
        } else {
            setInvalidAddressModal(true)
        }
    }
    const onContinueSaveAction = (selectedAddress) => {
        let partyPhone = party?.partyPhoneModel;
        console.log('=== partyPhone ===', partyPhone)
        let partyAddress = party?.partyAddressModel;
        let partyEmail = party.partyEmailModel;

        partyAddress.find(({ addressTypeId }) => addressTypeId === 3).address1 = formData.address1;
        partyAddress.find(({ addressTypeId }) => addressTypeId === 3).address2 = formData.address2;
        partyAddress.find(({ addressTypeId }) => addressTypeId === 3).city = formData.city;
        partyAddress.find(({ addressTypeId }) => addressTypeId === 3).state = formData.state;
        partyAddress.find(({ addressTypeId }) => addressTypeId === 3).stateId = formData.stateId;
        partyAddress.find(({ addressTypeId }) => addressTypeId === 3).zip = formData.zip;     
        if (partyPhone.find(({ phoneTypeId }) => phoneTypeId === 2)) {
            partyPhone.find(({ phoneTypeId }) => phoneTypeId === 2).number = formData.mobilePhone; /// primary phone number
        }
        if (partyPhone.find(({ phoneTypeId }) => phoneTypeId === 1)) {
            partyPhone.find(({ phoneTypeId }) => phoneTypeId === 1).number = formData.alternatePhone;
        }
        partyEmail.find(({ isPrimary }) => isPrimary === true).address = formData.email;
        let firstname = formData.name.split(' ')[0];
        let lastname = formData.name.split(' ').length > 1 ? formData.name.split(' ')[1] : '';
        fetch('api/party/'.concat(party.partyId, "/").concat(contract.clientId),
            {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    partyId: party.partyId,
                    firstName: firstname,
                    lastName: lastname,
                    partyName: firstname + " " + lastname,
                    partyAddressModel: partyAddress,
                    partyPhoneModel: partyPhone,
                    partyEmailModel: partyEmail,
                    city: formData.city,
                    zipcode: formData.zip,
                    state: formData.state
                })
            }).then(res => res.json())
            .then(data => {
                var editedData = JSON.parse(sessionStorage.getItem("appState"));
                editedData.party = data;
                sessionStorage.setItem("appState", JSON.stringify(editedData));
                history.push({
                    pathname: '/planoverview',
                    state: {
                        accountDetailsUpdated: true,
                        updatedPartyName: editedData.party
                    }
                });
            });
    }
    const onCloseAddressModal = () => {
        setSuggestedAddressModal(false);
        setInvalidAddressModal(false);
    }

    const backToDashboard = () => {
        history.push({
            pathname: '/planoverview',
            search: typeof token == 'undefined' ? null : `?token=${token}`,
            state: {
                view: activePlanView,
                activeOverviewTab: activeTabView
            }
        });
    }

    return (
        <div className="container contentWrapper">
            <Row>
                <Col lg="12" md="12" className="mb-5">
                    <a onClick={() => backToDashboard()} className={classNames(styles.viewLink, "btnLink")}>
                        <Chevron className={styles.searchIcon} />
                        View Dashboard
                    </a>
                </Col>
            </Row>
            <Card className={styles.accountDetails}>
                <CardHeader className={classNames(styles.borderStyle, "text-center")}>
                    <h1 className="mb-3 h5">My Account Details</h1>
                    <p className={classNames(styles.headerText, "mb-0")}>Incorrect Service Address?</p>
                    <p className={styles.headerText}>Call {salesPhone}</p>
                </CardHeader>
                <CardBody>
                    {!editContactInfo ?
                        <>
                            <Row>
                                <Col lg="6" md="6">
                                    <StaticContactInfo
                                        contactInfo={formData}
                                        title={"My Information"}
                                    />
                                </Col>
                                <Col lg="6" md="6">
                                    <fieldset>
                                        <legend className="cardTitle font-weight-bold mb-0">Mailing Address</legend>
                                        <StaticAddress
                                            addressData={formData.mailingAddress}
                                            showName={false}
                                            clientId={clientId}
                                            states={states.state}
                                        />
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6" md="6">
                                    <fieldset>
                                        <legend className="cardTitle font-weight-bold mb-0">Service Address</legend>
                                        <StaticAddress
                                            addressData={formData.serviceAddress}
                                            showName={false}
                                            clientId={clientId}
                                            states={states.state}
                                        />
                                    </fieldset>
                                </Col>
                                <Col lg="6" md="6" className="mt-5">
                                    <button className="btn btn-secondary" onClick={() => setEditContactInfo(true)}>Edit Info</button>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col lg="12" md="12">
                                    <ContactInfoForm
                                        validator={validator}
                                        contactInfo={{
                                            name: formData.name,
                                            email: formData.email,
                                            phone: {
                                                mobilePhone: formData.mobilePhone,
                                                alternatePhone: formData.alternatePhone
                                            }
                                        }}
                                        title={"Contact Information"}
                                        validationFired={validationFired}
                                        handleInputChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" md="12">
                                    <h6>{"Mailing Address"}</h6>
                                    <AddressForm
                                        address={{
                                            address1: formData.address1,
                                            address2: formData.address2,
                                            city: formData.city,
                                            zip: formData.zip,
                                            state: formData.state,
                                            stateId: formData.stateId,
                                        }}
                                        states={states.state}
                                        handleInputChange={handleInputChange}
                                        handleStateDropDown={handleStateDropDown}
                                        onChangedValue={handleStateDropDown}
                                        validator={validator.current}
                                        validationFired={validationFired}
                                        clientId={contract.clientId}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" md="12">
                                    <h6>{"Service Address"}</h6>
                                    <StaticAddress
                                        addressData={formData.serviceAddress}
                                        showName={false}
                                        clientId={clientId}
                                        states={states.state}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" md="12" className="d-flex flex-center">
                                    <button
                                        className="btn btn-secondary mr-4"
                                        onClick={() => setEditContactInfo(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleContactInfo()}
                                        disabled={disableSaveButton}
                                    >
                                        Save and Close
                                    </button>
                                </Col>
                            </Row>
                            {showSuggestedAddressValidationModal &&
                                <SuggestedAddress
                                    showSuggestedAddressValidationModal={showSuggestedAddressValidationModal}
                                    enteredAddress={addressToVerify}
                                    suggestedAddress={verifiedAddress}
                                    onContinueAction={onContinueSaveAction}
                                    onEditAddress={onCloseAddressModal}
                                />
                            }
                            {showInvalidAddressModal &&
                                <InvalidAddress
                                    showInvalidAddressModal={showInvalidAddressModal}
                                    onEditAddress={onCloseAddressModal}
                                    onContinueAction={onContinueSaveAction}
                                />
                            }
                        </>
                    }
                </CardBody>
            </Card>
        </div>
    )
}

export default CustomerDetails;

